import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import ApplicationInfo from "./ApplicationInfo";
import ApplicationActions from "./ApplicationActions";

export default function ApplicationItem({ application }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row xs="auto">
          <ApplicationInfo application={application} />
          <ApplicationActions application={application} />
        </Row>
      </Card.Body>
    </Card>
  );
}
