import React, { useState } from "react";
import "../Styles/index.css";
import SelectDesign from "./ReportItems/SelectDesign";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import MonoSpace from "./ReportItems/HtmlElements/MonoSpace";
import DualSpace from "./ReportItems/HtmlElements/DualSpace";
import ImageSpace from "./ReportItems/HtmlElements/ImageSpace";
import PdfDownloadButton from "../../PdfDownloadButton/Components/PdfDownloadButton";
import "animate.css";
import CalculatedParameters from "./ReportItems/CalculatedParameters/CalculatedParameters";
import { updateShowButtons } from "../Store";
import { useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../../Helpers/Overlay";
import ScrollableCard from "../../../Helpers/ScrollableCard";

export default function WrapUpNew({ data, isTest, demoScheme, demoAppData }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.wrapUp);
  const [showOverlay, setShowOverlay] = useState(false);
  const [pdfPreview, setPdfPreview] = useState(null);

  let appData = demoAppData ? demoAppData : data.appData;
  let calculatedScopes = data?.calculation?.calculatedScopes;
  let scheme = demoScheme ? demoScheme : appData.scheme && JSON.parse(appData.scheme);

  if (!demoScheme)
    scheme.forEach((reportItem) => {
      if (reportItem.htmlEl) return;
      else if (reportItem.subScopeId)
        reportItem.score = calculatedScopes.find((x) => x.isSub && x.scopeId === reportItem.subScopeId).calculatedScore;
      else {
        if (!isTest)
          reportItem.score = calculatedScopes.find(
            (x) => x.isSub === false && x.scopeId === reportItem.scopeId
          ).calculatedScore;
        else reportItem.score = calculatedScopes.find((x) => x.scopeId === reportItem.scopeId).score;
      }
    });

  const Key = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const HandleMappingReportItemComponents = (item, i) => {
    let isAllShown = IsAllShown();

    if (item.componentName === "EmptySpace") return;
    if (isAllShown && item.componentName === "MonoSpace") return <MonoSpace key={Key(i)} item={item} />;
    else if (isAllShown && item.componentName === "DualSpace") return <DualSpace key={Key(i)} item={item} />;
    else if (isAllShown && item.componentName === "ImageSpace") return <ImageSpace key={Key(i)} item={item} />;
    else if (!item.htmlEl) return HandleReportItemFiltering(item, Key(i));
  };

  const HandleReportItemFiltering = (item, key_) => {
    for (const [key, value] of Object.entries(state)) {
      if (item.componentName.includes(key) && value) return <SelectDesign key={key_} item={item} />;
    }
  };

  const updateButtons = (property, value) => {
    let newState = {};
    if (property === "showAll") {
      for (const [key, value] of Object.entries(state)) newState[key] = true;
    } else
      for (const [key, value] of Object.entries(state)) {
        if (key === property) newState[key] = true;
        else newState[key] = false;
      }

    dispatch(
      updateShowButtons({
        newState,
      })
    );
  };

  const IsAllShown = () => {
    if (state.Summary && state.Detail && state.Mono && state.Dual) return true;
    else return false;
  };

  const GetPdfPreview = (comp) => {
    if (!pdfPreview) {
      setPdfPreview(comp);
      return comp;
    } else {
      setPdfPreview(null);
      return null;
    }
  };

  return (
    <Container style={{ padding: !demoAppData && "120px 0" }}>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <Row className="mb-5">
        <Col xs="6">
          <h1 style={{ textAlign: "center" }}> {appData.name} </h1>
        </Col>
        <Col xs="5" className="flex-center">
          <ButtonGroup>
            <Button variant={IsAllShown() ? "primary" : "secondary"} onClick={() => updateButtons("showAll")}>
              Hepsi
            </Button>
            <Button
              variant={!IsAllShown() && state.Summary ? "primary" : "secondary"}
              onClick={() => updateButtons("Summary")}
            >
              Özet
            </Button>
            <Button
              variant={!IsAllShown() && state.Detail ? "primary" : "secondary"}
              onClick={() => updateButtons("Detail")}
            >
              Detay
            </Button>
            <Button
              variant={!IsAllShown() && state.Mono ? "primary" : "secondary"}
              onClick={() => updateButtons("Mono")}
            >
              Mono
            </Button>
            <Button
              variant={!IsAllShown() && state.Dual ? "primary" : "secondary"}
              onClick={() => updateButtons("Dual")}
            >
              Dual
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs="1">
          <div className="result-page-summary-download-pdf-button-col animate__animated animate__flash animate__delay-1s">
            <PdfDownloadButton
              scheme={scheme}
              appData={appData}
              overlayNotNeeded={true}
              GetPdfPreview={GetPdfPreview}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <ScrollableCard>
            <div className={"_flex-center"}>
              {pdfPreview ? (
                <div style={{ padding: "40px", width: "fit-content" }}>
                  <div className={"shadow-lg"}>{pdfPreview}</div>
                </div>
              ) : (
                <div className="animate__animated animate__fadeIn w-100 mx-3 ">
                  {scheme.map((x, i) => HandleMappingReportItemComponents(x, i))}
                </div>
              )}
            </div>
          </ScrollableCard>
        </Col>
      </Row>

      <div>
        {!demoAppData && (
          <CalculatedParameters
            consistencyScore={data.calculation.consistencyScore}
            leanNumbers={data.calculation.inventoryLeanNumbers}
          />
        )}
      </div>
    </Container>
  );
}
export const NormalizeScore = (item) => {
  const score = item.score;
  const ranges = item.scale.ranges;
  const numOfRanges = ranges.length;
  const oneStep = Math.round(100 / numOfRanges);
  let counter = 0;
  let percentage = 0;
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    if (score > range.start && score > range.end) counter++;
    else if ((score > range.start && score <= range.end) || (range.start === 0 && score === 0)) {
      let filledArea = score - range.start;
      percentage = Number(filledArea / (range.end - range.start)).toFixed(2);
    } else break;
  }

  item.normScore = Math.round(counter * oneStep + percentage * oneStep).toFixed(0);
  if (item.normScore > 100) item.normScore = 100;
  else if (item.normScore < 0) item.normScore = 0;

  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    const start = i * oneStep;
    const end = (i + 1) * oneStep;
    if ((item.normScore > start && item.normScore <= end) || (range.start === 0 && item.normScore === 0))
      range.isHit = true;
  }
};
