import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";

import UseAuthHelper from "../../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import { Overlay } from "../../../../../Helpers/Overlay";
import BreadCrumb from "../../../../Dashboard/BreadCrumb";
import { ScoringScale } from "../ScoringScale";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

const MySwal = withReactContent(Swal);

export function MatchQuestions() {
  const { t } = useTranslation();
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const { state } = useLocation();

  const [questionsAndScales, setQuestionsAndScales] = useState({
    inventory: null,
    questions: [],
    scales: [],
  });

  const [showOverlay, setShowOverlay] = useState(false);
  const [factorModal, setFactorModal] = useState(false);
  const [factor, setFactor] = useState(100);
  const [clickedOrder, setClickedOrder] = useState(null);
  const [targetOrder, setTargetOrder] = useState(null);
  const [cleanScales, setCleanScales] = useState(false);

  useEffect(() => {
    if (questionsAndScales.questions.length == 0) {
      GetQuestions();
    }
  }, []);

  const GetQuestions = () => {
    setShowOverlay(true);
    ApiHelper.getAsync(
      "inventory/GetQuestionsAndMatches",
      { Id: state.id },
      function (result) {
        FilterQuestions(result.data.data);
      },
      user.token
    );
  };

  const DeleteMatchesImage = () => {
    setShowOverlay(true);
    ApiHelper.getAsync(
      "inventory/DeleteQuestionsAndMatches",
      { inventoryId: questionsAndScales.inventory.id },
      function (result) {
        if (result.data.statusCode) {
          GetQuestions();
        } else alert(result.data.data);
      },
      user.token
    );
  };

  const HandleMatchingIdsAndOrders = (q, matchArray, questionArr, scope, subScope) => {
    if (q.checkbox) return;
    let matchData;
    if (matchArray) {
      matchData = matchArray.find((x) => x.id == q.id || x.matchId == q.id);
      if (matchData) {
        if (matchData.matchId == q.id) {
          q.matchId = matchData.id;
          q.matchOrder = matchData.order;
        } else if (matchData.id == q.id) {
          q.matchId = matchData.matchId;
          q.matchOrder = matchData.matchOrder;
        }
        q.matchFactor = matchData.factor;
      }
    }
    q.scope = scope.name;
    q.scopeId = scope.id;
    if (subScope) {
      q.subScope = subScope.name;
      q.subScopeId = subScope.id;
    }
    q.isManipulation = subScope?.isManipulation || scope?.isManipulation;
    q.isConsistency = subScope?.isConsistency || scope?.isManipulation;
    questionArr.push(q);
  };

  const FilterQuestions = (inventory) => {
    let questionArr = [];
    let matchArray = inventory.questionMatchesImage && JSON.parse(inventory.questionMatchesImage.matchesJson);
    inventory.scopes.forEach((scope) => {
      scope.questions.forEach((sq) => {
        HandleMatchingIdsAndOrders(sq, matchArray, questionArr, scope, null);
      });
      scope.subScopes.forEach((subScope) => {
        subScope.questions.forEach((ssq) => {
          HandleMatchingIdsAndOrders(ssq, matchArray, questionArr, scope, subScope);
        });
      });
    });

    questionArr.sort(function (a, b) {
      return a.order - b.order;
    });

    setQuestionsAndScales({
      inventory,
      questions: questionArr,
      scales: inventory?.questionMatchesImage?.scales ? inventory.questionMatchesImage.scales : [],
    });
    setShowOverlay(false);
  };

  const SaveOrder = () => {
    setShowOverlay(true);
    let filteredScopeQuestions = [];
    let filteredSubScopeQuestions = [];
    for (let i = 0; i < questionsAndScales.questions.length; i++) {
      const q = questionsAndScales.questions[i];
      if (q.matchId) {
        if (q.scope != undefined)
          filteredScopeQuestions.push({
            id: q.id,
            order: q.order,
            matchOrder: q.matchOrder,
            matchId: q.matchId,
            factor: Number(q.matchFactor),
          });
        else
          filteredSubScopeQuestions.push({
            id: q.id,
            order: q.order,
            matchId: q.matchId,
            matchOrder: q.matchOrder,
            factor: Number(q.matchFactor),
          });
      }
    }
    var merged = filteredScopeQuestions.length
      ? MergeRepetiteve(filteredScopeQuestions)
      : MergeRepetiteve(filteredSubScopeQuestions);

    ApiHelper.postAsync(
      "inventory/UpsertQuestionMatching",
      {
        inventoryId: state.id,
        MatchesJson: JSON.stringify(merged),
        isSub: filteredScopeQuestions.length ? false : true,
        scales: questionsAndScales.scales,
      },
      function (result) {
        setShowOverlay(false);
        if (result.status != 200) {
        } else if (result.data.statusCode == 1) {
          FilterQuestions(result.data.data[0].scope);
          MySwal.fire(<p>Envanter eşlemesi kaydedildi</p>);
        } else {
        }
      },
      user.token
    );
  };

  const MergeRepetiteve = (questions) => {
    let mergedQuestions = [];
    questions.forEach((q) => {
      var matchQuestion = questions.find((x) => x.matchId == q.id);
      if (!mergedQuestions.find((x) => x.matchId == matchQuestion.id)) mergedQuestions.push(matchQuestion);
    });
    return mergedQuestions;
  };

  const ChangeOrder = (order) => {
    if (targetOrder > questionsAndScales.questions.length || targetOrder == null || targetOrder == order) return;
    setClickedOrder(order);
    setFactorModal(true);
  };

  const HandlePolared = (data) => {
    return (
      <React.Fragment>
        <Col>
          Sol Kutup:
          <span style={{ fontWeight: "bold" }}> {data.leftPolar}</span> Sağ Kutup:{" "}
          <span style={{ fontWeight: "bold" }}>{data.rightPolar}</span>{" "}
        </Col>
      </React.Fragment>
    );
  };

  const HandleLikert = (data) => {
    return (
      <Col>
        <span style={{ fontWeight: "bold" }}> {data.questionText} </span>
      </Col>
    );
  };

  const HandleQuestion = (question, i) => {
    if (question.checkbox) return;
    return (
      <Card key={i}>
        <Card.Body style={{ padding: "5px 15px" }}>
          <Row xs="auto">
            <Col>Id: {question.id} </Col>
            <Col>{question.scope}</Col>
            <Col>{question.subScope}</Col>
            {question.isManipulation && (
              <Col>
                <Badge bg="info">Manipülasyon</Badge>
              </Col>
            )}
            {question.isConsistency && (
              <Col>
                <Badge bg="warning">Tutarlılık</Badge>
              </Col>
            )}
          </Row>
          <Row xs="auto">
            <Col xs={12} md={question.matchOrder ? 6 : 8}>
              {question.polared ? HandlePolared(question.polared, true) : HandleLikert(question.likert, true)}
            </Col>
            {question.matchOrder && (
              <Col xs={12} md={2}>
                Eşlenik=
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    paddingRight: "10px",
                  }}
                >
                  {question.matchOrder}
                </span>
                %{question.matchFactor}
                <span className="show-cursor" style={{ float: "right" }} onClick={() => RemoveMatch(question)}>
                  <IconSvg icon="trash-can" />
                </span>
              </Col>
            )}
            <Col xs={12} md={2}>
              <h4>{question.order}</h4>
            </Col>
            <Col xs={12} md={2}>
              <InputGroup size="sm" className="mb-3">
                <Button variant="primary" size="sm" onClick={() => ChangeOrder(question.order)}>
                  {t("match")}
                </Button>
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  type="number"
                  min="1"
                  max={questionsAndScales.questions.length}
                  step="1"
                  placeholder={question.order}
                  onChange={(e) => {
                    HandleQuestionMatchInput(e);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const HandleQuestionMatchInput = (e) => {
    setTargetOrder(e.target.value);
  };

  const HandleSettingFactor = (event) => {
    if (event.keyCode == 13) HandleCompleteMatching(event.target.value);
  };

  const HandleSettingFactor_ = () => {
    HandleCompleteMatching(factor);
  };

  const HandleCompleteMatching = (factor) => {
    let questions_ = JSON.parse(JSON.stringify(questionsAndScales.questions));
    const targetQuestion = questions_.find((q) => q.order == clickedOrder);
    const targetQuestion2 = questions_.find((q) => q.order == targetOrder);
    if (targetQuestion == null || targetQuestion2 == null) return;
    if (targetQuestion.matchId) {
      const matchQuestion = questions_.find((q) => q.id == targetQuestion.matchId);
      matchQuestion.matchId = null;
      matchQuestion.matchOrder = null;
      matchQuestion.matchFactor = null;
    }
    if (targetQuestion2.matchId) {
      const matchQuestion = questions_.find((q) => q.id == targetQuestion2.matchId);
      matchQuestion.matchId = null;
      matchQuestion.matchOrder = null;
      matchQuestion.matchFactor = null;
    }
    targetQuestion.matchOrder = targetQuestion2.order;
    targetQuestion.matchId = targetQuestion2.id;
    targetQuestion.matchFactor = factor;
    targetQuestion2.matchOrder = targetQuestion.order;
    targetQuestion2.matchId = targetQuestion.id;
    targetQuestion2.matchFactor = factor;

    setQuestionsAndScales({ ...questionsAndScales, questions: questions_ });
    setTargetOrder(null);
    setClickedOrder(null);
    setFactorModal(false);
    setFactor(100);
  };

  const HandleAddScoreScale = (data) => {
    setQuestionsAndScales({ ...questionsAndScales, scales: data });
  };

  const RemoveMatch = (question) => {
    let questions_ = JSON.parse(JSON.stringify(questionsAndScales.questions));
    const targetQuestion = questions_.find((q) => q.order == question.order);
    const targetQuestion2 = questions_.find((q) => q.order == question.matchOrder);
    if (targetQuestion == null || targetQuestion2 == null) return;

    targetQuestion.matchOrder = null;
    targetQuestion.matchId = null;
    targetQuestion.matchFactor = null;
    targetQuestion2.matchOrder = null;
    targetQuestion2.matchId = null;
    targetQuestion2.matchFactor = null;

    setQuestionsAndScales({ ...questionsAndScales, questions: questions_ });
    setTargetOrder(null);
    setClickedOrder(null);
    setFactorModal(false);
    setFactor(100);
  };

  return (
    <div className="sadmin-page-container">
      {showOverlay && <Overlay text="-" />}
      <BreadCrumb backUrl="/inventory" pageName="Soru Eşle" backPageName="Envanterler" />

      <Card body className="mb-3">
        <Row xs="auto">
          <Col xs="12" md="2">
            <h6>
              {t("inventoryNameInfoLabel")} : {state.name}
            </h6>
          </Col>
          <Col xs="12" md="2">
            <h6>
              {questionsAndScales.questions.length} {t("QuestionCountInfoLabel")}
            </h6>
          </Col>
          <Col xs="12" md="8">
            <Row xs="auto" className="justify-content-md-end">
              <Col>
                <Button variant="primary" size="sm" onClick={SaveOrder}>
                  {t("save")}
                </Button>
              </Col>
              {questionsAndScales?.inventory?.questionMatchesImage && (
                <Col>
                  <Button variant="danger" size="sm" onClick={DeleteMatchesImage}>
                    {t("deleteCurrentRecordButtonLabel")}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <br></br>
        <ScoringScale HandleAddScoreScale={HandleAddScoreScale} data={questionsAndScales.scales} clean={cleanScales} />
      </Card>

      {questionsAndScales.questions?.map((question, i) => (
        <React.Fragment key={i}>{HandleQuestion(question, i)}</React.Fragment>
      ))}

      <br></br>
      <br></br>
      <br></br>
      <Modal show={factorModal} centered onHide={() => setFactorModal(false)}>
        <Modal.Body>
          <Row>
            <Col xs="2"> K= {factor}</Col>
            <Col xs="8">
              <Form.Range
                type="range"
                max={100}
                min={-100}
                step={1}
                value={factor}
                onKeyDown={HandleSettingFactor}
                onChange={(e) => setFactor(e.target.value)}
                aria-label="Medium"
                aria-describedby="inputGroup-sizing-md"
              />
            </Col>
            <Col xs="2">
              <Button variant="primary" onClick={HandleSettingFactor_}>
                {t("match")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
