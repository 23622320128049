import React, { useEffect } from "react";
import CoverSection from "./PdfSections/CoverSection";
import InfoSection from "./PdfSections/InfoSection";
import SummarySection from "./PdfSections/SummarySection/SummarySection";
import ScalesSection from "./PdfSections/ScaleSection/ScalesSection";
import { TestDetailSectionB2c } from "./B2cPdfComponents/B2cDetailSection/B2cTestDetail/TestDetailB2c";

export function PdfFile(props) {
  let scalePageIds = [];
  useEffect(() => {
    props.StartDownloadPdf(props.id, scalePageIds, props.isTest);
  }, []);

  let HandleScalePagesIds = (id) => {
    scalePageIds.push(id);
  };

  let coverImage = props.isTest ? props.data?.pdfCoverImageName : props.data?.scales?.pdfCoverImageName;

  let infoImage = props.isTest ? props.data?.pdfInfoImageName : props.data?.scales?.pdfInfoImageName;
  let demography = props.demography ? props.demography : props.result;

  if (!props.isTest)
    props.data.scales.scope.forEach((scope) => {
      scope.score = props.data?.result?.calculatedScopes?.find((x) => {
        return !x.isSub && x.scopeId == scope.id;
      });
      scope.subScopes.forEach((subScope) => {
        subScope.score = props.data?.result?.calculatedScopes?.find((x) => {
          return x.isSub && x.scopeId == subScope.id;
        });
      });
    });

  return (
    <div
      id={"result" + props.id}
      style={{
        position: "absolute",
        width: "210mm",
        margin: "0mm",
        padding: "0mm",
        top: "0mm",
        left: "0mm",
      }}
    >
      {coverImage && <CoverSection demography={demography} id={props.id} coverImage={coverImage} />}
      {infoImage && <InfoSection data={props.data} id={props.id} infoImage={infoImage} />}

      {props.isTest ? (
        <TestDetailSectionB2c data={props.data} id={props.id} isPdf={true} candidateName={demography.name} />
      ) : (
        <>
          <SummarySection data={props.data} id={props.id} candidateName={demography.name} />
          <ScalesSection
            data={props.data}
            id={props.id}
            HandleScalePagesIds={HandleScalePagesIds}
            candidateName={demography.name}
          />
        </>
      )}
    </div>
  );
}
