import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function ScrollableCard(props) {
  const [maxHeight, setMaxHeight] = useState(0);
  const divRef = useRef(null);
  let desiredBottom = props.bottom !== undefined ? props.bottom : 20;
  let desiredTop = props.top !== undefined ? props.top : 20;

  useEffect(() => {
    const handleResize = () => {
      let topOfDiv = 0;
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        topOfDiv = rect.top;
        setMaxHeight(window.innerHeight - desiredBottom - topOfDiv);
      } else {
        console.log("error occurred on ScrollableCard, divRef.current unavailable");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [desiredBottom]);

  const componentRef = useRef();

  return (
    <div className={"overflow-y-auto"} style={{ marginTop: desiredTop }}>
      <PerfectScrollbar>
        <div ref={divRef} style={{ maxHeight: `${maxHeight}px`, minHeight: 250 }}>
          {props.children}
          <br></br>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
