export const ScopeApiUrls = {
  getScopes: "scope/GetScopes",
  createScope: "scope/CreateScope",
  deleteScope: "scope/DeleteScope",
  updateScope: "scope/UpdateScope",
  getSubScope: "subScope/GetSubScopes",
  createSubScope: "subscope/CreateSubScope",
  deleteSubScope: "subscope/DeleteSubScope",
  updateSubScope: "subscope/UpdateSubScope",
};

export default class ScopeAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getScopes(data, token) {
    return this.get(ScopeApiUrls.getScopes, data, token);
  }

  async deleteScope(data, token) {
    return this.get(ScopeApiUrls.deleteScope, data, token);
  }

  async createScope(data, token) {
    return this.post(ScopeApiUrls.createScope, data, token);
  }

  async updateScope(data, token) {
    return this.post(ScopeApiUrls.updateScope, data, token);
  }

  async getSubScope(data, token) {
    return this.get(ScopeApiUrls.getSubScope, data, token);
  }

  async deleteSubScope(data, token) {
    return this.get(ScopeApiUrls.deleteSubScope, data, token);
  }

  async createSubScope(data, token) {
    return this.post(ScopeApiUrls.createSubScope, data, token);
  }

  async updateSubScope(data, token) {
    return this.post(ScopeApiUrls.updateSubScope, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);

    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
