import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ApplicationDTO from "../../DTO/ApplicationDTO";
import { upsertLeanParameters } from "../../Store/Thunk";
import RangeParameter from "./RangeParameter";
import { updateLeanApplication, closeLeanModal } from "../../Store";
import { useTranslation } from "react-i18next";

export default function LeanModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowModal = useSelector((state) => state.entityList.leanModal.isShow);
  const application = useSelector((state) => state.entityList.leanModal.application);
  const leanModal = useSelector((state) => state.entityList.leanModal);

  const updateInventoryLeanParameter = (property, value) => {
    dispatch(
      updateLeanApplication({
        ...leanModal,
        application: {
          ...application,
          inventoryLeanParameters: {
            ...application.inventoryLeanParameters,
            [property]: value,
          },
        },
      })
    );
  };

  const UpsertLeanParameters = (application) => {
    const postObj = new ApplicationDTO(application);

    dispatch(upsertLeanParameters(postObj));
    dispatch(closeLeanModal());
  };

  const parameterNames = [
    { prop: "highRange", label: t("highRange") },
    { prop: "midRange", label: t("midRange") },
    { prop: "lowRange", label: t("lowRange") },
    { prop: "edgeRange", label: t("spacingOfTips") },
    { prop: "highRate", label: t("highFrequency") },
    { prop: "midRate", label: t("mediumFrequency") },
    { prop: "lowRate", label: t("lowFrequency") },
    { prop: "edgeRate", label: t("frequencyOfLeads") },
  ];

  const closeModal = () => {
    dispatch(closeLeanModal());
  };

  return (
    <Modal show={isShowModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {application?.id} {application?.name} {t("predispositionParameters")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {parameterNames.map(({ prop, label }) => (
            <RangeParameter
              key={prop}
              label={label}
              value={application?.inventoryLeanParameters?.[prop]}
              onChange={(e) => updateInventoryLeanParameter(prop, e.target.value)}
            />
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => UpsertLeanParameters(application)}>
          {t("save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
