import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Summary_LoadingBar({ item, isSub }) {
  return (
    <Row className="mb-3" style={{ height: item.height && item.height + "mm" }}>
      <Col xs={3}>
        <span className={!isSub ? "_main-scope-name-pdf" : "_sub-scope-names-pdf"}>{item.name}</span>
        <span style={{ display: "inline-block", float: "right" }}>
          <span className={!isSub ? "_main-scope-score-pdf" : "_sub-scope-score-pdf"}>{item.score}</span>
        </span>
      </Col>
      <Col xs={9}>
        <div
          style={{
            paddingTop: "12px",
          }}
        >
          <ProgressBar variant={isSub ? "sub-scope" : "main-scope"} now={item.score} label={`${item.score}%`} />
        </div>
      </Col>
    </Row>
  );
}
