import { createSlice } from "@reduxjs/toolkit";
import { updateTest, deleteTest, getTests, upsertLeanParameters } from "./Thunk";

export const entityList = createSlice({
  name: "entityList",
  initialState: {
    isTest: false,
    tests: [],
    isLoading: false,
    leanModal: {
      isShow: false,
      application: {},
    },
    entityModal: {
      isShow: false,
      data: {
        name: "",
        description: "",
        duration: 0,
        scales: [],
        cleanScoringComp: false,
      },
    },
    infoModal: {
      isShow: false,
      application: {},
    },
  },
  reducers: {
    updateIsTest(state, action) {
      state.isTest = action.payload;
    },
    openLeanModal(state, action) {
      state.leanModal.application = action.payload;
      state.leanModal.isShow = true;
    },
    updateLeanApplication(state, action) {
      state.leanModal = action.payload;
    },
    closeLeanModal(state) {
      state.leanModal.application = {};
      state.leanModal.isShow = false;
    },
    openEntityModal(state, action) {
      state.entityModal.data = action.payload;
      state.entityModal.isShow = true;
    },
    updateEntityApplication(state, action) {
      state.entityModal.data = action.payload;
    },
    closeEntityModal(state) {
      state.entityModal.data = {};
      state.entityModal.isShow = false;
    },
    openInfoModal(state, action) {
      state.infoModal.isShow = true;
      state.infoModal.application = action.payload;
    },
    updateInfoApplication(state, action) {
      state.infoModal.application = action.payload;
    },
    closeInfoModal(state) {
      state.infoModal.application = {};
      state.infoModal.isShow = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTest.fulfilled, (state, action) => {
      state.tests = action.payload;
    });
    builder.addCase(deleteTest.fulfilled, (state, action) => {
      state.tests = action.payload;
    });
    builder.addCase(getTests.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTests.fulfilled, (state, action) => {
      state.tests = action.payload;
      state.isLoading = false;
    });
    builder.addCase(upsertLeanParameters.fulfilled, (state, action) => {
      state.tests = action.payload;
    });
  },
});

export const {
  updateIsTest,
  openLeanModal,
  updateLeanApplication,
  closeLeanModal,
  openEntityModal,
  updateEntityApplication,
  closeEntityModal,
  openInfoModal,
  updateInfoApplication,
  closeInfoModal,
} = entityList.actions;

export default entityList.reducer;
