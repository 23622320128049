import Col from "react-bootstrap/Col";
import React from "react";
import { useDispatch } from "react-redux";

import IconSvg from "../../../../../../App/AppMenu/IconSvg";
//todo
// import { getApplicationBackup } from "../../Store/Thunk";

export default function BackUpButton({ application }) {
  const dispatch = useDispatch();

  const download = () => {
    alert("yapım aşamasında");
    //todo
    // dispatch(
    //   getApplicationBackup({ id: application.id, name: application.name })
    // );
  };

  return (
    <Col className="show-cursor" onClick={download}>
      <IconSvg icon="download" disabled={true} />
    </Col>
  );
}
