import React from "react";
import { Card, Row } from "react-bootstrap";
import RowInfo from "./RowInfo";
import RowActions from "./RowActions";
import RowButtons from "./RowButtons";

export default function InventoryListRow({ inventory }) {
  var editedInventory = { ...inventory, isTest: false };
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row xs="auto">
          <RowInfo inventory={editedInventory} />
          <RowActions inventory={editedInventory} />
          <RowButtons inventory={editedInventory} />
        </Row>
      </Card.Body>
    </Card>
  );
}
