import React from "react";
import { useTranslation } from "react-i18next";

export default function PageTabs(props) {
  const { t } = useTranslation();
  const clickeClass = "project-page-tab-button show-cursor clicked-project-page-tab-button";
  const notClickedClass = "project-page-tab-button show-cursor";
  return (
    <div className="project-page-tabs-container ">
      <div className="project-page-tabs">
        <div className={props.currentTab === 1 ? clickeClass : notClickedClass} onClick={() => props.setCurrentTab(1)}>
          {t("createProject")}
        </div>
        <div className={props.currentTab === 2 ? clickeClass : notClickedClass} onClick={() => props.setCurrentTab(2)}>
          {t("submissionReport")}
        </div>
      </div>
    </div>
  );
}
