import { HashRouter, Route, Routes } from "react-router-dom";
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import AppLayout from "./AppMenu/AppLayout";

// import AppHeader from "./AppHeader";
// import AppFooter from "./AppFooter";
//Pages
import { SuperAdminDash } from "../Components/Dashboard/SuperAdmin/SuperAdminDash";
import { AdminDash } from "../Components/Dashboard/Admin/Dashboard/AdminDash";
import { UserDash } from "../Components/Dashboard/User/UserDash/UserDash";
import { Register } from "./../Components/LoginRegister/Register";
import { Login } from "./../Components/LoginRegister/Login";
import { Confirmation } from "./../Components/LoginRegister/Confirmation";
import Inventory from "../Features/CreateApplication/Inventory";
import Test from "../Features/CreateApplication/Test";
import Survey from "../Features/CreateApplication/Survey";
import ApplicationResults from "../Features/ApplicationResults";
import InventoryAnswers from "../Features/CreateApplication/InventoryAnswers";
import { GoToInventory } from "./../Components/Application/AnsweringApplication/AnswerInventoryApp/GoToInventory";
import { GoToTest } from "../Components/Application/AnsweringApplication/AnswerTestApp/GoToTest";
import { Scope, SubScope } from "../Features/CreateApplication/Scope";
import { Polar } from "./../Components/Application/CreateApplication/Scope/Question/ScopeQuestion/Polar";
import { Likert } from "./../Components/Application/CreateApplication/Scope/Question/ScopeQuestion/Likert";
import { CheckBox } from "./../Components/Application/CreateApplication/Scope/Question/ScopeQuestion/CheckBox";
import { Question } from "../Components/Application/CreateApplication/Scope/Question/ScopeQuestion/Question";
import { ReorderQuestion } from "./../Components/Application/CreateApplication/Scope/Question/ReorderQuestion";
import { ReorderAdjQuestion } from "./../Components/Application/CreateApplication/Scope/Question/ReorderAdjQuestion";
import LoadCredit from "../Features/Credit";
import { LandingPage } from "./../Components/LoginRegister/LandingPage";
import { Projects } from "../Components/Dashboard/Admin/Project/Projects";
import { UserLogin } from "../Components/Dashboard/User/UserLogin";
import { TicketResults } from "../Components/Dashboard/Admin/Results/TicketResults";
import { WrapUp } from "../Components/Dashboard/Admin/Results/WrapUp";

import { Settings } from "../Components/Dashboard/Admin/Settings/Settings";
import { MyResults } from "../Components/Dashboard/User/MyResults/MyResults";
import { MatchQuestions } from "../Components/Application/CreateApplication/Scope/Question/MatchQuestions";

import { AuthProvider } from "../Helpers/Auth/AuthContext";
import { RequireAuth } from "../Helpers/Auth/RequireAuth";
import { CandidateDemography } from "../Components/Dashboard/User/CandidateDemography";
import BasicGraphSummary from "../Features/CreateApplication/ReportItems/BasicGraphSummary";
import BasicGraphDetail from "../Features/CreateApplication/ReportItems/BasicGraphDetail";
import MonoTextuary from "../Features/CreateApplication/ReportItems/MonoTextuary";
import DualTextuary from "../Features/CreateApplication/ReportItems/DualTextuary";
import ReportScheme from "../Features/CreateApplication/ReportScheme";
import Assessment from "../Features/CreateApplication/Assessment";
import ApplicationInfo from "../Features/CreateApplication/ApplicationInfo";
import WrapUpPage from "../Features/WrapUpPage/WrapUpPage";

import "bootstrap/dist/css/bootstrap.css";
import "react-tooltip/dist/react-tooltip.css";
import "./App.css";

function App() {
  const prevLocation = window.location.href;
  return (
    <AuthProvider>
      <HashRouter>
        <AppLayout>
          <Routes>
            <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
              <Route path="/Sdash" element={<SuperAdminDash />}></Route>
              <Route path="/assessment" element={<Assessment />}></Route>
              <Route path="/inventory" element={<Inventory />}></Route>
              <Route path="/survey" element={<Survey />}></Route>
              <Route path="/test" element={<Test />}></Route>
              <Route path="/info" element={<ApplicationInfo />}></Route>
              <Route path="/answers" element={<InventoryAnswers />}></Route>
              <Route path="/ApplicationResults/" element={<ApplicationResults />}></Route>
              <Route path="/scope" element={<Scope />}></Route>
              <Route path="/subscope" element={<SubScope />}></Route>
              <Route path="/polar" element={<Polar />}></Route>
              <Route path="/likert" element={<Likert />}></Route>
              <Route path="/checkbox" element={<CheckBox />}></Route>
              <Route path="/question" element={<Question />}></Route>
              <Route path="/reorder" element={<ReorderQuestion />}></Route>
              <Route path="/reorderadj" element={<ReorderAdjQuestion />}></Route>
              <Route path="/match" element={<MatchQuestions />}></Route>
              <Route path="/load" element={<LoadCredit />}></Route>
              <Route path="/basicGraphSummary" element={<BasicGraphSummary />}></Route>
              <Route path="/basicGraphDetail" element={<BasicGraphDetail />}></Route>
              <Route path="/monotextuary" element={<MonoTextuary />}></Route>
              <Route path="/dualtextuary" element={<DualTextuary />}></Route>
              <Route path="/scheme" element={<ReportScheme />}></Route>
              <Route path="/wrapuppage" element={<WrapUpPage />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route path="/dash" element={<AdminDash />}></Route>
              <Route path="/projects" element={<Projects />}></Route>
              <Route path="/results" element={<TicketResults />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              <Route path="/wrapup/:ticketResultId" element={<WrapUp />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={["User"]} />}>
              <Route path="/userdash" element={<UserDash />}></Route>
              <Route path="/myresults" element={<MyResults />}></Route>
              <Route path="/invapp/:ticketId" element={<GoToInventory b2b={true} />}></Route>
              <Route path="/testapp/:ticketId" element={<GoToTest b2b={true} />}></Route>
              <Route path="/demography" element={<CandidateDemography />}></Route>
              <Route path="/myresult/:ticketResultId" element={<WrapUp />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={["User", "Admin", "SuperAdmin"]} checkLogin={true} />}>
              <Route path="/Login" element={<Login />}></Route>
              <Route path="/Register" element={<Register />}></Route>
              <Route path="/Confirmation" element={<Confirmation />}></Route>
              <Route path="/" element={<LandingPage prevLocation={prevLocation} />}></Route>
            </Route>

            <Route path="/Userlogin/:hash" element={<UserLogin />}></Route>
            <Route path="/iapp/:invId" element={<GoToInventory b2c={true} />}></Route>
            <Route path="/tapp/:testId" element={<GoToTest b2c={true} />}></Route>
          </Routes>
        </AppLayout>
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
