import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export function ResultRow(props) {
  const navigate = useNavigate();
  if (props.ticket?.ticketResult) props.ticket.ticketResult.testId = props.ticket.testId;

  let HandleDateString = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString().substr(-2)}`;
  };

  let GetTime = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  let HandleOpenResultInNewTab = () => {
    if (!props.ticket?.ticketResult?.isFinished) return;
    navigate("/myresult/" + props.ticket?.ticketResult?.id);
  };

  return (
    <Row
      className="ticket-result-table-row-container"
      style={{
        background: !props.ticket?.ticketResult?.isFinished && "#fff0f5",
      }}
    >
      <Col xs="1" md="1">
        <h6
          style={{
            fontWeight: 700,
            color: props.ticket?.ticketResult?.isFinished ? "green" : "red",
          }}
        >
          {props.ticket.id}
        </h6>
      </Col>
      <Col xs="10" md="3">
        <h6>{props.ticket.test.name}</h6>
      </Col>
      <Col xs="6" md="2">
        <h6>
          {HandleDateString(props.ticket.createdAt)}
          <span className="time-stamp">{GetTime(props.ticket.createdAt)}</span>
        </h6>
      </Col>
      <Col xs="6" md="2">
        <h6>
          {HandleDateString(props.ticket.ticketResult?.startDate)}
          <span className="time-stamp">{GetTime(props.ticket.ticketResult?.startDate)}</span>
        </h6>
      </Col>
      <Col xs="6" md="2">
        <h6>
          {HandleDateString(props.ticket.ticketResult?.endDate)}
          <span className="time-stamp">{GetTime(props.ticket.ticketResult?.endDate)}</span>
        </h6>
      </Col>
      <Col xs="6" md="2">
        {props.ticket?.ticketResult?.isFinished && (
          <Button variant="primary" style={{ width: "100%" }} onClick={HandleOpenResultInNewTab}>
            Sonucu Gör
          </Button>
        )}
      </Col>
    </Row>
  );
}
