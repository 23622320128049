import { configureStore } from "@reduxjs/toolkit";
import BasicGraphSummary from "../Features/CreateApplication/ReportItems/BasicGraphSummary/Store";
import BasicGraphDetail from "../Features/CreateApplication/ReportItems/BasicGraphDetail/Store";
import MonoTexruary from "../Features/CreateApplication/ReportItems/MonoTextuary/Store";
import DualTextuary from "../Features/CreateApplication/ReportItems/DualTextuary/Store";
import ReportItemBase from "../Features/CreateApplication/ReportItems/ReportItemBase/Store";
import Auth from "./Auth/index";
import Credit from "../Features/Credit/Store";
import ApplicationResults from "../Features/ApplicationResults/Store";
import ExportExcelAllFilter from "../Features/ExportExcelAllFilter/Store";
import Inventory from "../Features/CreateApplication/Inventory/Store";
import EntityList from "../Features/CreateApplication/EntityList/Store";
import Test from "../Features/CreateApplication/Test/Store";
import Scope from "../Features/CreateApplication/Scope/Store";
import Scale from "../Features/CreateApplication/ScoringScale/Store";
import ReportItem from "../Features/CreateApplication/ReportItems/ReportItem/Store";
import ReportScheme from "../Features/CreateApplication/ReportScheme/Store";
import PdfDownloadButton from "../Features/PdfDownloadButton/Store";
import WrapUp from "../Features/WrapUp/Store";
import Assessment from "../Features/CreateApplication/Assessment/Store";
import Survey from "../Features/CreateApplication/Survey/Store";

export default configureStore({
  reducer: {
    auth: Auth,
    credit: Credit,
    applicationResults: ApplicationResults,
    exportExcelAllFilter: ExportExcelAllFilter,
    inventory: Inventory,
    entityList: EntityList,
    test: Test,
    scope: Scope,
    scale: Scale,
    basicGraphSummary: BasicGraphSummary,
    basicGraphDetail: BasicGraphDetail,
    monoTextuary: MonoTexruary,
    dualTextuary: DualTextuary,
    reportItemBase: ReportItemBase,
    reportItem: ReportItem,
    reportScheme: ReportScheme,
    pdfDownloadButton: PdfDownloadButton,
    wrapUp: WrapUp,
    assessment: Assessment,
    survey: Survey,
  },
});
