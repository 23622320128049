import { createSlice } from "@reduxjs/toolkit";
import { createApplication, getTests } from "./Thunk";

export const test = createSlice({
  name: "test",
  initialState: {
    tests: [],
    isLoading: false,
    test: {
      isTest: true,
      isB2b: false,
      Name: "",
      Description: "",
      Duration: 60,
      scales: [],
      cleanScoringComp: false,
    },
  },
  reducers: {
    updateTest(state, action) {
      state.test[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTests.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTests.fulfilled, (state, action) => {
      state.tests = action.payload;
      state.isLoading = false;
      state.test = {
        isTest: true,
        isB2b: false,
        Name: "",
        Description: "",
        Duration: 60,
        scales: [],
        cleanScoringComp: false,
      };
    });
    builder.addCase(createApplication.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createApplication.fulfilled, (state, action) => {
      state.tests = action.payload;
      state.test = {
        Description: "",
        Name: "",
        IsTest: true,
        IsB2b: false,
        scales: [],
        Duration: 60,
        cleanScoringComp: true,
      };
      state.isLoading = false;
    });
  },
});

export const { updateTest } = test.actions;

export default test.reducer;
