import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../Helpers/ApiHelper";

export default function CoverSection({ imageName, demography, name }) {
  let style = {
    height: "297mm",
    width: "210mm",
    margin: 0,
    padding: 0,
  };

  let background = {
    position: "absolute",
    top: "0mm",
    left: "0mm",
    height: "297mm",
    zIndex: "-100",
    width: "210mm",
    margin: "0mm",
    padding: "0mm",
  };
  return (
    <div style={style} id="cover">
      <Image
        className="pdf-background"
        crossOrigin="anonymous"
        style={background}
        src={ApiHelper.baseURL + "wwwroot/info/" + imageName}
      />
      <div className="_cover-page-candidate-data-container">
        <h3> {name} </h3>
        <p>
          İsim :<span className="_cover-page-candidate-data-span">{demography?.name}</span>
        </p>
        <p>
          E-posta:
          <span className="_cover-page-candidate-data-span">{demography?.email}</span>
        </p>
        <p>
          Doğum yılı:
          <span className="_cover-page-candidate-data-span">{demography?.birthYear}</span>
        </p>
        <p>
          Cinsiyet:
          <span className="_cover-page-candidate-data-span">{demography?.gender}</span>
        </p>
        <p>
          Okul:
          <span className="_cover-page-candidate-data-span">{demography?.school}</span>
        </p>
        <p>
          İş tecrübesi:
          <span className="_cover-page-candidate-data-span">{demography?.experience}</span>
        </p>
      </div>
    </div>
  );
}
