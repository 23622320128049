import { createSlice, current } from "@reduxjs/toolkit";
import { getDemoResults, removeTests } from "./ApplicationResultsThunk";

export const applicationResults = createSlice({
  name: "applicationResults",
  initialState: {
    demoResults: [],
    filteredDemoResults: [],
    selectedTestsId: [],
  },
  reducers: {
    filterTestsByTestId(state, action) {
      state.filteredDemoResults = current(state.demoResults).filter((test) => test.testId === action.payload);
    },
    filterTestsById(state, action) {
      state.filteredDemoResults = current(state.demoResults).filter((test) => test.id === parseInt(action.payload, 10));
    },
    filterTestsByEmail(state, action) {
      state.filteredDemoResults = current(state.demoResults).filter((test) =>
        test.email.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
    cleanFilter(state) {
      state.filteredDemoResults = current(state.demoResults);
    },
    selectTests(state, action) {
      state.selectedTestsId = [...current(state.selectedTestsId), ...action.payload];
    },
    uncheckTest(state, action) {
      const testIndex = current(state.selectedTestsId).indexOf(action.payload);

      state.selectedTestsId.splice(testIndex, 1);
    },
    uncheckAllTests(state) {
      state.selectedTestsId = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDemoResults.fulfilled, (state, action) => {
      state.demoResults = action.payload;
      state.filteredDemoResults = action.payload;
    });
    builder.addCase(removeTests.fulfilled, (state, action) => {
      state.demoResults = action.payload;
      state.filteredDemoResults = action.payload;
    });
  },
});

export const {
  filterTestsByTestId,
  filterTestsById,
  filterTestsByEmail,
  cleanFilter,
  selectTests,
  uncheckTest,
  uncheckAllTests,
} = applicationResults.actions;

export default applicationResults.reducer;
