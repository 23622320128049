import React from "react";
import Button from "react-bootstrap/Button";

const xlsx = require("xlsx");

export function ExportToExcel(props) {
  const HandleExport = () => {
    var title = props.result.testId + "-" + props.result.name + "-" + props.result.id;
    let refactoredData = [RefactorData(props.result)];
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(refactoredData);
    xlsx.utils.book_append_sheet(wb, ws, title);
    xlsx.writeFile(wb, title + ".xlsx");
  };

  return (
    <Button
      variant={props.isActive ? "outline-success" : "outline-danger"}
      size="sm"
      disabled={!props.isActive}
      onClick={HandleExport}
    >
      Excel
    </Button>
  );
}

export const RefactorData = (result) => {
  let parsedPolarLikert = result.questionAnswersJson && JSON.parse(result.questionAnswersJson);
  let parsedAdj = result.checkboxAnswersJson && JSON.parse(result.checkboxAnswersJson);
  let procArr;
  if (parsedPolarLikert && parsedAdj) procArr = parsedAdj.concat(parsedPolarLikert);
  else if (parsedPolarLikert) procArr = parsedPolarLikert;
  else if (parsedAdj) procArr = parsedAdj;

  let obj = {};
  obj.id = result.id;
  obj.email = result.email;
  obj.name = result.name;
  obj.school = result.school;
  obj.gender = result.gender;
  obj.faculty = result.faculty;
  obj.department = result.department;
  obj.experience = result.experience;

  for (let i = 0; i < procArr.length; i++) {
    const q = procArr[i];
    let questionStr = (i + 1).toString() + ")";
    if (q.leftPolar) {
      questionStr += q.leftPolar + "/" + q.rightPolar + "(P)";
      obj[questionStr] = q.answer;
    } else if (q.text) {
      questionStr += q.text + "(L)";
      obj[questionStr] = q.answer;
    } else if (q.questionText) {
      questionStr += q.questionText + "(C)";
      obj[questionStr] = q.checked ? 1 : 0;
    }
  }
  return obj;
};
