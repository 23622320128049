import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CreateScope from "../CreateScope/CreateScope";
import { closeSubScopeModal } from "../../Store";
import { updateSubScope } from "../../Store/Thunk";

export default function SubScopeModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isShow = useSelector((state) => state.scope.subScopeModal.isShow);
  const data = useSelector((state) => state.scope.subScopeModal.data);

  const update = (subScope) => {
    dispatch(updateSubScope({ subScope, scopeId: state.scope.id }));
    dispatch(closeSubScopeModal());
  };

  const close = () => {
    dispatch(closeSubScopeModal());
  };

  return (
    <Modal show={isShow} fullscreen={true} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title>{t("updateSubDimension")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mt-5">
          <CreateScope
            UpdateScope={update}
            test={state.test}
            uiString={t("subScope")}
            data={data}
            scopeId={state.scope.id}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
