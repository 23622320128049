export const DualTextuaryApiUrls = {
  createDualTextuary: "reportitem/CreateDualTextuary",
  updateDualTextuary: "reportitem/UpdateDualTextuary",
  deleteDualTextuary: "reportitem/DeleteDualTextuary",
  getDualTextuaries: "reportitem/GetDualTextuaries",
};

export default class DualTextuaryAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async createDualTextuary(data, token) {
    return this.post(DualTextuaryApiUrls.createDualTextuary, data, token);
  }

  async updateDualTextuary(data, token) {
    return this.post(DualTextuaryApiUrls.updateDualTextuary, data, token);
  }

  async getDualTextuaries(data, token) {
    return await this.get(DualTextuaryApiUrls.getDualTextuaries, data, token);
  }

  async deleteDualTextuary(data, token) {
    return await this.get(DualTextuaryApiUrls.deleteDualTextuary, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
