import React from "react";
import ApiHelper from "../../../../../Helpers/ApiHelper";

export default function ImageSpace({ item }) {
  return (
    <div className="flex-center mb-3">
      <img style={{ maxWidth: "100%" }} src={ApiHelper.baseURL + "wwwroot/scheme/" + item.imageName} />
    </div>
  );
}
