import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Summary_LoadingBar({ item, isSub }) {
  return (
    <Row className="mb-3">
      <Col xs={3}>
        <span className={!isSub ? "main-scope-name-pdf" : "sub-scope-names-pdf"}>{item.name}</span>
        <span style={{ display: "inline-block", float: "right" }}>
          <span className={!isSub ? "main-scope-score-pdf" : "sub-scope-score-pdf"}>
            {item.score + "==" + item.normScore}
          </span>
        </span>
      </Col>
      <Col xs={9}>
        <div
          style={{
            paddingTop: "12px",
          }}
        >
          <ProgressBar
            variant={isSub ? "sub-scope" : "main-scope"}
            now={item.normScore}
            label={`${item.normScore}%`}
            style={{ height: "20px" }}
          />
        </div>
      </Col>
    </Row>
  );
}
