import { createSlice, current } from "@reduxjs/toolkit";

export const scale = createSlice({
  name: "scale",
  initialState: {
    isHtml: false,
    isDual: false,
    ranges: [],
    range: {
      text: "",
      rightText: "",
      end: "",
      start: "",
    },
    resetWysiwyg: false,
    isOpenDescriptionModal: false,
    isOpenDetailedDescriptionModal: false,
  },
  reducers: {
    setIsDual(state, action) {
      state.isDual = action.payload;
    },
    setIsHtml(state, action) {
      state.isHtml = action.payload;
    },
    setScales(state, action) {
      state.ranges = action.payload || [];
    },
    addRange(state, action) {
      state.ranges.push(action.payload);
      state.range = {
        text: "",
        rightText: "",
        end: "",
        start: action.payload.end !== 100 && action.payload.end + 1,
      };
    },
    updateRangeState(state, action) {
      const { property, value } = action.payload;

      state.range = {
        ...current(state.range),
        [property]: value,
      };
    },
    deleteAllScales(state) {
      state.ranges = [];
      state.range = {
        text: "",
        rightText: "",
        end: "",
        start: "",
      };
    },
    resetWysiwyg(state, action) {
      state.resetWysiwyg = action.payload;
    },
    openDetailedDescriptionModal(state, action) {
      state.isOpenDetailedDescriptionModal = true;
      state.modalRange = action.payload;
    },
    closeDetailedDescriptionModal(state) {
      state.isOpenDetailedDescriptionModal = false;
      state.modalRange = null;
    },
    openDescriptionModal(state) {
      state.isOpenDescriptionModal = true;
    },
    closeDescriptionModal(state) {
      state.isOpenDescriptionModal = false;
    },
  },
});

export const {
  setIsHtml,
  addScale,
  updateLocalScale,
  deleteAllScales,
  resetWysiwyg,
  openDescriptionModal,
  closeDescriptionModal,
  setScales,
  addRange,
  updateRangeState,
  openDetailedDescriptionModal,
  closeDetailedDescriptionModal,
  setIsDual,
} = scale.actions;

export default scale.reducer;
