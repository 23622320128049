import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function Statistics(props) {
  const { t } = useTranslation();
  return (
    <div className="admin-statistics-container">
      <Row style={{ height: "50%", borderBottom: "4px solid #ECECEC", margin: 0 }}>
        <Col className="statistic-container-col " style={{ borderRight: "4px solid #ECECEC" }}>
          <h6 style={{ color: "#00428A" }}>{t("today")}</h6>
          <h1 style={{ color: "#878787" }}>{props.statistics?.lastDayCompleted}</h1>
        </Col>
        <Col className="statistic-container-col">
          <h6 style={{ color: "#00428A" }}>{t("last7Days")}</h6>
          <h1 style={{ color: "#878787" }}>{props.statistics?.last7daysCompleted}</h1>
        </Col>
      </Row>
      <Row style={{ margin: 0, height: "50%" }}>
        <Col className="statistic-container-col" style={{ borderRight: "4px solid #ECECEC" }}>
          <h6 style={{ color: "#00428A" }}>{t("last30Days")}</h6>
          <h1 style={{ color: "#878787" }}>{props.statistics?.last30daysCompleted}</h1>
        </Col>
        <Col className="statistic-container-col">
          <h6 style={{ color: "#00428A" }}>{t("last90Days")}</h6>
          <h1 style={{ color: "#878787" }}>{props.statistics?.last90daysCompleted}</h1>
        </Col>
      </Row>
    </div>
  );
}
