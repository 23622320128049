import { createAsyncThunk } from "@reduxjs/toolkit";

import AuthController from "../../Controllers/AuthController";

import HttpsClient from "../../Services/HttpsClient";
import ApiHelper from "../../Services/ApiHelper";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);

const authController = new AuthController(apiHelper);

export const login = createAsyncThunk("user/login", async (credentials) => {
  const response = await authController.login(credentials);

  return response.data;
});
