import React from "react";
import { ProgressBar, Row, Col } from "react-bootstrap";
import ParameterProgressBar from "./ParameterProgressBar";

export default function LeanNumbers({ numbers }) {
  return (
    <div>
      <h1> Yönelme Analizleri</h1>
      <ParameterProgressBar name="Uçlara Yönelim" score={numbers.edgeLean} />
      <ParameterProgressBar name="Yüksek Uca Yönelim" score={numbers.highLean} />
      <ParameterProgressBar name="Düşük Uca Yönelim " score={numbers.lowLean} />
      <ParameterProgressBar name="Ortalara Yönelim" score={numbers.middleLean} />
    </div>
  );
}
