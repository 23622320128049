import React from "react";
import ReportItemEntityBreadCrumb from "./ReportItemEntityBreadCrumb";
import { Card, Row, Col, Accordion } from "react-bootstrap";
import ScaleEntity from "./ScaleEntity";

export default function BasicGraphDetailEntity({ entity }) {
  const CreateHtmlElement = (val, headerText) => {
    return (
      <Col>
        <Card>
          <Card.Header> {headerText} </Card.Header>
          <Card.Body>
            <div dangerouslySetInnerHTML={{ __html: val }} />
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <ReportItemEntityBreadCrumb entity={entity} itemName="Detay" />
        </Accordion.Header>
        <Accordion.Body>
          <Row className="mb-3">
            {CreateHtmlElement(entity?.lowSideText, "Sol ölçek açıklaması")}
            {CreateHtmlElement(entity?.generalInformationText, "açıklama")}
            {CreateHtmlElement(entity?.highSideText, "Sağ ölçek açıklması")}
          </Row>

          <ScaleEntity scale={entity?.scale} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
