import { useLocation, Navigate, Outlet } from "react-router-dom";
import UseAuthHelper from "./UseAuthHelper";
import { setUser } from "../../Store/Auth/index";
import { useDispatch } from "react-redux";

export const RequireAuth = ({ allowedRoles, checkLogin }) => {
  const dispatch = useDispatch();

  const { location } = useLocation();
  const { auth } = UseAuthHelper();
  const { setAuth } = UseAuthHelper();

  if (!auth.data) {
    let localAuthData = localStorage.cpdata && JSON.parse(localStorage.cpdata);
    if (localAuthData) {
      setAuth({ data: localAuthData });
      auth.data = localAuthData;
      dispatch(setUser(localAuthData));
    }
  }

  if (checkLogin) {
    if (auth.data) {
      let role = auth.data.userRole[0];
      if (role === "SuperAdmin") return <Navigate to="/sdash" state={{ from: location }} replace />;
      if (role === "Admin") return <Navigate to="/dash" state={{ from: location }} replace />;
      if (role === "User")
        if (auth.data.demography) return <Navigate to="/userdash" state={{ from: location }} replace />;
        else return <Navigate to="/demography" state={{ from: location }} replace />;
    } else return <Outlet />;
  }

  let isIncludes = false;
  let userRole = auth?.data?.userRole[0];
  if (userRole) {
    for (let j = 0; j < allowedRoles.length; j++) {
      const allowedrole = allowedRoles[j];
      if (allowedrole === userRole) isIncludes = true;
    }
  }

  if (isIncludes) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};
