import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsHtml } from "../Store";
import ScalePreview from "./ScalePreview";
import { Row } from "react-bootstrap";

export default function IsHtmlSwitch() {
  const dispatch = useDispatch();
  const scale = useSelector((state) => state.scale);

  const handleToggleEditor = (event) => {
    dispatch(setIsHtml(event.target.checked));
  };

  const isHtmlDisabled = () => {
    return scale.ranges?.length;
  };

  // const isCheckboxChecked = () => {
  //   const res = scale?.ranges?.find((range) => {
  //     const htmlPattern = /<([a-z]+)[^>]*>.*<\/\1>/is;
  //     return htmlPattern.test(range.text);
  //   });

  //   return !!res;
  // };

  return (
    <Row>
      <Col className="mb-3" xs="2">
        <Form.Check
          type="switch"
          id="scoring-scale-switch"
          name="isHtml"
          label="HTML düzenleyici"
          disabled={isHtmlDisabled()}
          checked={scale.isHtml}
          onChange={handleToggleEditor}
        />
      </Col>
      <Col xs="10">
        <ScalePreview />
      </Col>
    </Row>
  );
}
