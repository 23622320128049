import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CreateScope from "../CreateScope/CreateScope";
import { closeScopeModal } from "../../Store";
import { updateScope } from "../../Store/Thunk";

export default function ScopeModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShow = useSelector((store) => store.scope.scopeModal.isShow);
  const data = useSelector((store) => store.scope.scopeModal.data);

  const { state } = useLocation();
  const test = state;

  const close = () => {
    dispatch(closeScopeModal());
  };

  const UpdateScope = (scope) => {
    dispatch(updateScope({ scope, testId: test.id }));
    dispatch(closeScopeModal());
  };

  return (
    <Modal fullscreen={true} show={isShow} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title>{t("updateSize")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mt-5">
          <CreateScope UpdateScope={UpdateScope} test={test} uiString={"Boyut"} data={data} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
