import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function ScaleEntity({ scale }) {
  const HandleIsHtml = (text) => {
    if (scale.isHtml)
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    else return text;
  };
  const HandleRanges = (ranges) => {
    return ranges.map((range, i) => (
      <Col key={Math.random().toString(16).slice(2)}>
        <Card>
          <Card.Body>
            <h5>
              {range.start} - {range.end}
            </h5>
            <Row>
              <Col>{HandleIsHtml(range.text)} </Col>
              {scale.isDual && <Col> {HandleIsHtml(range.rightText)}</Col>}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    ));
  };
  return (
    <div>
      <Row>{HandleRanges(scale.ranges)}</Row>
    </div>
  );
}
