import React from "react";
import ScalePage from "./ScalePage";

export default function ScalesSection(props) {
  let HandleScalesWithHeigt = () => {
    let MainScopeHeight = props.data.scales.scope[0]?.subScopes?.length > 0 ? 42 : 50;
    let heightOfOne = (props.data.scales.scope[0]?.subScopes?.length + 1) * MainScopeHeight;
    let numOfScaleElementInPage = 1;
    for (let i = 1; i < 10; i++) {
      if (i * heightOfOne > 330) {
        numOfScaleElementInPage = i - 1;
        break;
      }
    }
    let numOfPage = Math.ceil(props.data.scales.scope.length / numOfScaleElementInPage);
    let scopesPerPage = [];
    for (let j = 0; j < numOfPage; j++) {
      let groupedScopes = [];
      for (let k = 0; k < numOfScaleElementInPage; k++) {
        let scope = props.data.scales.scope[j * numOfScaleElementInPage + k];
        scope && groupedScopes.push(scope);
      }
      scopesPerPage.push(groupedScopes);
    }

    return scopesPerPage.map((page, i) => {
      props.HandleScalePagesIds("scales" + props.id + i);
      return (
        <ScalePage
          scopes={page}
          key={i + "ScalePage"}
          id={"scales" + props.id + i}
          candidateName={props.candidateName}
        />
      );
    });
  };

  return HandleScalesWithHeigt();
}
