import React, { useEffect } from "react";

import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function LandingPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.prevLocation.includes("wrapup")) {
      return navigate("/login", {
        state: { wrapUpId: props.prevLocation.split("wrapup/").pop() },
      });
    }
  });

  return (
    <Container>
      <h1 style={{ textAlign: "center", marginTop: "150px" }}>{t("LandingPageMessage")}</h1>

      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
        }}
        src="https://media.tenor.com/kSiC-0wGr4kAAAAd/monkey-technology.gif"
      />
    </Container>
  );
}
