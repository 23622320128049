export default class EntityListAPI {
  api = {
    getTests: "test/GetTests",
    updateTest: "test/UpdateTest",
    deleteTest: "test/DeleteTest",
    getApplicationBackup: "test/GetApplicationBackup",
    upsertLeanParameters: "test/UpsertLeanParameters",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getTests(data, token) {
    return await this.get(this.api.getTests, data, token);
  }

  async updateTest(data, token) {
    return await this.post(this.api.updateTest, data, token);
  }

  async deleteTest(data, token) {
    return await this.get(this.api.deleteTest, data, token);
  }

  async getApplicationBackup(data, token) {
    return await this.get(this.api.getApplicationBackup, data, token);
  }

  async upsertLeanParameters(data, token) {
    return this.post(this.api.upsertLeanParameters, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    this.cache.remove("tests");

    await this.httpsClient.post(url, data, token);
  }
}
