import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

import UseAuthHelper from "../../../Helpers/Auth/UseAuthHelper";
import DashButton from "./DashButton";

export function SuperAdminDash(props) {
  const { auth } = UseAuthHelper();

  const user = auth?.data;

  return (
    <div style={{ padding: "50px 30px" }}>
      <Card body className="mb-3" style={{ background: "#2c3034" }}>
        <h1 style={{ color: "white" }}>Hoşgeldin {user.userName}</h1>
        <br />
        <Stack gap={5}>
          <Row className="justify-content-md-center">
            <DashButton navigate="/inventory" buttonText="Envanter Oluştur" />
            <DashButton navigate="/test" buttonText="Test Oluştur" />
          </Row>
          <Row>
            <DashButton navigate="/load" buttonText="Kredi Yükle" />
            <DashButton navigate="/ApplicationResults/" buttonText="Envanter Sonuçları" />
          </Row>
        </Stack>
      </Card>
    </div>
  );
}
