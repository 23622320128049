export default class SurveyAPI {
  api = {
    getSurveys: "survey/getSurveys",
    createSurvey: "survey/createsurvey",
    deleteSurvey: "survey/deleteSurvey",
    updateSurvey: "survey/updatesurvey",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getAssesments(data, token) {
    return this.get(this.api.getSurveys, data, token);
  }

  async createSurvey(data, token) {
    return this.post(this.api.createSurvey, data, token);
  }

  async deleteSurvey(data, token) {
    return this.get(this.api.deleteSurvey, data, token);
  }

  async updateSurvey(data, token) {
    return this.post(this.api.updateSurvey, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);

    return response;
  }

  async post(url, data, token) {
    this.cache.remove("tests");

    await this.httpsClient.post(url, data, token);
  }
}
