import React from "react";
import { Col, Row, ProgressBar } from "react-bootstrap";
import { NormalizeScore } from "../../WrapUpNew";

export default function Detail_LoadingBar({ item, isSub }) {
  return (
    <div>
      <h6 className="detail-section-scope-name-header-pdf">
        {item.name}
        <span className="main-scope-score-header-pdf-detail">{item.normScore}</span>
      </h6>
      <div className="scope-detail-scope-container">
        <div
          style={{
            paddingTop: "12px",
          }}
        >
          <ProgressBar
            variant={isSub ? "sub-scope" : "main-scope"}
            now={item.normScore}
            label={`${item.normScore}%`}
            style={{ height: "20px" }}
          />
        </div>
        <Row>
          <Col xs="5">
            <div className="scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
          </Col>
          <Col xs={{ offset: 2, span: 5 }}>
            <div className="scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
          </Col>
        </Row>
        {item.generalInformationText?.length > 2 && (
          <div className="scale-detail-text" dangerouslySetInnerHTML={{ __html: item.generalInformationText }} />
        )}
      </div>
    </div>
  );
}
