import React from "react";
import ScalePercentage from "./ScalePercentage";

export default function SummarySection(props) {
  return (
    <div
      id={"summary" + props.id}
      style={{
        padding: "5mm",
        height: "600mm",
        margin: "0mm",
        width: "421mm",
        zIndex: "100",
        position: "relative",
        background: "#F7F7F4",
      }}
    >
      <h3 className="summary-report-pdf-header">
        Özet Rapor
        <span style={{ position: "absolute", right: "30px", top: "10px" }}>{props.candidateName}</span>
      </h3>
      <div className="summary-report-pdf-scope-container">
        {props.data.scales.scope.map((scope, i) => {
          return <ScalePercentage key={"sp" + i} scope={scope} />;
        })}
      </div>

      {props.data.scales.scope[0]?.subScopes?.length > 0 &&
        props.data.scales.scope.map((scope, i) => {
          return (
            <div key={scope.name + i}>
              <h6 className="summary-report-pdf-subscope-header">
                {scope.name}
                <span className="main-scope-score-header-pdf">{scope.score.calculatedScore}</span>
              </h6>
              <div className="summary-report-pdf-scope-container">
                {scope.subScopes.map((subScope, i) => {
                  return <ScalePercentage key={"ssp" + i} scope={subScope} isSub={true} />;
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}
