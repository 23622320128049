import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../Helpers/ApiHelper";

export default function InfoSection({ imageName }) {
  let style = {
    top: "1mm",
    margin: 0,
    padding: 0,
  };

  let background1 = {
    position: "relative",
    height: "296mm",
    left: 0,
    top: 0,
    width: "210mm",
    zIndex: "-100",
  };

  return (
    <div style={style} id="info">
      <Image
        style={background1}
        className="pdf-background"
        crossOrigin="anonymous"
        src={ApiHelper.baseURL + "wwwroot/info/" + imageName}
      />
    </div>
  );
}
