import React from "react";
import { Col, Row } from "react-bootstrap";

export default function DualText({ item }) {
  const isSub = item.subScopeId ? true : false;

  let HandleIsHit = (range) => {
    if ((item.score > range.start && item.score <= range.end) || (range.start === 0 && item.score === 0))
      return (
        <Row className="mb-3">
          <Col style={{ border: "1px solid red" }} xs="6">
            {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.text}
          </Col>
          <Col style={{ border: "1px solid red" }} xs="6">
            {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.rightText}
          </Col>
        </Row>
      );
  };

  return item.scale.ranges.map((range, i) => {
    return HandleIsHit(range);
  });
}
