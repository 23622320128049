import React from "react";
import { Col, Row, ProgressBar } from "react-bootstrap";

export default function Detail_LoadingBar({ item, isSub }) {
  return (
    <div>
      <h6 className="_detail-section-scope-name-header-pdf">
        {item.name}
        <span className="_main-scope-score-header-pdf-detail">{item.score}</span>
      </h6>
      <div className="_scope-detail-scope-container">
        <div
          style={{
            paddingTop: "12px",
          }}
        >
          <ProgressBar variant={isSub ? "sub-scope" : "main-scope"} now={item.score} label={`${item.score}%`} />
        </div>
        <Row>
          <Col xs="5">
            <div className="_scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
          </Col>
          <Col xs={{ offset: 2, span: 5 }}>
            <div className="_scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
          </Col>
        </Row>
        {item.generalInformationText?.length > 2 && (
          <div className="_scale-detail-text" dangerouslySetInnerHTML={{ __html: item.generalInformationText }} />
        )}
      </div>
    </div>
  );
}
