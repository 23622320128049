import { useMemo, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

const usePagination = (totalItems, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageCount = useMemo(() => Math.ceil(totalItems.length / itemsPerPage), [totalItems.length, itemsPerPage]);

  const currentItems = useMemo(() => {
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
    return totalItems.slice(indexOfFirstItem, indexOfFirstItem + itemsPerPage);
  }, [currentPage, itemsPerPage, totalItems]);

  const paginationItems = useMemo(() => {
    const items = [];
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(startPage + 4, pageCount);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
          {number}
        </Pagination.Item>
      );
    }

    return items;
  }, [currentPage, pageCount]);

  return { setCurrentPage, currentItems, paginationItems, currentPage, pageCount };
};

export default usePagination;
