import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "../../App/AppMenu/IconSvg";
import { Button } from "react-bootstrap";

export default function BreadCrumb(props) {
  const navigate = useNavigate();

  return (
    <Row className="breadcrumb-container mb-3">
      <Col xs={4} md={2} onClick={() => navigate(props.backUrl, props.backState)} style={{ margin: "auto" }}>
        <Button size="sm" variant="light">
          <IconSvg icon="back" /> {props.backPageName}
        </Button>
      </Col>
      <Col xs={8} md={10} className="breadcrumb-page-name">
        {props.pageName}
      </Col>
    </Row>
  );
}
