import React from "react";
import { ScopeScale } from "./ScopeScale";

export default function ScalePage(props) {
  return (
    <div
      style={{
        height: "500mm",
        margin: "0mm",
        padding: "0mm",
        width: "350mm",
        zIndex: "100",
        position: "relative",
        background: "#f7f7f4",
      }}
      id={props.id}
    >
      {props.scopes.map((scope, i) => (
        <ScopeScale key={i} scope={scope} candidateName={props.candidateName} />
      ))}
    </div>
  );
}
