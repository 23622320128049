import { createSlice } from "@reduxjs/toolkit";
import { createMonoTextuary, getMonoTextuaries, deleteMonoTextuary, updateMonoTextuary } from "./Thunk";

export const basiGraphSummary = createSlice({
  name: "monoTextuary",
  initialState: {
    scopeId: 0,
    id: 0,
    isSub: false,
    isLoading: false,
    monoTextuaryEntities: [],
  },
  reducers: {
    updateMonoTextuaryState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMonoTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMonoTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.monoTextuaryEntities = action.payload;
    });
    builder.addCase(getMonoTextuaries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getMonoTextuaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.monoTextuaryEntities = action.payload;
    });
    builder.addCase(deleteMonoTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMonoTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.monoTextuaryEntities = action.payload;
    });
    builder.addCase(updateMonoTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMonoTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.id = 0;
    });
  },
});

export const { updateMonoTextuaryState } = basiGraphSummary.actions;

export default basiGraphSummary.reducer;
