import React, { useEffect } from "react";
import { CandidateInfo } from "../../Application/AnsweringApplication/SharedInfoPages/CandidateInfo";
import ApiHelper from "../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../Helpers/Auth/UseAuthHelper";
import { useNavigate } from "react-router-dom";

export function CandidateDemography() {
  const { auth, setAuth } = UseAuthHelper();
  const user = auth?.data;

  const navigate = useNavigate();

  useEffect(() => {
    if (user.demography) navigate("/userdash");
    document.getElementById("login")?.remove();
  }, []);

  const GetDemography = (demography) => {
    demography.userId = user.userId;
    ApiHelper.postAsync(
      "user/createdemography",
      demography,
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          user.demography = result.data.data;
          localStorage.setItem("cpdata", JSON.stringify(user));
          setAuth({ data: user });
          navigate("/userdash");
        } else {
        }
      },
      user.token
    );
  };

  return (
    <div>
      <CandidateInfo finish={GetDemography} email={user?.email} />
    </div>
  );
}
