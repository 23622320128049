export const BasicGraphSummaryApiUrls = {
  createBasicGraphSummary: "reportitem/CreateBasicGraphSummary",
  updateBasicGraphSummary: "reportitem/UpdateBasicGraphSummary",
  deleteBasicGraphSummary: "reportitem/DeleteBasicGraphSummary",
  getBasicGraphSummaries: "reportitem/GetBasicGraphSummaries",
};

export default class BasicGraphSummaryAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async createBasicGraphSummary(data, token) {
    return this.post(BasicGraphSummaryApiUrls.createBasicGraphSummary, data, token);
  }

  async updateBasicGraphSummary(data, token) {
    return this.post(BasicGraphSummaryApiUrls.updateBasicGraphSummary, data, token);
  }

  async getBasicGraphSummaries(data, token) {
    return await this.get(BasicGraphSummaryApiUrls.getBasicGraphSummaries, data, token);
  }

  async deleteBasicGraphSummary(data, token) {
    return await this.get(BasicGraphSummaryApiUrls.deleteBasicGraphSummary, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
