import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_1({ item, isSub }) {
  let HandleIsHit = (range) => {
    if ((item.score > range.start && item.score <= range.end) || (range.start === 0 && item.score === 0)) return true;
    else return false;
  };

  return (
    //item.name = Hayal Gücü, item.scopeName = Açıklık
    <div style={{ height: item.height, width: item.width }}>
      <Row className={"_detail-section-sub-scope-name-header-pdf"}>{isSub && item.scopeName}</Row>
      <Row
        xs={isSub ? "auto" : 12}
        className={
          isSub ? "_detail-section-sub-scope-name-header-pdf-new" : "_detail-section-scope-name-header-pdf-new"
        }
      >
        <Col className={"align-content-center text-end px-0"}>{item.name}</Col>
        <Col className={"px-0"}>
          <div
            className={(!isSub ? "_main-scope-score-header-pdf-detail-new" : "_sub-scope-score-pdf") + " _flex-center"}
          >
            {item.score}
          </div>
        </Col>
      </Row>

      <div className="_scope-detail-scope-container-new">
        <Row>
          {item.scale.ranges.map((range, i) => {
            let isHit = HandleIsHit(range);
            return (
              <Col
                key={i + "-" + range.id + "scopeScale"}
                className={
                  isHit
                    ? isSub
                      ? "_scale-style-pdf-new _hit-sub-scale-new _flex-center"
                      : "_scale-style-pdf-new _hit-scale-new _flex-center"
                    : "_scale-style-pdf-new _flex-center"
                }
              >
                {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.text}
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xs="5" className={"px-1"}>
            <div className="_scale-detail-text-pdf-new" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
          </Col>
          <Col xs={{ offset: 2, span: 5 }} className={"px-1"}>
            <div className="_scale-detail-text-pdf-new" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
          </Col>
        </Row>
        {item.generalInformationText?.length > 2 && (
          <div
            className="_scale-detail-text-new mb-2"
            dangerouslySetInnerHTML={{ __html: item.generalInformationText }}
          />
        )}
      </div>
    </div>
  );
}
