import React from "react";
import ReportItemEntityBreadCrumb from "./ReportItemEntityBreadCrumb";
import { Accordion } from "react-bootstrap";
import ScaleEntity from "./ScaleEntity";

export default function MonoTextuaryEntity({ entity }) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <ReportItemEntityBreadCrumb entity={entity} itemName="Tek Metin" />
        </Accordion.Header>
        <Accordion.Body>
          <ScaleEntity scale={entity?.scale} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
