import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "animate.css";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

export function QuestionImage(props) {
  const [isMediaRunning, setIsMediaRunning] = useState(false);
  const [order, setOrder] = useState(null);
  const [startTime, setStartTime] = useState(null);

  let mediaWidth = window.innerWidth <= 500 ? "450" : "1000";
  let mediaHeight = window.innerWidth <= 500 ? "189" : "420";

  useEffect(() => {
    window.innerWidth > 500 && EnterAnimation();
    if (order === props.q.order) return;
    if (!order) {
      setOrder(props.q.order);
      setStartTime(props.startTime);
    } else if (props.q.order + 1 === order) {
      let timer = Math.ceil((new Date() - startTime) / 1000);
      props.SetUnAnsweredAnswerTime(props.q.order, timer);
      setOrder(props.q.order);
      setStartTime(props.startTime);
    } else if (props.q.order - 1 === order) {
      setOrder(props.q.order);
      setStartTime(props.startTime);
    }
  });

  let EnterAnimation = () => {
    document.getElementById("question-text").classList.remove("animate__backOutLeft");
    document.getElementById("question-text").classList.add("animate__backInRight");
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  let ExitAnimation = () => {
    document.getElementById("question-text").classList.remove("animate__backInRight");
    document.getElementById("question-text").classList.add("animate__backOutLeft");
  };

  let HandleAnswering = async (a) => {
    window.innerWidth > 500 && ExitAnimation();
    document.getElementById(a).classList.add("animate__flash");
    await timeout(400);
    document.getElementById(a).classList.remove("animate__flash");
    let timer = Math.ceil((new Date() - new Date(props.startTime)) / 1000);
    props.SetAnswer(props.q.order, a, props.n + 1, null, timer);
  };

  let HandleAnswerButtonClass = (no) => {
    if (props.q.answer && props.q.answer === no)
      return "answer-btn-question animate__animated animate__faster selected-answer-btn";
    return "answer-btn-question animate__animated animate__faster";
  };

  let HandleStartMedia = () => {
    if (props.q.mediaClickCount >= 2 || isMediaRunning) return;
    const media = document.getElementById("media");
    media.onended = function () {
      props.setIsMediaRunning(false);
      setIsMediaRunning(false);
    };
    media.play();
    if (!props.q.mediaClickCount) props.SetMediaClick(props.q.order, 1);
    else if (props.q.mediaClickCount === 1) props.SetMediaClick(props.q.order, 2);
    props.setIsMediaRunning(true);
    setIsMediaRunning(true);
  };

  const HandleQuestionImageFile = (fileName) => {
    if (fileName.endsWith(".mp3"))
      return (
        <>
          <div className="flex-center">
            <IconSvg icon="speaker" />
            <audio id="media" src={ApiHelper.baseURL + "wwwroot/question/" + fileName}>
              <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="audio/mpeg" />
            </audio>
          </div>
          <Button
            variant="primary"
            size="sm"
            disabled={isMediaRunning || props.q.mediaClickCount > 1}
            onClick={HandleStartMedia}
          >
            Başlat
          </Button>
          <span className="media-warning">
            Kalan Hak &nbsp;
            {!props.q.mediaClickCount ? 2 : 2 - props.q.mediaClickCount}
          </span>
        </>
      );
    else if (fileName.endsWith(".webm"))
      return (
        <div>
          <div>
            <video
              id="media"
              width={mediaWidth}
              height={mediaHeight}
              src={ApiHelper.baseURL + "wwwroot/question/" + fileName}
            >
              <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/webm" />
            </video>
          </div>

          <Button
            variant="primary"
            size="sm"
            disabled={isMediaRunning || props.q.mediaClickCount > 1}
            onClick={HandleStartMedia}
          >
            Başlat
          </Button>
          <span className="media-warning">
            Kalan Hak &nbsp;
            {!props.q.mediaClickCount ? 2 : 2 - props.q.mediaClickCount}
          </span>
        </div>
      );
    else if (fileName.endsWith(".mp4"))
      return (
        <div>
          <div>
            <video
              id="media"
              width={mediaWidth}
              height={mediaHeight}
              src={ApiHelper.baseURL + "wwwroot/question/" + fileName}
            >
              <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/mp4" />
            </video>
          </div>
          <Button
            variant="primary"
            size="sm"
            disabled={isMediaRunning || props.q.mediaClickCount > 1}
            onClick={HandleStartMedia}
          >
            Başlat
          </Button>
          <span className="media-warning">
            Kalan Hak &nbsp;
            {!props.q.mediaClickCount ? 2 : 2 - props.q.mediaClickCount}
          </span>
        </div>
      );

    return <Image src={ApiHelper.baseURL + "wwwroot/question/" + fileName} width={mediaWidth} height={mediaHeight} />;
  };
  return (
    <>
      <div className="question-image-question-container animate__animated animate__fadeInDown animate__faster">
        <h2 className="likert-image-question-text animate__animated  animate__faster" id="question-text">
          {props?.q?.text ? props.q.text : HandleQuestionImageFile(props.q.imagePath)}
        </h2>
      </div>

      <Container style={{ marginTop: "30px" }} className="animate__animated  animate__fadeInDown animate__faster">
        <Row className="justify-content-center" style={{ margin: "5px", width: "100%" }}>
          {props.q &&
            props.q.options.map((a, i) => {
              return (
                <Col
                  key={i}
                  xs="12"
                  md="4"
                  lg="2"
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    id={a.id}
                    className={HandleAnswerButtonClass(a.id)}
                    onClick={() => HandleAnswering(a.id)}
                    style={{
                      height: "200px",
                      width: "200px ",
                    }}
                    disabled={isMediaRunning}
                  >
                    {a.option ? (
                      a.option
                    ) : (
                      <Image
                        src={ApiHelper.baseURL + "wwwroot/question/" + a.path}
                        width="170"
                        height="170"
                        style={{ borderRadius: "10px" }}
                      />
                    )}
                  </button>
                </Col>
              );
            })}
        </Row>
      </Container>
    </>
  );
}
