import React from "react";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import BreadCrumb from "../../../../../Components/Dashboard/BreadCrumb";

export default function ReportItemBreadCrumb({ state, pageName }) {
  const { t } = useTranslation();
  return (
    <>
      <BreadCrumb
        pageName={pageName}
        backPageName={state.subScope ? t("subScopes") : t("scopes")}
        backUrl={state.subScope ? "/subScope" : "/scope"}
        backState={{
          state: state.subScope ? { scope: state.scope, application: state.application } : state.application,
        }}
      />
      <Card body className="mb-3">
        <Row>
          <Col>
            <h6>Envanter Adı : {state?.application?.name}</h6>
          </Col>
          <Col>
            <h6>Boyut Adı : {state.scope.name}</h6>
          </Col>
          {state.subScope && (
            <Col>
              <h6>Alt Boyut Adı : {state.subScope.name}</h6>
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
}
