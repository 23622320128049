import B2CRowDTO from "../DTO/B2CRowDTO";
import B2BRowDTO from "../DTO/B2BRowDTO";

const RESULT_TYPES = {
  B2B: "B2B",
  B2C: "B2C",
};

function parseTicketResults(ticket, resultType) {
  const answers =
    resultType === RESULT_TYPES.B2B ? ticket.ticketResult.questionAnswersJson : ticket.questionAnswersJson;

  return JSON.parse(answers);
}

function getRowObject(ticket, resultType) {
  return resultType === RESULT_TYPES.B2B ? new B2BRowDTO(ticket) : new B2CRowDTO(ticket);
}

function addQuestionDetails(row, question, index) {
  const answerOrder = question.options.findIndex((option) => option.id === question.answer) + 1;
  row[`soru${index + 1}`] = answerOrder.toString();
  row[`süre${index + 1}`] = question.timer;
}

export default function refactorTestRow(ticket, isB2b) {
  const resultType = isB2b ? RESULT_TYPES.B2B : RESULT_TYPES.B2C;
  const parsedResults = parseTicketResults(ticket, resultType);
  const rowObject = getRowObject(ticket, resultType);

  parsedResults.forEach((question, index) => {
    addQuestionDetails(rowObject, question, index);
  });

  return rowObject;
}
