import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Select from "react-select";

import { createAssessment, getAssessments, updateAssessment } from "../Store/Thunk";
import { Overlay } from "../../../../Helpers/Overlay";
import AssessmentList from "./AssessmentList";
import { UpdateAssessmentState, UpdateIsB2b, UpdateName, UpdatePrice, UpdateSelectedApplications } from "../Store";
import ScrollableCard from "../../../../Helpers/ScrollableCard";

export default function Assessment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.assessment.isLoading);
  const name = useSelector((state) => state.assessment.name);
  const price = useSelector((state) => state.assessment.price);
  const isB2b = useSelector((state) => state.assessment.isB2b);
  const assessmentId = useSelector((state) => state.assessment.id);
  const mappedApplications = useSelector((state) => state.assessment.mappedApplications);

  const isUpdateOperation = useSelector((state) => state.assessment.isUpdateOperation);

  const selectedApplications = useSelector((state) => state.assessment.selectedApplications);

  useEffect(() => {
    dispatch(getAssessments());
  }, []);

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    if (isUpdateOperation)
      dispatch(
        updateAssessment({
          name: formData.get("name"),
          isB2b: isB2b,
          testIds: selectedApplications.filter((x) => x.label.includes("(T)")).map((y) => y.id),
          surveyIds: selectedApplications.filter((x) => x.label.includes("(A)")).map((y) => y.id),
          invIds: selectedApplications.filter((x) => x.label.includes("(E)")).map((y) => y.id),
          id: assessmentId,
          price,
        }),
      );
    else
      dispatch(
        createAssessment({
          name: formData.get("name"),
          isB2b: isB2b,
          price,
          testIds: selectedApplications.filter((x) => x.label.includes("(T)")).map((y) => y.id),
          surveyIds: selectedApplications.filter((x) => x.label.includes("(A)")).map((y) => y.id),
          invIds: selectedApplications.filter((x) => x.label.includes("(E)")).map((y) => y.id),
        }),
      );

    ClearInputs();

    event.currentTarget.reset();
  };

  const ClearInputs = () => {
    dispatch(
      UpdateAssessmentState({
        name: "",
        isB2b: false,
        isUpdateOperation: false,
        id: "",
        selectedApplications: [],
      }),
    );
  };

  const HandleApplicationSelect = (val) => {
    dispatch(UpdateSelectedApplications(val));
  };

  return (
    <div className={"sadmin-page-container"}>
      {isLoading && <Overlay />}
      <Card body className="mb-3">
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs="12" md="10">
              <Row>
                <Col xs={12} md={4}>
                  <h6>{isUpdateOperation ? assessmentId : t("assessmentName")}</h6>
                  <InputGroup className="mb-3">
                    <Form.Control
                      required
                      name="name"
                      placeholder={t("enterAssessmentName")}
                      value={name}
                      onChange={(e) => dispatch(UpdateName(e.target.value))}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} md={4}>
                  <h6>{t("applications")}</h6>
                  <Select
                    value={selectedApplications}
                    options={mappedApplications}
                    placeholder={t("uygulamaları seçiniz")}
                    onChange={HandleApplicationSelect}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </Col>
                <Col xs={12} md={2}>
                  <h6>{t("price")}</h6>
                  <InputGroup className="mb-3">
                    <Form.Control
                      required
                      name="name"
                      type="number"
                      placeholder={t("enterPrice")}
                      value={price}
                      onChange={(e) => dispatch(UpdatePrice(e.target.value))}
                    />
                  </InputGroup>
                </Col>
                <Col xs="12" md="2" className="flex-center">
                  <Form.Check
                    type="switch"
                    label={isB2b ? "B2b" : "B2c"}
                    checked={isB2b}
                    name="isB2b"
                    onChange={(e) => dispatch(UpdateIsB2b(e.target.checked))}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} style={{ marginBottom: "0px !important" }}>
              <Row style={{ height: !isUpdateOperation && "100%", margin: 0 }}>
                <Button
                  className="mb-1"
                  type="submit"
                  style={{ width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={!name}
                >
                  {isUpdateOperation ? t("update") : t("create")}
                </Button>
              </Row>
              {isUpdateOperation && (
                <Row style={{ margin: 0 }}>
                  <Button style={{ width: "100%", height: "100%" }} variant="secondary" onClick={ClearInputs}>
                    {t("decline")}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentAssessments")}</h5>
      <ScrollableCard>
        <AssessmentList />
      </ScrollableCard>
    </div>
  );
}
