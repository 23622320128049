import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCreditsData } from "../Store/CreditThunk";

const useCreditsData = () => {
  const dispatch = useDispatch();
  const creditsData = useSelector((state) => state.credit);

  useEffect(() => {
    dispatch(getCreditsData());
  }, []);

  const adminsList = useMemo(
    () => creditsData.admins?.map((admin) => ({ value: admin.id, label: admin.email })) || [],
    [creditsData.admins]
  );
  const testsList = useMemo(
    () => creditsData.tests?.map((test) => ({ value: test.id, label: test.name })) || [],
    [creditsData.tests]
  );

  return { adminsList, testsList };
};

export default useCreditsData;
