import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Range from "./Range";
import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllScales } from "../../Store";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

export default function RangeList() {
  const dispatch = useDispatch();
  const ranges = useSelector((state) => state.scale.ranges);

  const deleteScales = () => {
    dispatch(deleteAllScales());
  };

  return (
    <Col xs="12" md="7">
      <Row xs="auto">
        {ranges?.map((range, index) => (
          <Range range={range} key={index} />
        ))}
        <Col>
          {ranges?.length > 0 && (
            <Button onClick={deleteScales} variant="danger">
              <IconSvg icon="trash-can" fill="#fff" />
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
}
