import React from "react";
import usePagination from "../../Hooks/usePagination";
import Pagination from "react-bootstrap/Pagination";

export default function UIPagination({ items, itemsPerPage }) {
  const { paginationItems, setCurrentPage, currentPage, pageCount } = usePagination(items || [], itemsPerPage);

  return (
    <Pagination>
      <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
      {currentPage > 3 && <Pagination.Ellipsis />}
      {paginationItems}
      {currentPage < pageCount - 2 && <Pagination.Ellipsis />}
      <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === pageCount} />
      <Pagination.Last onClick={() => setCurrentPage(pageCount)} disabled={currentPage === pageCount} />
    </Pagination>
  );
}
