import { createSlice } from "@reduxjs/toolkit";

export const reportItemBase = createSlice({
  name: "reportItemBase",
  initialState: {
    designType: null,
    name: "",
  },
  reducers: {
    updateReportItemBase(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateReportItemBase } = reportItemBase.actions;

export default reportItemBase.reducer;
