import { useEffect, useState } from "react";

import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { Col, Row } from "react-bootstrap";
import { Applications } from "./Applications/Applications";
import { Statistics } from "./Statistics";
import { Notifications } from "./Notifications";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Overlay } from "../../../../Helpers/Overlay";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);
export function AdminDash() {
  const { t } = useTranslation();
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const [showOverlay, setShowOverlay] = useState(true);

  const [adminData, setAdminData] = useState({
    credits: [],
    statistics: [],
    notifications: [],
  });

  useEffect(() => {
    RefreshAdminDash();
  }, []);

  const RefreshAdminDash = () => {
    setShowOverlay(true);
    ApiHelper.getAsync(
      "credit/GetAdminDashData",
      { userId: user.userId },
      function (result) {
        setShowOverlay(false);
        if (result.data.statusCode === 1) {
          setAdminData({
            ...adminData,
            credits: result.data.data?.credits,
            statistics: result.data.data?.statistics,
            notifications: result.data.data?.notifications,
          });
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      user.token
    );
  };

  return (
    <div>
      {showOverlay && <Overlay />}
      <Applications credits={adminData.credits} />
      <Row className="statistics-notifications-container-row">
        <Col xs="12" md="6">
          <h3 className="admin-dash-headings">{t("completedApplications")}</h3>
          <Statistics statistics={adminData.statistics} />
        </Col>
        <Col xs="12" md="6">
          <h3 className="admin-dash-headings">{t("notifications")}</h3>
          <Notifications notifications={adminData.notifications} />
        </Col>
      </Row>
    </div>
  );
}
