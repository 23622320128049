import React from "react";

export default function Rows({ items }) {
  return (
    <>
      {items &&
        items.map((credit) => (
          <tr key={credit.id}>
            <td> {credit.id}</td>
            <td>{credit.labelAdmin}</td>
            <td>{credit.labelTest}</td>
            <td>{credit.creditAmount}</td>
          </tr>
        ))}
    </>
  );
}
