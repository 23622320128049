import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WysiwygEditor from "../../../../UI/WysiwygEditor";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetWysiwyg, addRange, updateRangeState } from "../Store";
import Button from "react-bootstrap/Button";
import RangeList from "./RangeList/RangeList";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import { isValidScale } from "../Utils";

export default function ScaleForm({ onChange }) {
  const dispatch = useDispatch();
  const range = useSelector((state) => state.scale.range);
  const isHtml = useSelector((state) => state.scale.isHtml);
  const ranges = useSelector((state) => state.scale.ranges);
  const isDual = useSelector((state) => state.scale.isDual);

  const add = () => {
    dispatch(
      addRange({
        text: range.text,
        rightText: range.rightText,
        start: parseInt(range.start, 10),
        end: parseInt(range.end, 10),
      })
    );

    dispatch(resetWysiwyg(true));
  };

  const updateRange = (property, value) => {
    dispatch(
      updateRangeState({
        property,
        value,
      })
    );
    dispatch(resetWysiwyg(false));
  };

  const addAllScales = () => {
    onChange && onChange(ranges);
  };

  const isAllowAdd = () => {
    var isValid = isValidScale(ranges, range);
    if (!isValid || !range.text || (isDual && !range.rightText)) return true;
  };

  return (
    <Row>
      <Row className="mb-3">
        <Col xs="6" md="2">
          <InputGroup>
            <InputGroup.Text>start</InputGroup.Text>
            <Form.Control
              name="start"
              required
              max="100"
              min="0"
              type="number"
              value={range.start}
              placeholder="start"
              onChange={(event) => updateRange("start", event.target.value)}
            />
          </InputGroup>
        </Col>
        <Col xs="6" md="2">
          <InputGroup>
            <InputGroup.Text>end</InputGroup.Text>
            <Form.Control
              name="end"
              required
              max="100"
              min={range.start}
              type="number"
              // disabled={parseInt(range.start, 10) === 100}
              value={range.end}
              placeholder="end"
              onChange={(event) => updateRange("end", event.target.value)}
            />
          </InputGroup>
        </Col>
        <Col xs={1}>
          <Button style={{ width: "100%" }} disabled={isAllowAdd()} onClick={add}>
            Ekle <IconSvg icon="plus" />
          </Button>
        </Col>
        <RangeList />
      </Row>
      <Row>
        <Col>
          {isHtml ? (
            <Row>
              <Col>
                <WysiwygEditor reset={resetWysiwyg} onChange={(html) => updateRange("text", html)} />
              </Col>
              {isDual && (
                <Col>
                  <WysiwygEditor reset={resetWysiwyg} onChange={(html) => updateRange("rightText", html)} />
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Text></InputGroup.Text>
                  <Form.Control
                    name="description"
                    value={range.text}
                    placeholder="description"
                    onChange={(event) => updateRange("text", event.target.value)}
                  />
                </InputGroup>
              </Col>
              {isDual && (
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Text></InputGroup.Text>
                    <Form.Control
                      name="description"
                      value={range.rightText}
                      placeholder="description"
                      onChange={(event) => updateRange("rightText", event.target.value)}
                    />
                  </InputGroup>
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Row>
  );
}
