import React from "react";
import { Col, Row } from "react-bootstrap";

export default function DualSpace({ item }) {
  return (
    <Row className="mb-3">
      <Col>
        <div dangerouslySetInnerHTML={{ __html: item.leftSpaceHtml }} />
      </Col>
      <Col>
        <div dangerouslySetInnerHTML={{ __html: item.rightSpaceHtml }} />
      </Col>
    </Row>
  );
}
