import React from "react";
import { Col, Row } from "react-bootstrap";

export function Notifications(props) {
  let HandleDateString = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString().substr(-2)}`;
  };

  let GetTime = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    const hours = date.getHours();
    const hoursPrefix = hours < 10 ? "0" : null;
    const minutes = date.getMinutes();
    const minutesPrefix = minutes < 10 ? "0" : null;
    return `${hoursPrefix + hours}:${minutesPrefix + minutes}`;
  };

  const HandleRowClick = (id) => {
    window.open(window.location.origin + "/#/wrapup/" + id, "_blank");
  };

  return (
    <div className="admin-notification-container">
      {props.notifications?.map((ticket, i) => (
        <Row key={i + "t"} className="notification-row" onClick={() => HandleRowClick(ticket?.ticketResult?.id)}>
          <Col xs="1">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M13.3346 5.96016C13.3277 5.89892 13.3143 5.83858 13.2946 5.78016V5.72016C13.2626 5.65162 13.2198 5.58861 13.168 5.5335L9.16797 1.5335C9.11286 1.48164 9.04985 1.43888 8.9813 1.40683H8.9213L8.70797 1.3335H4.66797C4.13754 1.3335 3.62883 1.54421 3.25376 1.91928C2.87868 2.29436 2.66797 2.80306 2.66797 3.3335V12.6668C2.66797 13.1973 2.87868 13.706 3.25376 14.081C3.62883 14.4561 4.13754 14.6668 4.66797 14.6668H11.3346C11.8651 14.6668 12.3738 14.4561 12.7488 14.081C13.1239 13.706 13.3346 13.1973 13.3346 12.6668V5.96016ZM9.33464 3.60683L11.0613 5.3335H9.33464V3.60683ZM12.0013 12.6668C12.0013 12.8436 11.9311 13.0132 11.806 13.1382C11.681 13.2633 11.5114 13.3335 11.3346 13.3335H4.66797C4.49116 13.3335 4.32159 13.2633 4.19656 13.1382C4.07154 13.0132 4.0013 12.8436 4.0013 12.6668V3.3335C4.0013 3.15668 4.07154 2.98712 4.19656 2.86209C4.32159 2.73707 4.49116 2.66683 4.66797 2.66683H8.0013V6.00016C8.0013 6.17697 8.07154 6.34654 8.19656 6.47157C8.32159 6.59659 8.49116 6.66683 8.66797 6.66683H12.0013V12.6668Z"
                fill="#8A8A8A"
              />
            </svg>
          </Col>
          <Col xs="3" className="notification-name-email">
            {ticket?.test?.name}
          </Col>
          <Col xs="3" className="notification-name-email">
            {ticket?.candidateUser?.email}
          </Col>
          <Col xs="3" className="notification-name-email">
            {ticket?.candidateUser?.demography?.name}
          </Col>
          <Col xs="2" className="notification-complete-time">
            <Row style={{ height: "100%" }}>
              <Col className="flex-center">{HandleDateString(ticket?.ticketResult?.endDate)}</Col>
              <Col className="flex-center">{GetTime(ticket?.ticketResult?.endDate)}</Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
}
