import React from "react";

export default function MonoText({ item }) {
  return item.scale.ranges.map((range, i) => {
    if (range.isHit)
      return item.scale.isHtml ? (
        <>
          <div
            className="mb-3"
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "20px",
            }}
            dangerouslySetInnerHTML={{ __html: range.text }}
          />
        </>
      ) : (
        <>
          <p
            className="mb-3"
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "20px",
            }}
          >
            {range.text}
          </p>
        </>
      );
  });
}
