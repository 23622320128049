import React from "react";
import IsHtmlSwitch from "./IsHtmlSwitch";
import ScaleForm from "./ScaleForm";

export default function ScoringScale() {
  return (
    <>
      <IsHtmlSwitch />
      <ScaleForm />
    </>
  );
}
