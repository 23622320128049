import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

import IconSvg from "../../../../../App/AppMenu/IconSvg";
import { openScopeModal } from "../../Store";
import { deleteScope, getScopes } from "../../Store/Thunk";
import ScrollableCard from "../../../../../Helpers/ScrollableCard";

export default function ScopeList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state } = useLocation();
  const application = state;
  const dispatch = useDispatch();
  const scopes = useSelector((store) => store.scope.scopes);

  useEffect(() => {
    dispatch(getScopes());
  }, []);

  const open = (scope) => {
    dispatch(openScopeModal(scope));
  };

  const remove = (id) => {
    dispatch(deleteScope({ id, testId: application.id, isTest: application.isTest }));
  };

  const openPage = (url, scope, application) => {
    navigate(url, {
      state: {
        scope,
        application,
        isSub: false,
      },
    });
  };

  const isAllowed = (property) => {
    if (!scopes?.length) return false;

    return !!scopes?.find((item) => {
      return item[property]?.length > 0;
    });
  };

  const shortSubScopeNames = (scope) => {
    return scope?.subScopes.map((item, i) => {
      return (
        <span key={i} style={{ padding: "0 10px", fontWeight: "bold", color: "gray" }}>
          {item.name}
        </span>
      );
    });
  };

  const CalculateQuestionNumber = (scope, type) => {
    return scope.questions.filter((item) => item[type]).length;
  };
  return (
    <ScrollableCard>
      {scopes?.map((scope, i) => {
        return (
          <Card key={i} className="mb-3">
            <Card.Body>
              <Row>
                <Col xs="12" md="7">
                  <Row xs="auto">
                    <Col xs="1">
                      <h4>{scope.id}</h4>
                    </Col>
                    <Col xs="4">
                      <h4>{scope.name}</h4>
                    </Col>
                    {isAllowed("subScopes") && <Col className="flex-center">{shortSubScopeNames(scope)}</Col>}
                    {isAllowed("questions") && (
                      <Col xs="4">
                        <Row>
                          <Col>
                            <h6>checkbox {CalculateQuestionNumber(scope, "adjective")}</h6>
                          </Col>
                          <Col>
                            <h6>kutuplu {CalculateQuestionNumber(scope, "polared")}</h6>
                          </Col>
                          <Col>
                            <h6>likert {CalculateQuestionNumber(scope, "likert")}</h6>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {scope.isManipulation && (
                      <Col>
                        <Badge bg="info">{t("manipulation")}</Badge>
                      </Col>
                    )}
                    {scope.isConsistency && (
                      <Col>
                        <Badge bg="warning">{t("consistency")}</Badge>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs="12" md="3">
                  <Row xs="auto">
                    <Col>
                      <Dropdown>
                        <Dropdown.Toggle size="sm" variant="info" id="dropdown-basic">
                          {t("addReportElementButtonLabel")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => openPage("/basicGraphSummary", scope, application)}>
                            {t("basicGraphSummary")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => openPage("/basicGraphDetail", scope, application)}>
                            {t("basicGraphDetail")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => openPage("/monotextuary", scope, application)}>
                            {t("monoTextuary")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => openPage("/dualtextuary", scope, application)}>
                            {t("dualTextuary")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    {!application.isTest && (
                      <Col>
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={() => openPage("/subscope", scope, application)}
                          disabled={isAllowed("questions")}
                        >
                          {t("addSubDimension")}
                        </Button>
                      </Col>
                    )}
                    <Col>
                      {application.isTest ? (
                        <Button size="sm" variant="warning" onClick={() => openPage("/question", scope, application)}>
                          {t("addQuestion")}
                        </Button>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            size="sm"
                            variant="info"
                            id="dropdown-basic"
                            disabled={isAllowed("subScopes")}
                          >
                            {t("createQuestion")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => openPage("/polar", scope, application)}>
                              {t("polar")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => openPage("/likert", scope, application)}>
                              {t("likert")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => openPage("/checkbox", scope, application)}>
                              {t("adjective")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" md="2">
                  <Row xs="auto" style={{ float: "right" }}>
                    <Col>
                      <Button size="sm" variant="success" onClick={() => open(scope)}>
                        {t("edit")}
                      </Button>
                    </Col>
                    <Col>
                      <Button size="sm" variant="danger" onClick={() => remove(scope.id)}>
                        <IconSvg icon="trash-can" fill="#fff" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        );
      })}
    </ScrollableCard>
  );
}
