import React from "react";
import { Row, Col } from "react-bootstrap";

export function TestScopeScaleB2c(props) {
  let score = props.scopeResult.score;
  let HandleIsHit = (scale) => {
    if ((score > scale.start && score <= scale.end) || (scale.start === 0 && score === 0)) return true;
    else return false;
  };
  return (
    <div style={{ border: "1px solid #e3e3e3" }} className="b2c-detail-scope-scale-container test">
      <h5 style={{ width: "100%", textAlign: "center", color: "#5f5f5f" }}>
        <span>{props.scopeResult.scopeName}:</span>
        <span className="">{score}</span>
      </h5>
      <Row>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }}>
          <Row>
            {props.scopeResult.scales.map((scale, i) => {
              let isHit = HandleIsHit(scale);
              return (
                <Col
                  xs={window.innerWidth < 500 && "12"}
                  key={i + "-" + scale.id + "scopeScale"}
                  className={isHit ? "detail-scale hit-sub-scale" : "detail-scale scale-style"}
                >
                  {scale.text}
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
          <h6 className="scale-detail-text">{props.scopeResult.leftScaleText}</h6>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{ offset: 2, span: 5, order: 3 }}>
          <h6 className="scale-detail-text" style={{ textAlign: "end" }}>
            {props.scopeResult.rightScaleText}
          </h6>
        </Col>
      </Row>
      {props.scopeResult.description?.length > 2 && (
        <p style={{ fontSize: "12px" }} className="scale-detail-text">
          Aciklama: {props.scopeResult.description}
        </p>
      )}
    </div>
  );
}
