import React from "react";
import { Card, Row, Col, Accordion, Button } from "react-bootstrap";
import IconSvg from "../../../../../App/AppMenu/IconSvg";
import { useDispatch, useSelector } from "react-redux";
import { updateReportSchemeState } from "../../Store";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../../Helpers/Auth/UseAuthHelper";

export default function ImageSpace({ entity, UpdateScheme }) {
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const dispatch = useDispatch();
  const entityList = useSelector((state) => state.reportScheme.reportItemEntityList);

  const deleteElement = async () => {
    let newList = JSON.parse(JSON.stringify(entityList));
    const res = await DeleteFromDb();

    if (res) {
      newList.splice(entity.order - 1, 1);
      newList.forEach((entity, i) => {
        entity.order = i + 1;
      });
      dispatch(updateReportSchemeState({ reportItemEntityList: newList }));
      UpdateScheme(newList);
    }
  };

  let DeleteFromDb = async () => {
    // setShowOverlay(true);

    return new Promise(function (resolve, reject) {
      ApiHelper.getAsync(
        "report/DeleteImage",
        { fileName: entity.imageName },
        function (result) {
          if (result.data.statusCode == 1) resolve(true);
          else {
            alert("hata olustu sayfa yenile");
            resolve(false);
          }
        },
        user.token
      );
    }).then((val) => {
      return val;
    });
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Row style={{ width: "100%" }}>
            <Col xs="6" md="6">
              <h5> Görsel</h5>
            </Col>
            <Col xs="6" md="4">
              <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                  <h5
                    style={{
                      color: entity.orderedBefore && !entity.edited ? "green" : "red",
                    }}
                  >
                    {entity.order}
                  </h5>
                </Col>
              </Row>
            </Col>
            <Col xs="6" md="2">
              <div className="report-item-delete-button" onClick={deleteElement}>
                <IconSvg icon="trash-can" fill="#fff" />
              </div>
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <img src={ApiHelper.baseURL + "wwwroot/scheme/" + entity.imageName} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
