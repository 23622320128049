import { useSelector } from "react-redux";
import React from "react";

export default function ScalePreview({}) {
  const ranges = useSelector((state) => state.scale.ranges);

  const scaleWidth = 1;
  const scaleStyle = {
    height: "20px",
    position: "absolute",
  };

  return (
    <div
      style={{
        position: "relative",
        height: "20px",
        width: "100%",
      }}
    >
      {[...Array(100)].map((_, index) => {
        const scale = ranges?.find((scale) => index + 1 >= scale.start && index + 1 <= scale.end);

        const component = (
          <div
            key={index}
            style={{
              ...scaleStyle,
              left: `${index * scaleWidth}%`,
              width: `${scaleWidth}%`,
              backgroundColor: scale ? "green" : "lightgray",
            }}
          />
        );

        return component;
      })}
    </div>
  );
}
