import { createSlice } from "@reduxjs/toolkit";
import { createBasicGraphDetail, getBasicGraphDetails, deleteBasicGraphDetail, updateBasicGraphDetail } from "./Thunk";

export const basiGraphDetail = createSlice({
  name: "basicGraphDetail",
  initialState: {
    scopeId: 0,
    id: 0,
    isSub: false,
    isLoading: false,
    basicGraphDetailEntities: [],
    lowSideText: "",
    highSideText: " ",
    generalInformationText: "",
  },
  reducers: {
    updateBasicGraphDetailState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBasicGraphDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createBasicGraphDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphDetailEntities = action.payload;
      state.lowSideText = "";
      state.highSideText = "";
      state.generalInformationText = "";
    });
    builder.addCase(getBasicGraphDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBasicGraphDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphDetailEntities = action.payload;
    });
    builder.addCase(deleteBasicGraphDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBasicGraphDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphDetailEntities = action.payload;
    });
    builder.addCase(updateBasicGraphDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateBasicGraphDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.id = 0;
      state.lowSideText = "";
      state.highSideText = "";
      state.generalInformationText = "";
    });
  },
});

export const { updateBasicGraphDetailState } = basiGraphDetail.actions;

export default basiGraphDetail.reducer;
