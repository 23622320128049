import { createSlice } from "@reduxjs/toolkit";

export const reportItem = createSlice({
  name: "reportItem",
  initialState: {
    scopeId: 0,
    id: 0,
    isSub: false,
    isLoading: false,
    ReportItemEntities: [],
  },
  reducers: {
    updateReportItemState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { updateReportItemState } = reportItem.actions;

export default reportItem.reducer;
