import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import TestAPI from "../API/TestAPI";
import { getTests as entityListGetTests } from "../../EntityList/Store/Thunk";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const testAPI = new TestAPI(apiHelper);

export const getTests = createAsyncThunk("test/GetTests", async (_, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  const data = await testAPI.getTests(_, token);
  return data;
});

export const createApplication = createAsyncThunk("test/CreateApplication", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await testAPI.createApplication(payload, token);

  dispatch(entityListGetTests());

  return;
});
