import { createSlice } from "@reduxjs/toolkit";
import {
  CreateInventory,
  uploadApplicationBackUp,
  GetInventories,
  DeleteInventory,
  UpsertLeanParameters,
  UpdateInventory,
} from "./Thunk";

export const inventory = createSlice({
  name: "inventory",
  initialState: {
    inventories: [],
    isLoading: false,
    selectedAssessments: [],
    name: "",
    inventoryId: 0,
    isUpdateOperation: false,
    leanModalState: {
      inventory: null,
      isVisible: false,
    },
    answersModalState: {
      inventory: null,
      isVisible: false,
    },
  },
  reducers: {
    UpdateName(state, action) {
      state.name = action.payload;
    },
    UpdateAssessment(state, action) {
      state.selectedAssessments = action.payload;
    },
    UpdateLeanModalState(state, action) {
      state.leanModalState.isVisible = action.payload.isVisible;
      state.leanModalState.inventory = action.payload.inventory;
    },
    UpdateAnswersModalState(state, action) {
      state.answersModalState.isVisible = action.payload.isVisible;
      state.answersModalState.inventory = action.payload.inventory;
    },
    HandleUpdateOperation(state, action) {
      state.name = action.payload.name;
      state.selectedAssessments = action.payload.selectedAssessments;
      state.isUpdateOperation = action.payload.isUpdateOperation;
      state.inventoryId = action.payload.inventoryId;
    },
    UpdateLeanParameters(state, action) {
      state.leanModalState.inventory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CreateInventory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(CreateInventory.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(uploadApplicationBackUp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(uploadApplicationBackUp.fulfilled, (state, action) => {
      //todo
      // state.tests = action.payload;
      state.isLoading = false;
    });
    builder.addCase(DeleteInventory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteInventory.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(GetInventories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetInventories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inventories = action.payload.data;
    });

    builder.addCase(UpsertLeanParameters.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(UpsertLeanParameters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.leanModalState.isVisible = false;
      state.leanModalState.inventory = null;
    });

    builder.addCase(UpdateInventory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(UpdateInventory.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  UpdateAssessment,
  UpdateName,
  UpdateAnswersModalState,
  UpdateInfoModalState,
  UpdateLeanModalState,
  HandleUpdateOperation,
  UpdateLeanParameters,
  CloseInfoModal,
} = inventory.actions;

export default inventory.reducer;
