import React from "react";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function DashButton(props) {
  const navigate = useNavigate();
  return (
    <Col>
      <Button
        style={{ height: "100%", width: "100%" }}
        variant="primary"
        size="lg"
        onClick={() => navigate(props.navigate)}
      >
        {props.buttonText}
      </Button>
    </Col>
  );
}
