import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { Overlay } from "../../../../Helpers/Overlay";
import { useTranslation } from "react-i18next";

export function ResultFilter(props) {
  const { t } = useTranslation();

  Date.prototype.toDateInputValue = function () {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const [postData, setPostData] = useState({
    candidateName: "",
    email: "",
    selectedApps: [],
    ticketId: "",
    start: new Date().toDateInputValue(),
    end: new Date().toDateInputValue(),
    isFinished: false,
  });

  const [isDisabled, setIsDisabled] = useState({
    candidateName: false,
    email: false,
    selectedApps: false,
    ticketId: false,
    start: false,
    end: false,
    isFinished: false,
  });

  const [isSearched, setIsSearched] = useState(false);

  const [apps, setApps] = useState([]);

  useEffect(() => {
    ApiHelper.getAsync(
      "test/GetAppsOfAdmin",
      { userId: props.user.userId },
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          setApps(
            result.data.data.tests.map((x) => ({
              label: x.name,
              name: x.name,
              value: x.id,
            }))
          );
        } else {
        }
      },
      props.user.token
    );
  }, []);

  const HandleSearch = () => {
    if (isSearched) return HandleClean();
    setShowOverlay(true);
    let obj = {
      start: new Date(postData.start),
      end: new Date(postData.end),
      appIds: postData.selectedApps.map((x) => parseInt(x.value)),
      isFinished: postData.isFinished,
      userId: props.user.userId,
      candidateName: postData.candidateName,
      email: postData.email,
      ticketId: postData.ticketId ? parseInt(postData.ticketId) : 0,
    };

    ApiHelper.postAsync(
      "ticket/FilterTickets",
      obj,
      function (result) {
        setShowOverlay(false);
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          props.Show(result.data.data);
          setIsSearched(true);
        } else {
        }
      },
      props.user.token
    );
  };

  const HandleClean = () => {
    props.Clean();
    setPostData({
      candidateName: "",
      email: "",
      selectedApps: [],
      ticketId: "",
      start: new Date().toDateInputValue(),
      end: new Date().toDateInputValue(),
      isFinished: false,
    });
    setIsSearched(false);
    setIsDisabled({
      candidateName: false,
      email: false,
      selectedApps: false,
      ticketId: false,
      start: false,
      end: false,
      isFinished: false,
    });
  };

  const CleanDisabled = () => {
    setIsDisabled({
      candidateName: false,
      email: false,
      selectedApps: false,
      ticketId: false,
      start: false,
      end: false,
      isFinished: false,
    });
  };

  const SetTicketId = (val) => {
    setPostData({ ...postData, ticketId: val });
    if (val === "") CleanDisabled();
    else
      setIsDisabled({
        candidateName: true,
        email: true,
        selectedApps: true,
        ticketId: false,
        start: true,
        end: true,
        isFinished: true,
      });
  };

  const SetName = (val) => {
    setPostData({ ...postData, candidateName: val });
    if (val === "") CleanDisabled();
    else
      setIsDisabled({
        candidateName: false,
        email: true,
        selectedApps: true,
        ticketId: true,
        start: true,
        end: true,
        isFinished: true,
      });
  };

  const SetEmail = (val) => {
    setPostData({ ...postData, email: val });
    if (val === "") CleanDisabled();
    else
      setIsDisabled({
        candidateName: true,
        email: false,
        selectedApps: true,
        ticketId: true,
        start: true,
        end: true,
        isFinished: true,
      });
  };

  const SetSelectedApps = (val) => {
    setPostData({ ...postData, selectedApps: val });
    if (val.length === 0) CleanDisabled();
    else
      setIsDisabled({
        candidateName: true,
        email: true,
        ticketId: true,
        selectedApps: false,
        start: false,
        isFinished: false,
        end: false,
      });
  };

  const SetStartDate = (val) => {
    setPostData({ ...postData, start: val });
  };

  const SetEndDate = (val) => {
    setPostData({ ...postData, end: val });
  };

  const SetIsFinished = (val) => {
    setPostData({
      ...postData,
      isFinished: val,
    });
  };

  return (
    <div
      style={{
        margin: "40px 20px 20px 20px",
      }}
    >
      {showOverlay && <Overlay text="Arama yapılıyor..." />}
      <Row className="ticket-result-filter-container">
        <Col xs="12" md="6" className="mb-3">
          <Select
            placeholder="Uygulamalar"
            options={apps}
            isMulti
            closeMenuOnSelect={false}
            value={postData.selectedApps}
            onChange={(val) => SetSelectedApps(val)}
            isDisabled={isSearched || isDisabled.selectedApps}
          />
        </Col>

        <Col xs="12" md="2" className="flex-center mb-3">
          <div className="ticket-result-filter-checkbox-label">{t("start")}:</div>
          <input
            disabled={isSearched || isDisabled.start}
            type="date"
            lang="tr"
            max={postData.end}
            value={postData.start}
            onChange={(e) => SetStartDate(e.target.value)}
          />
        </Col>

        <Col xs="12" md="2" className="flex-center mb-3">
          <div className="ticket-result-filter-checkbox-label">{t("end")}:</div>
          <input
            disabled={isSearched || isDisabled.end}
            type="date"
            lang="tr"
            min={postData.start}
            value={postData.end}
            onChange={(e) => SetEndDate(e.target.value)}
          />
        </Col>

        <Col xs="6" md="2" className="flex-center mb-3">
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Form.Check.Input
              disabled={isSearched || isDisabled.isFinished}
              value={postData.isFinished}
              type="checkbox"
              onChange={(e) => SetIsFinished(e.target.checked)}
            />
            <Form.Check.Label className="ticket-result-filter-checkbox-label">{t("completed")}</Form.Check.Label>
          </div>
        </Col>

        <Col xs="6" md="2">
          <Form.Control
            disabled={isSearched || isDisabled.ticketId}
            placeholder="ID"
            type="number"
            value={postData.ticketId}
            onChange={(e) => SetTicketId(e.target.value)}
          />
        </Col>

        <Col xs="12" md="4">
          <Form.Control
            disabled={isSearched || isDisabled.candidateName}
            placeholder={t("nameSurname")}
            value={postData.candidateName}
            onChange={(e) => SetName(e.target.value)}
          />
        </Col>

        <Col xs="12" md="4">
          <Form.Control
            disabled={isSearched || isDisabled.email}
            placeholder={t("email")}
            type="email"
            value={postData.email}
            onChange={(e) => SetEmail(e.target.value)}
          />
        </Col>

        <Col xs="6" md="2" className="flex-center">
          <Button
            variant="primary"
            style={{ width: "100%" }}
            onClick={HandleSearch}
            disabled={
              postData.candidateName || postData.email || postData.ticketId || postData.selectedApps.length
                ? false
                : true
            }
          >
            {isSearched ? t("clear") : t("search")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
