import React from "react";
import ApiHelper from "../../../../../../Helpers/ApiHelper";

export default function ImageSpace({ item }) {
  return (
    <div className="_flex-center">
      <img style={{ maxWidth: "100%" }} src={ApiHelper.baseURL + "wwwroot/scheme/" + item.imageName} />
    </div>
  );
}
