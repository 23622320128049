export const MonoTextuaryApiUrls = {
  createMonoTextuary: "reportitem/CreateMonoTextuary",
  updateMonoTextuary: "reportitem/UpdateMonoTextuary",
  deleteMonoTextuary: "reportitem/DeleteMonoTextuary",
  getMonoTextuaries: "reportitem/GetMonoTextuaries",
};

export default class MonoTextuaryAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async createMonoTextuary(data, token) {
    return this.post(MonoTextuaryApiUrls.createMonoTextuary, data, token);
  }

  async updateMonoTextuary(data, token) {
    return this.post(MonoTextuaryApiUrls.updateMonoTextuary, data, token);
  }

  async getMonoTextuaries(data, token) {
    return await this.get(MonoTextuaryApiUrls.getMonoTextuaries, data, token);
  }

  async deleteMonoTextuary(data, token) {
    return await this.get(MonoTextuaryApiUrls.deleteMonoTextuary, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
