import React from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import IconSvg from "../../../../../../App/AppMenu/IconSvg";
import { useState } from "react";
import ApiHelper from "../../../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../../../Helpers/Auth/UseAuthHelper";
import { useEffect } from "react";

export default function SelectApplication(props) {
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const [favList] = useState([]);
  const [isFav] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [favourites, setFavourites] = useState([]);

  useEffect(() => {
    RefreshFavourites();
  }, []);

  let HandleSetFav = () => {
    if (props.postData.applications.length < 2) return;
    setShowInputModal(true);
  };

  const HandleSavingFav = (event) => {
    if (event.keyCode === 13) {
      let isExist = favList.filter((fav) => {
        if (fav.map((x) => x.id) === props.postData.applications.map((x) => x.value)) return fav;
      });
      if (!isExist.length) HandlePostNewFav(event.target.value);
    }
  };

  const HandlePostNewFav = (name) => {
    let ids = props.postData.applications.map((x) => x.value);

    ApiHelper.postAsync(
      "credit/CreateFavorite",
      { userId: props.user.userId, applicationIds: ids, name: name },
      function (result) {
        setShowInputModal(false);
        RefreshFavourites();
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
        } else {
        }
      },
      props.user.token
    );
  };

  const HandleFavSelect = (fav) => {
    let credits = props.data.filter((credit) => fav.applicationIds.includes(credit.value));
    props.HandleTestsSelect(credits);
  };

  let RefreshFavourites = () => {
    ApiHelper.getAsync(
      "credit/getfavorites",
      { userId: user.userId },
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          let data = result.data.data;
          HandleSetFavouritesState(data);
        } else {
        }
      },
      user.token
    );
  };

  let HandleSetFavouritesState = (data) => {
    if (data.length > 0) {
      data.forEach((fav) => {
        fav.applicationsJson = JSON.parse(fav.applicationsJson);
        fav.applicationIds = fav.applicationsJson.map((x) => x.Id);
      });
      setFavourites(data);
    } else if (data.length === 0) setFavourites([]);
  };

  let HandleDeleteFav = (id) => {
    ApiHelper.getAsync(
      "credit/deletefavorite",
      { id },
      function (result) {
        RefreshFavourites();
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
        } else {
        }
      },
      user.token
    );
  };

  return (
    <>
      <h6 className="project-page-input-label"> Uygulama Ekle </h6>
      <Row>
        <Col xs={11}>
          <Select
            value={props.postData.applications}
            isMulti
            options={props.data}
            placeholder={"Uygulamalar"}
            onChange={props.HandleTestsSelect}
            closeMenuOnSelect={false}
          />
        </Col>
        <Col xs={1} className="fav-button-container" onClick={HandleSetFav}>
          <IconSvg icon={isFav ? "fav" : "not-fav"} />
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        {favourites.map((fav, i) => (
          <Col xs="auto" key={"fav" + i} className="favourite-app-button" onClick={() => HandleFavSelect(fav)}>
            {fav.name}
            <span onClick={() => HandleDeleteFav(fav.id)}>
              <IconSvg icon="delete-x" />
            </span>
          </Col>
        ))}
      </Row>

      <Modal show={showInputModal} centered onHide={() => setShowInputModal(false)}>
        <Modal.Body>
          {props.postData.applications.map((x, i) => (
            <span className="application-name-list-element" key={i + "shortcut"}>
              {i + 1 + "- " + x.name}
            </span>
          ))}
          <InputGroup size="md">
            <InputGroup.Text id="inputGroup-sizing-md">İsim Ver</InputGroup.Text>
            <Form.Control onKeyDown={HandleSavingFav} aria-label="Medium" aria-describedby="inputGroup-sizing-md" />
          </InputGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}

{
  /* <Modal.Header closeButton>
<Modal.Title>Proje Kısayolu Olarak Ekle</Modal.Title>
</Modal.Header> */
}
