import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../Services/Storage/LocalStorage";
import StorageModel from "../../../Models/StorageModel";
import HttpsClient from "../../../Services/HttpsClient";
import ApiHelper from "../../../Services/ApiHelper";
import Cache from "../../../Services/Cache";
import CreditAPI from "../API/CreditAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const creditAPI = new CreditAPI(apiHelper, cache);

export const getCreditsData = createAsyncThunk("credit/GetCreditsTestsAndAdmins", async (_, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;
  const data = await creditAPI.getCreditsData(token);

  return data;
});

export const upsertCredit = createAsyncThunk("credit/UpsertCredit", async (payload, getState) => {
  const state = getState.getState();
  const token = state.auth.user.token;

  const data = await creditAPI.upsertCredit(payload, token);

  return data;
});
