export default class AssessmentAPI {
  api = {
    getAssessments: "assessment/getAssessments",
    createAssessment: "assessment/createassessment",
    deleteAssessment: "assessment/deleteAssessment",
    updateAssessment: "assessment/updateassessment",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getAssesments(data, token) {
    return this.get(this.api.getAssessments, data, token);
  }

  async createAssessment(data, token) {
    return this.post(this.api.createAssessment, data, token);
  }

  async deleteAssessment(data, token) {
    return this.get(this.api.deleteAssessment, data, token);
  }

  async updateAssessment(data, token) {
    return this.post(this.api.updateAssessment, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);

    return response;
  }

  async post(url, data, token) {
    this.cache.remove("tests");

    await this.httpsClient.post(url, data, token);
  }
}
