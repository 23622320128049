import React, { useState, useEffect } from "react";
import { PolarImage } from "./PolarImage";
import { LikertImage } from "./LikertImage";
import { Overlay } from "../../../../../Helpers/Overlay";
import AnsweringPageFooter from "../../SharedComponents/AnswerIngPageFooter";
import FinishingModal from "../../SharedComponents/FinishingModal";
import "animate.css";
import { Button } from "react-bootstrap";

export function PolarLikertSlider(props) {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));

  let percentage = (window.questionNo / props.questions.length) * 100 + "%";

  const [current, setCurret] = useState();
  const [maxQuestionNumber, setMaxQuestionNumber] = useState(0);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [finishModalShow, setFinishModalShow] = useState(false);

  let AnswerinFooterNextButtonProps = {
    func: null,
    text: null,
    isDisabled: null,
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  let NextQuestion = (n) => {
    if (window.questionNo + 1 > props.questions.length || n > props.questions.length) return;
    if (n === false) renderQuestion(window.questionNo + 1);
    else renderQuestion(n);
  };
  let PrevQuestion = () => {
    if (window.questionNo - 1 === 0) {
      // props.goBack();
    } else if (maxQuestionNumber - 1 < window.questionNo) {
      renderQuestion(window.questionNo - 1);
    }
  };

  let Finish = () => {
    props.finish(props.questions);
    setFinishModalShow(false);
  };

  let DismisFinish = () => {
    props.dismissFinish(props.questions);
    setFinishModalShow(false);
  };

  let SetAnswer = async (order, option, n, isPolar, timer) => {
    if (window.innerWidth <= 500 || isPolar) setIsOverlayVisible(true);
    props.questions.map((q) => {
      if (q.order === order) {
        q.answer = option;
        if (q.timer) q.timer = q.timer + timer;
        else q.timer = timer;
      }
    });
    props.isB2b && props.update(props.questions);
    NextQuestion(n);
    if (window.innerWidth <= 500 || isPolar) await timeout(200);
    if (window.innerWidth <= 500 || isPolar) setIsOverlayVisible(false);

    if (window.isFinished || props.questions.length === n - 1) {
      setFinishModalShow(true);
    } else if (props.questions.length === n) {
      window.isFinished = true;
      window.lastAnswerNo = n;
    } else {
      window.lastAnswerNo = n;
    }
  };

  let renderQuestion = (n) => {
    if (n === 1) {
      //uygulama onceden baslami mi kontrol ediliyor, baslamis ise kalinan sira bulunuyor
      let startedBefore = props.questions.find((x) => {
        return x.answer !== undefined;
      });

      let last = props.questions.find((x) => {
        return x.answer === undefined;
      });

      if (last) n = last.order;
      else if (!last && startedBefore) {
        n = props.questions.length;
        window.isFinished = true;
        setFinishModalShow(true);
      }
    }
    if (maxQuestionNumber < n) setMaxQuestionNumber(n);
    const image = props.questions.find((x) => x.order === n);
    window.questionNo = n;
    if (image.subLikertId || image.likertId) {
      setCurret(<LikertImage q={image} a={props.answers} n={n} SetAnswer={SetAnswer} />);
    } else if (image.subPolaredId || image.polaredId) {
      setCurret(<PolarImage q={image} n={n} SetAnswer={SetAnswer} />);
    }
  };

  let HandleNextButtonFunctionAndText = () => {
    if (
      props.isFinished &&
      (window.lastAnswerNo === undefined || window.lastAnswerNo <= window.questionNo || window.isFinished)
    ) {
      AnswerinFooterNextButtonProps = {
        func: props.HandleFinishButton,
        text: "Uygulamayı Bitir",
      };
    } else {
      AnswerinFooterNextButtonProps = {
        func: NextQuestion,
        text: "Sonraki",
      };
    }
  };

  let CloseApplication = async () => {
    setIsOverlayVisible(true);
    await timeout(400);
    window.close();
  };

  HandleNextButtonFunctionAndText();

  return (
    <>
      {isOverlayVisible && <Overlay text="Lütfen Bekleyiniz" />}
      <FinishingModal show={finishModalShow} Finish={Finish} DismisFinish={DismisFinish} />
      {props.isB2b && (
        <Button variant="warning" className="save-and-exit-button" onClick={CloseApplication}>
          Kaydet ve Çık
        </Button>
      )}

      <h4 className="test-question-no-at-heder">
        {window.questionNo} / {props.questions.length}
      </h4>
      <div className="test-question-progression-container" style={{ position: "fixed" }}>
        <div className="test-question-progression-bar" style={{ width: percentage, position: "fixed" }}></div>
      </div>
      <div className="animate__animated  animate__fadeInDown">
        {!current && props.questions && renderQuestion(1)}
        {current}
      </div>
      <div>
        <AnsweringPageFooter
          prevButtonFunction={PrevQuestion}
          prevButtonText="Önceki"
          nextButtonText={AnswerinFooterNextButtonProps.text}
          nextButtonFunction={AnswerinFooterNextButtonProps.func}
          isNextDisabled={
            props.isTest
              ? false
              : props.isFinished
              ? false
              : window.lastAnswerNo === undefined || window.lastAnswerNo <= window.questionNo
              ? true
              : false
          }
        />
      </div>
    </>
  );
}
