import React, { useState } from "react";
import PageTabs from "./PageTabs";
import SentReports from "./Tabs/SentReport/SentReports";
import CreateProject from "./Tabs/SendApplication/CreateProject";
export function Projects() {
  const [currentTab, setCurrentTab] = useState(1);
  const SetCurrentTabToSentReport = () => {
    setCurrentTab(2);
  };

  const [creditsAndApps, setCreditsAndApps] = useState();

  return (
    <div className="">
      <PageTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === 1 ? (
        <CreateProject
          changeTab={SetCurrentTabToSentReport}
          creditsAndApps={creditsAndApps}
          setCreditsAndApps={setCreditsAndApps}
        />
      ) : (
        <SentReports creditsAndApps={creditsAndApps} />
      )}
    </div>
  );
}
