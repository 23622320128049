import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import "animate.css";
import ApiHelper from "../../../../Helpers/ApiHelper";

export function AppCard(props) {
  let HandleDateString = (dateData) => {
    const date = new Date(dateData);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  let HandleCardButtonText = () => {
    if (props.ticket.clickCounter >= 5) return "Giriş hakkı kalmadı";
    if (props.ticket.ticketResult?.isFinished) return "Tamamlandı";
    if (props.ticket.ticketResult !== null) return "Devam Et";
    else return "Başla";
  };

  let HandleRemainingDuration = (ticket) => {
    if (props.ticket.ticketResult?.isFinished) return "0 dakika";
    if (ticket.ticketResult && ticket.ticketResult.remainingDuration)
      return Math.floor(ticket.ticketResult.remainingDuration / 60) + "dakika";
    return ticket.test.duration + " dakika";
  };
  return (
    <Col xs={1} className="app-card-container  animate__animated  animate__fadeInLeft animate__faster">
      <Card className="appilcation-card">
        <Card.Header className="company-logo-container">
          <img src={ApiHelper.baseURL + "wwwroot/logo/" + props.companyInfo?.logoPath} height="80" width="250" />
        </Card.Header>
        <Card.Body>
          <div className="app-card-body">
            <Row>
              <Col xs="8" className="app-card-col">
                <p className="app-card-text-label">Uygulama Tipi</p>
                <p className="app-card-text"> {props.ticket.test.name} </p>
              </Col>
              <Col xs="4" className="app-card-col">
                <p className="app-card-text-label text-right">Tanımlanma</p>
                <p className="app-card-text text-right">{HandleDateString(props.ticket.createdAt)}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="8" className="app-card-col">
                <p className="app-card-text-label">Soru Sayısı</p>
                <p className="app-card-text">{props.ticket.test?.testImage?.questionNumber}</p>
              </Col>
              <Col xs="4" className="app-card-col">
                <p className="app-card-text-label text-right ">Son Teslim</p>
                <p className="app-card-text text-right">{HandleDateString(props.ticket.deadline)}</p>
              </Col>
            </Row>
          </div>
        </Card.Body>
        <Card.Footer className="text-muted">
          <Row className="start-app-button-row">
            {props.ticket.test.isTest && (
              <Col xs="6" className="app-duration-container">
                <span className="app-duration">
                  <IconSvg icon="clock" /> {HandleRemainingDuration(props.ticket)}
                </span>
              </Col>
            )}
            <Col xs={props.ticket.test.isTest ? 6 : 12} className="start-app-button-container">
              {props.ticket.clickCounter >= 5 && !props.ticket.ticketResult?.isFinished ? (
                <h6 style={{ textAlign: "center" }}> Giriş hakkı kalmadı </h6>
              ) : props.ticket.ticketResult?.isFinished ? (
                <h6 style={{ textAlign: "center" }}> Tamamlandı </h6>
              ) : (
                <button
                  className="start-app-button"
                  onClick={() => window.open(window.location.origin + "/#/application/" + props.ticket.id, "_blank")}
                >
                  {HandleCardButtonText()}
                </button>
              )}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
}
