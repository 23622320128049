import React from "react";
import SubScopeScale from "./SubScopeScale";
import { Row, Col } from "react-bootstrap";

export function ScopeScale(props) {
  let score = props.scope?.score?.calculatedScore;
  let HandleIsHit = (scale) => {
    if ((score > scale.start && score <= scale.end) || (scale.start === 0 && score === 0)) return true;
    else return false;
  };

  return (
    <div className="detail-container-pdf">
      <h6 className="detail-report-pdf-candidate-name">{props.candidateName}</h6>
      <h6 className="detail-report-pdf-header">Detay Rapor</h6>

      <h6 className="detail-section-scope-name-header-pdf">
        {props.scope.name}
        <span className="main-scope-score-header-pdf-detail">{score}</span>
      </h6>
      <div className="scope-detail-scope-container">
        <div className="">
          <Row>
            {props.scope.scales.map((scale, i) => {
              let isHit = HandleIsHit(scale);
              return (
                <Col
                  key={i + "-" + scale.id + "scopeScale"}
                  className={isHit ? "scale-style-pdf hit-scale" : "scale-style-pdf"}
                >
                  {scale.text}
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs="5">
              <h6 className="scale-detail-text-pdf">{props.scope.leftScaleText}</h6>
            </Col>
            <Col xs={{ offset: 2, span: 5 }}>
              <h6 className="scale-detail-text-pdf">{props.scope.rightScaleText}</h6>
            </Col>
          </Row>
          {props.scope.description?.length > 2 && (
            <p style={{ fontSize: "12px" }} className="scale-detail-text">
              Aciklama: {props.scope.description}
            </p>
          )}
        </div>
      </div>
      {props.scope.subScopes &&
        props.scope.subScopes.map((x, i) => (
          <div key={i + x.id + "ss"} className="subscope-detail-scope-container">
            <SubScopeScale subScope={x} scopeName={props.scope.name} />
          </div>
        ))}
    </div>
  );
}
