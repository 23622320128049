import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import SurveyAPI from "../API/SurveyAPI";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const surveyAPI = new SurveyAPI(apiHelper, cache);

export const getSurveys = createAsyncThunk("survey/getSurveys", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  return await surveyAPI.getAssesments(payload, token);
});

export const createSurvey = createAsyncThunk("survey/createsurvey", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await surveyAPI.createSurvey(payload, token);

  dispatch(getSurveys());

  return;
});

export const updateSurvey = createAsyncThunk("survey/updateSurvey", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await surveyAPI.updateSurvey(payload, token);

  dispatch(getSurveys());

  return;
});

export const deleteSurvey = createAsyncThunk("survey/deleteSurvey", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await surveyAPI.deleteSurvey(payload, token);

  dispatch(getSurveys());

  return;
});
