import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Summary_1({ item, isSub }) {
  const isHtml = item.scale.isHtml;
  let HandleIsHit = (range) => {
    if ((item.score > range.start && item.score <= range.end) || (range.start === 0 && item.score === 0)) return true;
    else return false;
  };

  return (
    <Row className="mb-2 ">
      <Col style={{ height: "20px" }} xs={3}>
        <Row>
          <Col xs={"auto"} className={!isSub ? "_main-scope-name-pdf-new" : "_sub-scope-names-pdf-new"}>
            {item.name}
          </Col>
          <Col>
            <div className={isSub ? "_sub-scope-score-pdf-new _flex-center" : "_main-scope-score-pdf-new _flex-center"}>
              {item.score}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={9}>
        <Row>
          {item.scale.ranges.map((range, i) => {
            let isHit = HandleIsHit(range);
            return (
              <Col
                key={i + "-" + range.id + "scopeScale"}
                className={
                  " " +
                  (isHit
                    ? isSub
                      ? "_scale-style-pdf-new _hit-sub-scale-new _flex-center"
                      : "_scale-style-pdf-new _hit-scale-new _flex-center"
                    : "_scale-style-pdf-new _flex-center")
                }
              >
                {isHtml ? <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} /> : range.text}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
