import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Summary_1({ item, isSub }) {
  const isHtml = item.scale.isHtml;

  return (
    <Row className="mb-3">
      <Col xs={3}>
        <span className={!isSub ? "main-scope-name-pdf" : "sub-scope-names-pdf"}>{item.name}</span>
        <span style={{ display: "inline-block", float: "right" }}>
          <span className={!isSub ? "main-scope-score-pdf" : "sub-scope-score-pdf"}>{item.normScore}</span>
        </span>
      </Col>
      <Col xs={9}>
        <Row>
          {item.scale.ranges.map((range, i) => {
            let isHit = range.isHit;
            return (
              <Col
                key={i + "-" + range.id + "scopeScale"}
                className={
                  isHit ? (isSub ? "scale-style-pdf hit-sub-scale" : "scale-style-pdf hit-scale") : "scale-style-pdf"
                }
              >
                {isHtml ? <div dangerouslySetInnerHTML={{ __html: range.text }} /> : range.text}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
