import refactorInventoryRow from "./TransformInventoryRow";
import refactorTestRow from "./TransformTestRow";

function processTicketRow(ticket, { isTest, isB2b }) {
  return isTest ? refactorTestRow(ticket, isB2b) : refactorInventoryRow(ticket, isB2b);
}

export default function generateExcelRows(data) {
  return data.tickets.map((ticket) => processTicketRow(ticket, data.test));
}
