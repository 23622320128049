import { createSlice } from "@reduxjs/toolkit";
import { getTestEntity } from "./Thunk";

export const exportExcelAllFilter = createSlice({
  name: "ExportExcelAllFilter",
  initialState: {
    testsEntity: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestEntity.fulfilled, (state, action) => {
      state.testsEntity = action.payload;
    });
  },
});

export const {} = exportExcelAllFilter.actions;

export default exportExcelAllFilter.reducer;
