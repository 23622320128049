export default class CreditAPI {
  api = {
    creditsData: "test/GetCreditsTestsAndAdmins",
    upsertCredit: "test/UpsertCredit",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getCreditsData(token) {
    const cachedData = this.cache.get("creditsData");
    if (cachedData) return cachedData;

    const response = await this.httpsClient.get(this.api.creditsData, undefined, token);

    this.cache.set("creditsData", response);

    return response;
  }

  async upsertCredit(payload, token) {
    const cachedData = this.cache.get("creditsData") || { credits: [], tests: [], admins: [] };
    const response = await this.httpsClient.get(this.api.upsertCredit, payload, token);
    const updatedData = { ...cachedData, credits: response.data };

    this.cache.set("creditsData", updatedData);

    return updatedData;
  }
}
