import React from "react";
import { TestScopeScaleB2c } from "./TestScopeScaleB2c";
import { Col, Row } from "react-bootstrap";

export function TestDetailSectionB2c(props) {
  let pdfStyles = {
    padding: "5mm",
    height: "400mm",
    margin: "0mm",
    width: "280mm",
    zIndex: "100",
    position: "relative",
    background: "#F7F7F4",
    padding: "50px 30px",
  };
  let wrapupStyles = {};
  let score = props.data.testScore;
  let HandleIsHit = (scale) => {
    if ((score > scale.start && score <= scale.end) || (scale.start === 0 && score === 0)) return true;
    else return false;
  };
  return (
    <div style={props.isPdf ? pdfStyles : wrapupStyles} id={"test" + props.id}>
      <div>
        <div className="b2c-detail-scope-scale-container">
          <h2 style={{ width: "100%", color: "#5f5f5f", textAlign: "center" }}>{props.data.testName}</h2>
          <h3 style={{ width: "100%", color: "#5f5f5f", textAlign: "center" }}>
            Genel Skor : {Math.floor(props.data.testScore)}
          </h3>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }}>
            <Row>
              {props.data.scales.map((scale, i) => {
                let isHit = HandleIsHit(scale);
                return (
                  <Col
                    xs={window.innerWidth < 500 && "12"}
                    key={i + "-" + scale.id + "scopeScale"}
                    className={isHit ? "detail-scale hit-scale" : "detail-scale scale-style"}
                  >
                    {scale.text}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <div style={{ padding: "30px 50px" }}>
            {props.data.scopeResults.map((scopeResult, i) => (
              <TestScopeScaleB2c key={i} scopeResult={scopeResult} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
