const CreateItems = (scope, subScope, array) => {
  let needToLoop = subScope ? subScope : scope;
  const CreateEntityObj = (item, componentName) => {
    return {
      ...item,
      scopeName: scope.name,
      subScopeName: subScope?.name,
      scopeId: scope.id,
      subScopeId: subScope?.id,
      componentName,
    };
  };

  needToLoop.summaries?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "BasicGraphSummaryEntity"));
  });

  needToLoop.details?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "BasicGraphDetailEntity"));
  });

  needToLoop.monos?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "MonoTextuaryEntity"));
  });

  needToLoop.duals?.forEach((item, i) => {
    array.push(CreateEntityObj(item, "DualTextuaryEntity"));
  });
};

export const GenerateReportItemsArray = (inventory) => {
  let itemsArray = [];
  inventory.scopes.forEach((scope) => {
    CreateItems(scope, null, itemsArray);

    scope.subScopes.forEach((subScope) => {
      CreateItems(scope, subScope, itemsArray);
    });
  });

  if (inventory.reportScheme) {
    var existingList = inventory?.reportScheme?.reportSchemeJson && JSON.parse(inventory.reportScheme.reportSchemeJson);

    const htmlEls = [];

    existingList.forEach((item) => {
      if (item.htmlEl) {
        item.orderedBefore = true;
        item.edited = false;
        htmlEls.push(item);
      }
    });

    itemsArray.forEach((item) => {
      existingList.filter((alreadyOrderedItem) => {
        if (alreadyOrderedItem.id == item.id && alreadyOrderedItem.componentName == item.componentName) {
          item.height = alreadyOrderedItem.height;
          item.order = alreadyOrderedItem.order;
          item.orderedBefore = true;

          return true;
        }
      });
    });

    itemsArray = itemsArray.concat(htmlEls);

    itemsArray.sort((a, b) => a.order - b.order);

    itemsArray.forEach((x, i) => (x.order = i + 1));

    return itemsArray;
  } else {
    itemsArray.forEach((x, i) => (x.order = i + 1));

    return itemsArray;
  }
};
