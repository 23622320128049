import React from "react";
import { OrdersRecord } from "./OrdersRecord";

export function OrdersRecordContainer(props) {
  return (
    <div className="orders-record-container">
      {props.ordersRecord?.success?.map((record, i) => (
        <OrdersRecord record={record} ordersRecord={props.ordersRecord} key={i + "succ"} succes={true} />
      ))}
      {props.ordersRecord?.fail?.map((record, i) => (
        <OrdersRecord record={record} ordersRecord={props.ordersRecord} key={i + "fail"} succes={false} />
      ))}
    </div>
  );
}
