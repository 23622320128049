import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetInventories } from "../../Store/Thunk";
import InventoryListRow from "./InventoryListRow";
import LeanModal from "./Modal/LeanModal";
import ScrollableCard from "../../../../../Helpers/ScrollableCard";

export default function InventoryList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetInventories());
  }, []);

  const inventories = useSelector((state) => state.inventory.inventories);

  return (
    <>
      <LeanModal />
      {/* <AnswersModal /> */}
      <ScrollableCard>
        {inventories?.map((inv, i) => (
          <InventoryListRow inventory={inv} key={i} />
        ))}
      </ScrollableCard>
    </>
  );
}
