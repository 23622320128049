export default class TestAPI {
  api = {
    getTests: "test/GetTests",
    createApplication: "test/CreateApplication",
  };

  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async getTests(data, token) {
    return await this.get(this.api.getTests, data, token);
  }

  async createApplication(data, token) {
    return this.post(this.api.createApplication, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
