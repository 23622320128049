export const BasicGraphDetailApiUrls = {
  createBasicGraphDetail: "reportitem/CreateBasicGraphDetail",
  updateBasicGraphDetail: "reportitem/UpdateBasicGraphDetail",
  deleteBasicGraphDetail: "reportitem/DeleteBasicGraphDetail",
  getBasicGraphDetails: "reportitem/GetBasicGraphDetails",
};

export default class BasicGraphDetailAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async createBasicGraphDetail(data, token) {
    return this.post(BasicGraphDetailApiUrls.createBasicGraphDetail, data, token);
  }

  async updateBasicGraphDetail(data, token) {
    return this.post(BasicGraphDetailApiUrls.updateBasicGraphDetail, data, token);
  }

  async getBasicGraphDetails(data, token) {
    return await this.get(BasicGraphDetailApiUrls.getBasicGraphDetails, data, token);
  }

  async deleteBasicGraphDetail(data, token) {
    return await this.get(BasicGraphDetailApiUrls.deleteBasicGraphDetail, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
