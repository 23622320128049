import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Modal from "react-bootstrap/Modal";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ApiHelper from "../../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../../Helpers/Auth/UseAuthHelper";
import { getTests } from "../../Store/Thunk";
import { closeInfoModal } from "../../Store";
import { useDispatch, useSelector } from "react-redux";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

export default function ApplicationInfoModal() {
  const dispatch = useDispatch();
  const application = useSelector((state) => state.entityList.infoModal.application);
  const isShow = useSelector((state) => state.entityList.infoModal.isShow);

  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const [infoTitle, setInfoTitle] = useState(application?.infoTitle);
  const [editorState, setEditorState] = useState();

  const [pdfCoverImageName, setPdfCoverImageName] = useState({
    file: null,
    value: "",
  });

  const [pdfInfoImageName, setPdfInfoImageName] = useState({
    file: null,
    value: "",
  });
  const [iconImageName, setIconImageName] = useState({
    file: null,
    value: "",
  });

  useEffect(() => {
    const prevHtml = application?.infoHtml
      ? application?.infoHtml
      : "<p> Uygulama bilgilendirme sayfası içeriğini giriniz</p>";
    const contentBlock = htmlToDraft(prevHtml);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  const OnEditorStateChange = (state) => {
    setEditorState(state);
  };

  const ResfreshAfterUpdate = () => {
    dispatch(getTests());
    dispatch(closeInfoModal());
  };

  const UpdateApplicationInfo = () => {
    const formData = new FormData();

    formData.append("pdfCoverImage", pdfCoverImageName.file);
    formData.append("pdfInfoImage", pdfInfoImageName.file);
    formData.append("iconImage", iconImageName.file);
    formData.append("infoHtml", draftToHtml(convertToRaw(editorState?.getCurrentContent())));
    formData.append("testId", application?.id);
    formData.append("infoTitle", infoTitle);

    ApiHelper.xmlPostFormData("test/UpsertTestInfo", formData, user.token, ResfreshAfterUpdate);
  };

  const DeleteImage = (imageName, type) => {
    ApiHelper.getAsync(
      "test/deleteInfoImage",
      { imageName, type, testId: application.id },
      function (result) {
        if (!result.data.statusCode) alert("hata olustu");
        else {
          dispatch(getTests());
          dispatch(closeInfoModal());
        }
      },
      user.token
    );
  };

  return (
    <Modal show={isShow} fullscreen={true} onHide={() => dispatch(closeInfoModal())}>
      <Modal.Header closeButton>
        <Modal.Title>{application?.name} Bilgilendirme Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-3">
            <Col>
              {application?.iconImageName ? (
                <Button
                  size="sm"
                  variant="danger"
                  style={{ margin: "10px" }}
                  onClick={() => DeleteImage(application?.iconImageName, "icon")}
                >
                  <IconSvg icon="trash-can" fill="#fff" />
                </Button>
              ) : (
                <input
                  value={iconImageName.value}
                  type="file"
                  accept="image/png, image/jpeg, image/webp"
                  onChange={(e) =>
                    setIconImageName({
                      file: e.target.files[0],
                      value: e.target.value,
                    })
                  }
                />
              )}

              <img src={ApiHelper.baseURL + "wwwroot/info/" + application?.iconImageName} height="55" width="55" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              {application?.pdfCoverImageName ? (
                <Button
                  size="sm"
                  variant="danger"
                  style={{ margin: "10px" }}
                  onClick={() => DeleteImage(application?.pdfCoverImageName, "cover")}
                >
                  <IconSvg icon="trash-can" fill="#fff" />
                </Button>
              ) : (
                <input
                  value={pdfCoverImageName.value}
                  type="file"
                  accept="image/png, image/jpeg, image/webp"
                  onChange={(e) =>
                    setPdfCoverImageName({
                      file: e.target.files[0],
                      value: e.target.value,
                    })
                  }
                />
              )}

              <img
                src={ApiHelper.baseURL + "wwwroot/info/" + application?.pdfCoverImageName}
                height="297"
                width="210"
              />
            </Col>
            <Col>
              {application?.pdfInfoImageName ? (
                <Button
                  size="sm"
                  variant="danger"
                  style={{ margin: "10px" }}
                  onClick={() => DeleteImage(application?.pdfInfoImageName, "info")}
                >
                  <IconSvg icon="trash-can" fill="#fff" />
                </Button>
              ) : (
                <input
                  value={pdfInfoImageName.value}
                  type="file"
                  accept="image/png, image/jpeg, image/webp"
                  onChange={(e) =>
                    setPdfInfoImageName({
                      file: e.target.files[0],
                      value: e.target.value,
                    })
                  }
                />
              )}

              <img src={ApiHelper.baseURL + "wwwroot/info/" + application?.pdfInfoImageName} height="297" width="210" />
            </Col>
          </Row>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Bilgilendirme sayfası başlığı giriniz"
              value={infoTitle}
              onChange={(e) => setInfoTitle(e.target.value)}
            />
          </InputGroup>

          <Editor editorState={editorState} onEditorStateChange={OnEditorStateChange} />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={UpdateApplicationInfo}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
