import React, { useState, useEffect } from "react";
import ApiHelper from "../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import { Overlay } from "../../../../Helpers/Overlay";
import { ResultTable } from "./ResultTable";

export function MyResults() {
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const [ticketImages, setTicketImages] = useState([]);
  const [overlayVisible, setOverlayVisible] = useState(true);

  useEffect(() => {
    GetUserTestTicketResults();
  }, []);

  const GetUserTestTicketResults = () => {
    ApiHelper.getAsync(
      "ticket/GetUserTicketResults",
      { userId: user.userId },
      function (result) {
        setOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setTicketImages(result.data.data);
        } else {
        }
      },
      user.token
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      {overlayVisible && <Overlay />}
      <ResultTable tickets={ticketImages} />
    </div>
  );
}
