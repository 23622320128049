import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import AnsweringPageFooter from "../../SharedComponents/AnswerIngPageFooter";

import FinishingModal from "../../SharedComponents/FinishingModal";
import TextSection from "../../SharedComponents/TextSection";

export function ChecboxesImage({ q, Finish, isOnlyChecklist }) {
  const [checkboxAnswersJson, setCheckboxAnswersJson] = useState(q);

  const [finishModalShow, setFinishModalShow] = useState(false);

  let HandleOnChange = (id) => {
    const result = checkboxAnswersJson.map((x) => {
      if (x.id === id) {
        x.checked = !x.checked;
      }
      return x;
    });
    setCheckboxAnswersJson(result);
  };

  let Finish_ = () => {
    Finish(checkboxAnswersJson);
    setFinishModalShow(false);
  };

  let DismisFinish = () => {
    setFinishModalShow(false);
  };

  let FinishCheckList = () => {
    if (isOnlyChecklist) setFinishModalShow(true);
    else Finish(checkboxAnswersJson);
  };

  let HandleAdjQuestions = () => {
    return q.map((q, i) => {
      return (
        <Row key={i} className="justify-content-center">
          <Col xs="8" md="5" style={{ margin: "10px" }} onClick={() => HandleOnChange(q.questionId)}>
            <Form.Check
              style={{
                borderRadius: "16px",
                marginTop: "-10px",
                background: "#FFF",
                padding: "17px 10px 13px 43px",
                display: "inline-flex",
                width: "100%",
              }}
              label={
                <h5
                  style={{
                    fontSize: window.innerWidth <= 500 ? "15px" : "18px",
                    paddingLeft: "10px",
                    color: "#000",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  {q.text}
                </h5>
              }
              type="checkbox"
              id={q.text}
              checked={q?.checked}
            />
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <TextSection
        text="Aşağıdaki ifadelerden sizi tanımladığını düşündüklerinizi
          işaretleyiniz"
        class="checkbox-image-info-text"
      />

      <Container style={{ marginTop: "180px", marginBottom: 150 }} id="checkbox-container">
        {HandleAdjQuestions()}
      </Container>
      <AnsweringPageFooter
        onClickFunction={FinishCheckList}
        isDisabled={false}
        buttonText={isOnlyChecklist ? "Tamamla" : "Devam"}
      />

      <FinishingModal show={finishModalShow} Finish_={Finish_} DismisFinish={DismisFinish} />
    </>
  );
}
