import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function WysiwygEditor({ reset, onChange, editorValue }) {
  const [editorState, setEditorState] = useState();
  useEffect(() => {
    let contentBlock;
    if (editorValue) contentBlock = htmlToDraft(editorValue);
    else contentBlock = htmlToDraft("");

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

    setEditorState(EditorState.createWithContent(contentState));
  }, [editorValue]);

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [reset]);

  const handleChange = (data) => {
    setEditorState(data);

    const preparedData = draftToHtml(convertToRaw(editorState?.getCurrentContent()));

    onChange && onChange(preparedData);
  };

  return <Editor editorState={editorState} onEditorStateChange={handleChange} placeholder="Açıklama giriniz" />;
}
