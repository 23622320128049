import React from "react";
import { Col, Row } from "react-bootstrap";

export default function ScalePercentage(props) {
  let score = props.scope?.score?.calculatedScore;
  let willRender = props.isSub ? props.scope.subScales : props.scope.scales;
  let HandleIsHit = (scale) => {
    if ((score > scale.start && score <= scale.end) || (scale.start === 0 && score === 0)) return true;
    else return false;
  };
  return (
    <Row className="mb-2">
      <Col xs={3}>
        <span className={!props.isSub ? "main-scope-name-pdf" : "sub-scope-names-pdf"}>{props.scope.name}</span>
        <span style={{ display: "inline-block", float: "right" }}>
          <span className={!props.isSub ? "main-scope-score-pdf" : "sub-scope-score-pdf"}>{score}</span>
        </span>
      </Col>
      <Col xs={9}>
        <Row>
          {willRender.map((scale, i) => {
            let isHit = HandleIsHit(scale);
            return (
              <Col
                key={i + "-" + scale.id + "scopeScale"}
                className={
                  isHit
                    ? props.isSub
                      ? "scale-style-pdf hit-sub-scale"
                      : "scale-style-pdf hit-scale"
                    : "scale-style-pdf"
                }
              >
                {scale.text}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
