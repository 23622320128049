import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../../Helpers/ApiHelper";

export default function CoverSection(props) {
  let style = {
    height: "297mm",
    width: "210mm",
    margin: 0,
    padding: 0,
  };

  const demography = props.demography;

  let background = {
    position: "absolute",
    top: "0mm",
    left: "0mm",
    height: "297mm",
    zIndex: "-100",
    width: "210mm",
    margin: "0mm",
    padding: "0mm",
  };
  return (
    <div style={style} id={"entry" + props.id}>
      <Image
        className="pdf-background"
        crossOrigin="anonymous"
        style={background}
        // src={require("./../../../../../Images/cover.jpeg")}
        src={ApiHelper.baseURL + "wwwroot/info/" + props.coverImage}
      />
      <div className="cover-page-candidate-data-container">
        <p>
          İsim :<span className="cover-page-candidate-data-span">{demography.name}</span>
        </p>
        <p>
          E-posta:
          <span className="cover-page-candidate-data-span">{demography.email}</span>
        </p>
        <p>
          Doğum yılı:
          <span className="cover-page-candidate-data-span">{demography.birthYear}</span>
        </p>
        <p>
          Cinsiyet:
          <span className="cover-page-candidate-data-span">{demography.gender}</span>
        </p>
        <p>
          Okul:
          <span className="cover-page-candidate-data-span">{demography.school}</span>
        </p>
        <p>
          İş tecrübesi:
          <span className="cover-page-candidate-data-span">{demography.experience}</span>
        </p>
      </div>
    </div>
  );
}
