import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UseAuthHelper from "../../../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../../../Helpers/ApiHelper";

import TargetMailManagement from "./TargetMailManagement";
import SelectApplication from "./SelectApplication";
import { Button, Modal } from "react-bootstrap";
import IconSvg from "../../../../../../App/AppMenu/IconSvg";
import { Overlay } from "../../../../../../Helpers/Overlay";
import { useTranslation } from "react-i18next";

export default function CreateProject(props) {
  const { t } = useTranslation();
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const [showOverlay, setShowOverlay] = useState(true);
  const [showSendingConfirmModal, setShowSendingConfirmModal] = useState(false);
  const [postData, setPostData] = useState({
    userId: user.userId,
    applications: [],
    mailList: [],
    allowPdf: false,
    openCam: false,
  });
  const [showCreateTicketsModal, setShowCreateTicketsModal] = useState({
    show: false,
    data: null,
  });

  useEffect(() => {
    ApiHelper.getAsync(
      "test/GetCreditsForAdmin",
      { userId: user.userId },
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          SetAdminCreditsAndTickets(result.data.data);
        } else {
        }
      },
      user.token
    );
  }, []);

  let SetAdminCreditsAndTickets = (data) => {
    data.tests.forEach((test) => {
      let credit = data.credits.find((credit) => credit.testId === test.id);
      test.credit = credit.creditAmount;
      test.creditId = credit.id;
    });
    let mappedtests = data.tests.map((x) => ({
      label: x.name + t("remainingCredits") + x.credit + ")",
      name: x.name,
      value: x.id,
      amount: x.credit,
      isDisabled: x.credit < 11,
      creditId: x.creditId,
    }));

    props.setCreditsAndApps(mappedtests);
    setShowOverlay(false);
  };

  let HandleTestsSelect = (data) => {
    setPostData((x) => ({ ...x, applications: data }));
  };

  let SendTestToCandidate = () => {
    setShowSendingConfirmModal(false);

    setShowOverlay(true);
    let postObj = {
      userId: postData.userId,
      mailList: postData.mailList,
      allowPdf: postData.allowPdf,
      openCam: postData.openCam,
      creditIds: postData.applications.map((x) => x.creditId),
    };

    ApiHelper.postAsync(
      "credit/CreateTickets",
      postObj,
      function (result) {
        setShowOverlay(false);
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          props.changeTab();
        } else {
          setShowSendingConfirmModal({ show: true, data: result.data.data });
        }
      },
      user.token
    );
  };

  return (
    <div className="project-page-component-container">
      {showOverlay && <Overlay />}
      <Row>
        <Col xs={12} md={6}>
          <SelectApplication
            data={props.creditsAndApps}
            HandleTestsSelect={HandleTestsSelect}
            selectedApplications={postData.applications}
            postData={postData}
            setPostData={setPostData}
            user={user}
          />
        </Col>
        <Col xs={11} md={6}>
          <TargetMailManagement
            postData={postData}
            setPostData={setPostData}
            SendTestToCandidate={SendTestToCandidate}
            setShowSendingConfirmModal={setShowSendingConfirmModal}
          />
        </Col>
      </Row>

      <Modal show={showSendingConfirmModal} onHide={() => setShowSendingConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Onayla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5> Uygulamalar</h5>
          <div className="send-confirm-list-container">
            {postData.applications.map((a, i) => (
              <div key={"apps" + i} className="candidate-mail">
                {a.name}
              </div>
            ))}
          </div>
          <h5> {t("emails")} </h5>
          <div className="send-confirm-list-container">
            {postData.mailList.map((m, i) => (
              <div key={"mails" + i} className="candidate-mail">
                {i + 1 + ") " + m}
              </div>
            ))}
          </div>
          <Row>
            <Col>
              <h6>
                {t("pdf")}: <IconSvg icon={postData.allowPdf ? "check" : "not"} />
              </h6>
            </Col>
            <Col>
              <h6>
                {t("camera")}: <IconSvg icon={postData.openCam ? "check" : "not"} />
              </h6>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={SendTestToCandidate} style={{ width: "100%" }}>
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateTicketsModal.show} onHide={() => setShowCreateTicketsModal({ show: false })} centered>
        <Modal.Header>
          <h2> Hata </h2>
        </Modal.Header>
        <Modal.Body>
          <h5> {showCreateTicketsModal.data} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowCreateTicketsModal({ show: false })}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// {ticketTableData && (
//   <TicketTable
//     ticketTableData={ticketTableData}
//     tests={data}
//     HandleMailSearch={HandleMailSearch}
//   />
// )}

// let HandleMailSearch = (val) => {
//   if (val !== "") {
//     let filteredData = ticketTableData.filter((x) => x.email.includes(val));
//     setTicketTableData(filteredData);
//   } else {
//     setTicketTableData(cleanFilterData);
//   }
// };
