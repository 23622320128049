import React, { useEffect, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import ApiHelper from "../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import { Overlay } from "../../../../Helpers/Overlay";
import { useTranslation } from "react-i18next";

export function Settings() {

  const [showOverlay, setShowOverlay] = useState(true);
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [previevLogoSrc, setPrevievLogoSrc] = useState(null);

  const { auth } = UseAuthHelper();
  const user = auth?.data;

  useEffect(() => {
    GetCompanySettings();
  }, []);

  const GetCompanySettings = () => {
    ApiHelper.getAsync(
      "credit/getCompanyinfo",
      { userId: user.userId },
      function (result) {
        setShowOverlay(false);
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          setPrevievLogoSrc(ApiHelper.baseURL + "wwwroot/logo/" + result.data.data.logoPath);
          setMailContent(result.data.data.mailContent);
          setCompanyName(result.data.data.companyName);
        } else {
        }
      },
      user.token
    );
  };
  const ReadUploadFile = (e) => {
    setCompanyLogoFile(e.target.files[0]);
    setPrevievLogoSrc(URL.createObjectURL(e.target.files[0]));
  };

  let CreateScopeQuestion = () => {
    setShowOverlay(true);

    const formData = new FormData();

    formData.append("logo", companyLogoFile);
    formData.append("companyName", companyName);
    formData.append("mailContent", mailContent);
    formData.append("userId", user.userId);

    ApiHelper.xmlPostFormData("credit/CreateCompanyInfo", formData, user.token, RefreshAfterCreate);
  };

  let RefreshAfterCreate = () => {
    setShowOverlay(false);
  };
  const { t } = useTranslation();
  return (
    <Container style={{ paddingTop: "50px" }}>
      {showOverlay && <Overlay />}
      <Row className="justify-content-center">
        <Col xs="12" md="6">
          <h3 className="admin-settings-input-header"> {t("companyName")}</h3>
          <FloatingLabel label={t("pleaseEnterACompanyName")} className="mb-3">
            <Form.Control
              type="text"
              placeholder="Şirket adı giriniz"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="6">
          <h3 className="admin-settings-input-header">{t("theTextOfTheInvitationMailSentToTheCandidate")}</h3>
          <FloatingLabel controlId="floatingTextarea" label={t("pleaseEnterTheInvitationMailText")} className="mb-3">
            <Form.Control
              as="textarea"
              style={{ height: "80px" }}
              value={mailContent}
              onChange={(e) => setMailContent(e.target.value)}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="6">
          <label className="admin-setting-page-add-image-label">
            <input id="admin-excel-input" type="file" onChange={ReadUploadFile} accept=".jpeg, .png, .webp, .jpg" />
            <IconSvg icon="add-image" />

            <span> {t("uploadLogo")} ( 300X100 ) </span>
          </label>
          <div className="logo-previev-img-container">
            <img src={previevLogoSrc} height="100" width="300" />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="12" md="6">
          <Button
            variant="primary"
            style={{ width: "100%" }}
            onClick={CreateScopeQuestion}
            disabled={!(companyName && mailContent && companyLogoFile)}
          >
            {t("saveChanges")}
            {t("testing7")}

          </Button>
        </Col>
      </Row>
    </Container>
  );
}
