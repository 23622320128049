import { createSlice } from "@reduxjs/toolkit";
import {
  createBasicGraphSummary,
  getBasicGraphSummaries,
  deleteBasicGraphSummary,
  updateBasicGraphSummary,
} from "./Thunk";

export const basicGraphSummary = createSlice({
  name: "basicGraphSummary",
  initialState: {
    scopeId: 0,
    id: 0,
    isSub: false,
    isLoading: false,
    basicGraphSummaryEntities: [],
  },
  reducers: {
    updateBasicGraphSummaryState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBasicGraphSummary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createBasicGraphSummary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphSummaryEntities = action.payload;
    });
    builder.addCase(getBasicGraphSummaries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBasicGraphSummaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphSummaryEntities = action.payload;
    });
    builder.addCase(deleteBasicGraphSummary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBasicGraphSummary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.basicGraphSummaryEntities = action.payload;
    });
    builder.addCase(updateBasicGraphSummary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateBasicGraphSummary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.id = 0;
    });
  },
});

export const { updateBasicGraphSummaryState } = basicGraphSummary.actions;

export default basicGraphSummary.reducer;
