import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { createReportScheme, deleteReportScheme, getAllReportItems, updateReportScheme } from "../Store/Thunk";
import { updateReportSchemeState } from "../Store";
import BreadCrumb from "../../../../Components/Dashboard/BreadCrumb";
import BasicGraphSummaryEntity from "./Entities/BasicGraphSummaryEntity";
import BasicGraphDetailEntity from "./Entities/BasicGraphDetailEntity";
import MonoTextuaryEntity from "./Entities/MonoTextuaryEntity";
import DualTextuaryEntity from "./Entities/DualTextuaryEntity";
import { Overlay } from "../../../../Helpers/Overlay";
import SchemeElementModal from "./SchemeElementModal";
import EmptySpace from "./HtmlElements/EmptySpace";
import MonoSpace from "./HtmlElements/MonoSpace";
import DualSpace from "./HtmlElements/DualSpace";
import ImageSpace from "./HtmlElements/ImageSpace";
import DemoWrapUpModal from "./DemoWrapUpModal";
import ScrollableCard from "../../../../Helpers/ScrollableCard";

export default function ReportScheme() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const reportSchemeState = useSelector((state) => state.reportScheme);
  const [schemeElementModalData, setSchemeElementModalData] = useState({
    show: false,
    type: null,
  });
  const [demoWrapUpModalData, setDemoWrapUpModalData] = useState({
    show: false,
    type: null,
  });

  const components = {
    BasicGraphSummaryEntity,
    BasicGraphDetailEntity,
    MonoTextuaryEntity,
    DualTextuaryEntity,
    DualSpace,
    MonoSpace,
    EmptySpace,
    ImageSpace,
  };

  useEffect(() => {
    dispatch(updateReportSchemeState({ testId: state.id }));
    dispatch(getAllReportItems({ id: state.id, applicationType: state.applicationType }));
  }, []);

  const CreateReportScheme = (newList) => {
    var postObj = {
      reportSchemeJson: JSON.stringify(newList ? newList : reportSchemeState.reportItemEntityList),
      applicationId: reportSchemeState.inventory.id,
      applicationType: state.applicationType,
    };

    if (reportSchemeState?.inventory?.reportScheme) {
      postObj.id = reportSchemeState.inventory.reportScheme.id;
      dispatch(updateReportScheme(postObj));
    } else dispatch(createReportScheme(postObj));
  };

  const DeleteReportScheme = () => {
    dispatch(deleteReportScheme({ id: reportSchemeState?.inventory?.reportScheme.id }));
  };

  const CreateDynamicComponent = (entity) => {
    const SpecificComponent = components[entity.componentName];
    return (
      <SpecificComponent key={Math.random().toString(16).slice(2)} entity={entity} UpdateScheme={CreateReportScheme} />
    );
  };

  const OpenDemoWrapUpModal = () => {
    setDemoWrapUpModalData({
      show: true,
      type: null,
    });
  };

  return (
    <div className="sadmin-page-container">
      {reportSchemeState.isLoading && <Overlay />}
      <BreadCrumb
        pageName={t("Şema")}
        backPageName={state.isTest ? t("tests") : t("inventories")}
        backUrl={state.isTest ? "/test" : "/inventory"}
      />
      <Row xs="auto">
        <Col>
          <ButtonGroup size="sm" className="mb-3">
            <Button onClick={() => setSchemeElementModalData({ show: true, type: "mono" })}>Tek Bölüm</Button>
            <Button onClick={() => setSchemeElementModalData({ show: true, type: "dual" })}>Çift Bölüm</Button>
            <Button onClick={() => setSchemeElementModalData({ show: true, type: "space" })}>Boşluk</Button>
            <Button onClick={() => setSchemeElementModalData({ show: true, type: "image" })}>Görsel</Button>
          </ButtonGroup>
        </Col>
        <Col style={{ float: "right" }}>
          <Button size="sm" onClick={() => CreateReportScheme()}>
            {reportSchemeState?.inventory?.reportScheme ? "Güncelle" : "Kaydet"}
          </Button>
        </Col>

        {reportSchemeState?.inventory?.reportScheme && (
          <Col>
            <Button size="sm" variant="danger" onClick={DeleteReportScheme}>
              Mevcut Kaydı Sil
            </Button>
          </Col>
        )}
        <Col>
          <h4> {reportSchemeState?.inventory?.id} </h4>
        </Col>
        <Col>
          <h4> {reportSchemeState?.inventory?.name} </h4>
        </Col>
        {reportSchemeState?.inventory?.reportScheme && (
          <Col>
            <Button size="sm" variant="success" onClick={OpenDemoWrapUpModal}>
              Wrap Up
            </Button>
          </Col>
        )}
        {reportSchemeState?.inventory?.reportScheme && (
          <Col>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                navigate("/wrapuppage", {
                  state: { state: state },
                });
              }}
            >
              Wrap Up Page
            </Button>
          </Col>
        )}
      </Row>
      <ScrollableCard>
        {reportSchemeState.reportItemEntityList &&
          reportSchemeState.reportItemEntityList.map((entity) => CreateDynamicComponent(entity))}
      </ScrollableCard>

      <SchemeElementModal
        schemeElementModalData={schemeElementModalData}
        onHide={() => setSchemeElementModalData({ show: false, type: null })}
        UpdateScheme={CreateReportScheme}
      />
      {reportSchemeState?.inventory && (
        <DemoWrapUpModal
          demoWrapUpModalData={demoWrapUpModalData}
          onHide={() => setDemoWrapUpModalData({ show: false, type: null })}
        />
      )}
    </div>
  );
}
