import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CreatePostObj } from "../../../Application/ApplicationResults//Pdf/PdfDownloadButton";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { useState } from "react";
import { Overlay } from "../../../../Helpers/Overlay";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";

import { useNavigate } from "react-router-dom";
import WrapUpNew from "../../../../Features/WrapUp/Components/WrapUpNew";

export function WrapUp(props) {
  const navigate = useNavigate();
  const { ticketResultId } = useParams();
  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const [showOverlay, setShowOverlay] = useState(true);
  const [state, setState] = useState(null);

  useEffect(() => {
    document.getElementById("login")?.remove();
    GetTicketResult();
  }, []);

  let GetTicketResult = () => {
    ApiHelper.getAsync(
      "ticket/GetTicketResult",
      { id: ticketResultId },
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          HandlePdfSummary(result.data.data);
        } else {
        }
      },
      user.token
    );
  };

  let HandlePdfSummary = (ticket) => {
    // let candidateName = ticketResult.name;
    let ticketResult = ticket.ticketResult;
    ticketResult.testId = ticket.testId;
    const postObj = CreatePostObj(ticketResult);
    ApiHelper.postAsync(
      postObj.isTest ? "user/GetTestPdfImage" : "user/GetInventoryPdfImage",
      postObj,
      function (result) {
        if (result.status !== 200) {
        } else if (result.data.statusCode === 1) {
          setState({
            data: result.data.data,
            ticketResult,
            demography: ticket.candidateUser.demography,
          });
          setShowOverlay(false);
        } else {
        }
      },
      null
    );
  };

  return (
    <div>
      {showOverlay && <Overlay dna={true} text="Sonuç hazırlanıyor..." />}
      {state?.data && state?.ticketResult && (
        // <B2cPdfComponent
        //   data={state.data}
        //   finishedTestData={state.ticketResult}
        //   demography={state.demography}
        //   userRole={user.userRole[0]}
        //   navigate={navigate}
        // />

        <WrapUpNew
          data={state.data}
          // finishedTestData={finishedTestData}
          isUser={true}
          // isTest={applicationData?.isTest}
        />
      )}
    </div>
  );
}
