import React from "react";
import { Badge, Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAssessmentState } from "../Store";
import { deleteAssessment } from "../Store/Thunk";
import { HandleMappingApplications } from "../Store";

export default function AssessmentList() {
  const dispatch = useDispatch();
  const assessmentList = useSelector((state) => state.assessment.assessmentList);

  const HandleDeleteButton = (id) => {
    dispatch(deleteAssessment({ id }));
  };

  const HandleUpdateButton = (assess) => {
    var obj = {
      id: assess.id,
      name: assess.name,
      isB2b: assess.isB2b,
      price: assess.price,
      isUpdateOperation: true,
      selectedApplications: HandleMappingApplications(assess.inventories, assess.tests, assess.surveys),
    };

    dispatch(UpdateAssessmentState(obj));
  };

  return (
    assessmentList &&
    assessmentList.map((x, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row xs="auto">
            <Col xs="1">{x.id}</Col>
            <Col xs="12" md="3">
              {x.name}
            </Col>
            <Col md="1" style={{ color: x.isB2b ? "green" : "red", fontWeight: 700 }}>
              {x.price}
            </Col>
            <Col xs="12" md="5">
              <Row xs="auto">
                {x.inventories &&
                  x.inventories.map((inv, j) => (
                    <Col key={j + "inv"}>
                      <Badge bg="primary">{inv.name}</Badge>
                    </Col>
                  ))}
                {x.tests &&
                  x.tests.map((test, k) => (
                    <Col key={"te" + k}>
                      <Badge bg="secondary">{test.name}</Badge>
                    </Col>
                  ))}
                {x.surveys &&
                  x.surveys.map((survey, m) => (
                    <Col key={"te" + m}>
                      <Badge bg="warning">{survey.name}</Badge>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md="2">
              <Row xs="auto" className="justify-content-md-end">
                <Col>
                  <Button size="sm" variant="success" onClick={() => HandleUpdateButton(x)}>
                    Düzenle
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    variant="danger"
                    size="sm"
                    onClick={() => HandleDeleteButton(x.id)}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
