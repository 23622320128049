export function isValidScale(ranges, newRange) {
  let start = parseInt(newRange.start);
  let end = parseInt(newRange.end);
  for (const scale of ranges) {
    if (
      (start <= scale.start && end >= scale.end) ||
      (start >= scale.start && start <= scale.end) ||
      (end >= scale.start && end <= scale.end)
    ) {
      return false;
    }
  }
  var result =
    start >= 0 &&
    start <= 100 &&
    end >= 0 &&
    end <= 100 &&
    !isNaN(start) &&
    !isNaN(end) &&
    (start < end || end > start);
  return result;
}
