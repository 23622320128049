import Storage from "./Storage";

export default class LocalStorage extends Storage {
  getData(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  setData(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  deleteData(key) {
    localStorage.removeItem(key);
  }
}
