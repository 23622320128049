import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createBasicGraphSummary,
  getBasicGraphSummaries,
  updateBasicGraphSummary,
  deleteBasicGraphSummary,
} from "../Store/Thunk";

import { setIsDual } from "../../../ScoringScale/Store";
import ReportItem from "../../ReportItem/Components/ReportItem";
import { updateBasicGraphSummaryState } from "../Store";

export default function BasicGraphSummary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const basicGraphSummaryState = useSelector((state) => state.basicGraphSummary);

  useEffect(() => {
    dispatch(setIsDual(false));
    let isSub = state.subScope ? true : false;
    let scopeId = state.subScope ? state.subScope.id : state.scope.id;
    dispatch(getBasicGraphSummaries({ id: scopeId, isSub }));
    dispatch(updateBasicGraphSummaryState({ scopeId, isSub }));
  }, []);

  const CreateBasicGraphSummary = (val) => {
    dispatch(createBasicGraphSummary(val));
  };

  const UpdateBasicGraphSummary = (val) => {
    dispatch(updateBasicGraphSummary(val));
  };

  return (
    <div className="sadmin-page-container">
      <ReportItem
        keyword="Summary"
        state={state}
        pageName={t("createBasicGraphSummary")}
        CreateFunction={CreateBasicGraphSummary}
        UpdateFunction={UpdateBasicGraphSummary}
        DeleteFunction={deleteBasicGraphSummary}
        entityList={basicGraphSummaryState.basicGraphSummaryEntities}
        isLoading={basicGraphSummaryState.isLoading}
      />
    </div>
  );
}
