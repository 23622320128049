import React from "react";

export default function MonoText({ item, isSub }) {
  let HandleIsHit = (range) => {
    if ((item.score > range.start && item.score <= range.end) || (range.start === 0 && item.score === 0))
      return item.scale.isHtml ? (
        <div className="mb-3" dangerouslySetInnerHTML={{ __html: range.text }} />
      ) : (
        <p>{range.text}</p>
      );
  };

  return item.scale.ranges.map((range, i) => {
    return HandleIsHit(range);
  });
}
