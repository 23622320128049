export function processApplicationData(application) {
  const processedData = { application };

  processAnswers(processedData.application, processedData);

  if (processedData.answers) {
    processedData.answers = JSON.parse(processedData.answers);
    eachRecursive(processedData.answers);
  }

  eachRecursive(processedData.application);

  processedData.application = sortNestedScalesObjects(processedData.application);

  return processedData;
}

export function processAnswers(obj, result) {
  Object.keys(obj).forEach((key) => {
    if (key === "answers") {
      result.answers = obj.answers;
      return;
    }

    if (typeof obj[key] === "object" && obj[key] !== null) {
      processAnswers(obj[key], result);
    }
  });
}

export function eachRecursive(obj) {
  Object.keys(obj).forEach((key) => {
    if (key === "id") {
      obj.id = 0;
    }
    if (key === "answers") {
      obj.answers = null;
      obj.answersId = 0;
    }
    if (typeof obj[key] === "object" && obj[key] !== null) {
      eachRecursive(obj[key]);
    }
  });
}

function sortScalesByStartAndEnd(scalesArray) {
  scalesArray.sort((firstScale, secondScale) => {
    if (firstScale.start !== secondScale.start) {
      return firstScale.start - secondScale.start;
    }
    return firstScale.end - secondScale.end;
  });
}

function sortNestedScalesObjects(obj) {
  const scalesPaths = ["scales", "scope.scales", "scope.subScope.subScales"];

  scalesPaths.forEach((path) => {
    let scalesLocation = obj;
    path.split(".").forEach((part) => {
      scalesLocation = scalesLocation && scalesLocation[part];
    });

    if (Array.isArray(scalesLocation)) {
      sortScalesByStartAndEnd(scalesLocation);
    }
  });

  return obj;
}
