import React from "react";
import { designTypes } from "../../../CreateApplication/ReportItems/ReportItemBase/Components/DesignTypeDropdown";
import Summary_LoadingBar from "./Designs/Summary_LoadingBar";
import Summary_1 from "./Designs/Summary_1";
import Detail_1 from "./Designs/Detail_1";
import Detail_LoadingBar from "./Designs/Detail_LoadingBar";
import MonoText from "./Designs/MonoText";
import DualText from "./Designs/DualText";
import Detail_2 from "./Designs/Detail_2";
import MonoText_2 from "./Designs/MonoText_2";
import { NormalizeScore } from "../WrapUpNew";

export default function SelectDesign({ item }) {
  const designs = {
    Summary_1,
    Summary_LoadingBar,
    Detail_1,
    Detail_2,
    Detail_LoadingBar,
    MonoText,
    DualText,
    MonoText_2,
  };

  const isSub = item.subScopeId ? true : false;

  const GetNameProperty = () => {
    for (var name in designTypes) {
      var value = designTypes[name];
      if (value == item.designType) return name;
    }
  };

  const CreateDynamicComponent = () => {
    NormalizeScore(item);
    const SpecificComponent = designs[GetNameProperty()];
    return <SpecificComponent item={item} isSub={isSub} />;
  };
  return CreateDynamicComponent();
}
