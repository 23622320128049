import React from "react";
import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../../../App/AppMenu/IconSvg";

export function OrdersRecord(props) {
  let HandleDateString = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString().substr(-2)}`;
  };

  let GetTime = (dateData) => {
    if (!dateData) return "--";
    const date = new Date(dateData);
    return `${date.getHours()}:${date.getMinutes()}`;
  };
  return (
    <Row>
      <Col xs="12" md="3" onClick={() => navigator.clipboard.writeText(props.record.UserEmail)}>
        <span
          style={{
            color: props.succes ? "#12b400" : "red",
            fontWeight: "700",
            paddingRight: "10px",
          }}
        >
          {props.ordersRecord.id}
        </span>
        {props.record.UserEmail} <IconSvg icon="copy-text" />
      </Col>
      <Col xs="12" md="6">
        {props.ordersRecord.sentApps?.map((app, i) => (
          <span key={i + "bb"} style={{ padding: "3px" }}>
            {app?.name}
          </span>
        ))}
      </Col>
      <Col xs="12" md="3" style={{ display: "flex", justifyContent: "flex-end" }}>
        {HandleDateString(props.ordersRecord.createdAt)}-{GetTime(props.ordersRecord.createdAt)}
        <span style={{ paddingLeft: "10px" }}>
          <IconSvg icon={props.succes ? "succes-dot" : "fail-dot"} />
        </span>
      </Col>
    </Row>
  );
}
