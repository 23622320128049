import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "animate.css";

export function LikertImage(props) {
  useEffect(() => {
    window.innerWidth > 500 && EnterAnimation();
  });

  let EnterAnimation = () => {
    document.getElementById("question-text").classList.remove("animate__backOutLeft");
    document.getElementById("question-text").classList.add("animate__backInRight");
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  let ExitAnimation = () => {
    document.getElementById("question-text").classList.remove("animate__backInRight");
    document.getElementById("question-text").classList.add("animate__backOutLeft");
  };
  let HandleAnswering = async (a) => {
    window.innerWidth > 500 && ExitAnimation();
    document.getElementById(a)?.classList?.add("animate__flash");
    await timeout(400);
    document.getElementById(a)?.classList?.remove("animate__flash");

    props.SetAnswer(props.q.order, a, props.n + 1);
  };
  let HandleAnswerButtonClass = (no) => {
    if (props.q.answer && props.q.answer === no)
      return "answer-btn animate__animated animate__faster selected-answer-btn";
    return "answer-btn animate__animated animate__faster";
  };
  return (
    <>
      <div className="likert-image-question-container animate__animated animate__fadeInDown animate__faster">
        <h2 className="likert-image-question-text animate__animated  animate__faster" id="question-text">
          {props.q.text}
        </h2>
      </div>

      <Container style={{ marginTop: "230px" }} className="animate__animated  animate__fadeInDown animate__faster">
        {props.a &&
          props.a.map((a, i) => {
            return (
              <Row className="justify-content-center" style={{ margin: "5px", width: "100%" }} key={i}>
                <Col xs="10" md="8" lg="4">
                  <button id={i + 1} className={HandleAnswerButtonClass(i + 1)} onClick={() => HandleAnswering(i + 1)}>
                    <span style={{ float: "left" }}>{i + 1}</span> {a}
                  </button>
                </Col>
              </Row>
            );
          })}
      </Container>
    </>
  );
}
