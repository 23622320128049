export default class ExportExcelAllFilterAPI {
  api = {
    demoResult: "test/GetDemoResultData",
    testsEntityOnly: "test/GetTestsEntityOnly",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getDemoResultData(data, token) {
    const response = await this.httpsClient.post(this.api.demoResult, data, token);

    return response.data;
  }

  async getTestEntityOnly(token) {
    const cachedData = this.cache.get("testsEntity");
    if (cachedData) return cachedData;

    const response = await this.httpsClient.get(this.api.testsEntityOnly, undefined, token);

    this.cache.set("testsEntity", response);

    return response;
  }
}
