import React, { useState } from "react";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
import IconSvg from "./IconSvg";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export default function TopNavigation(props) {
  const { t } = useTranslation();
  const { setAuth } = UseAuthHelper();
  const [currentLanguage, setCurrentLanguage] = useState();


  let HandleLogOut = () => {
    localStorage.clear();
    setAuth(null);
    window.location.href = window.location.origin;
  };
  let handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
    setCurrentLanguage(i18next.language);
  };

  let size = window.innerWidth <= 500 ? "45" : "100";
  let dropdownIconSize = window.innerWidth <= 500 ? "20" : "30";


  return (<div className="top-navigation-container">
    <Row style={{ width: window.innerWidth }}>
      <Col xs="3" md="2" className="top-navigation-logo-container">
        <IconSvg icon="cp-logo" height={size} width={size} />
      </Col>
      <Col xs="6" md="8">
        <h2 className="top-navigation-page-name">{t(props.pageName)}</h2>
      </Col>
      <Col xs="3" md="2"></Col>
    </Row>

    <Dropdown drop={"down-centered"}>
      <Dropdown.Toggle className="top-navigation-user-name">
        {props.userName}
        <span style={{ marginLeft: "20px" }}>
          <IconSvg icon="user" height={dropdownIconSize} width={dropdownIconSize} />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className={"top-navigation-dropdown-menu"}>
        <Dropdown.Item style={{ backgroundColor: (i18next.language === "tr" ? "#dcdcdc" : "") }}>
          <Row onClick={() => handleLanguageChange("tr")}>
            <Col>{t("turkish")}</Col>
            <Col xs={3}>
              <IconSvg icon="flag-tr" height={24} width={24} />
            </Col>
          </Row>
        </Dropdown.Item>

        <Dropdown.Item style={{ backgroundColor: (i18next.language === "en" ? "#dcdcdc" : "") }}>
          <Row onClick={() => handleLanguageChange("en")}>
            <Col>{t("english")}</Col>
            <Col xs={3}>
              <IconSvg icon="flag-uk" height={24} width={24} />
            </Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <Row onClick={HandleLogOut}>
            <Col>{t("logout")}</Col>
            <Col xs={3}>
              <IconSvg icon="on-off" height={24} width={24} />
            </Col>
          </Row>
        </Dropdown.Item>


      </Dropdown.Menu>
    </Dropdown>


  </div>);
}
