import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";

import "../../Styles/index.css";
import { openDetailedDescriptionModal } from "../../Store";
import ScaleModal from "../ScaleModal";

export default function Range({ range }) {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.scale.isOpenDetailedDescriptionModal);

  const openModal = () => {
    dispatch(openDetailedDescriptionModal(range));
  };

  return (
    <Col>
      <Button variant="outline-success" onClick={openModal}>{`${range?.start} - ${range?.end}`}</Button>
      {isModalOpen && <ScaleModal />}
    </Col>
  );
}
