import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import ApiHelper from "../../Helpers/ApiHelper";

import { Overlay } from "../../Helpers/Overlay";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export function Confirmation() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [message, setMessage] = useState("Hesap doğrulanıyor, lütfen bekleyiniz");

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const u = searchParams.get("u");
    const t = searchParams.get("t").replace(/ /g, "");
    if (!u || !t) {
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    } else {
      ApiHelper.getAsync(
        "user/confirmemail",
        { u, t },
        function (result) {
          setIsOverlayVisible(false);
          if (result.status !== 200) {
            setMessage("e-posta doğrulanamadı");
            MySwal.fire({
              title: <strong>Hata</strong>,
              html: <i>Sunucu hatası</i>,
              icon: "error",
            });
          } else if (result.data.statusCode === 1) {
            setMessage("e-posta doğrulama işlemi tamamlandı");
          } else {
            setMessage("e-posta doğrulanamadı");
            MySwal.fire({
              title: <strong>Hata</strong>,
              html: <i>{result.data.data}</i>,
              icon: "error",
            });
          }
        },
        null
      );
    }
  });

  return (
    <Container>
      {isOverlayVisible && <Overlay />}
      <h1>{message}</h1>
    </Container>
  );
}
