import React, { useRef, useState } from "react";

import IconSvg from "../../../App/AppMenu/IconSvg";
import { Col, Row } from "react-bootstrap";
import { Overlay } from "../../../Helpers/Overlay";
import PdfFile from "./PdfReportItems/PdfFile";
import { ComponentToPrint } from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import "../Styles/PdfFile.css";

export default function PdfDownloadButton({ scheme, appData, overlayNotNeeded, GetPdfPreview }) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [pdfComponent, setPdfComponent] = useState(null);

  const componentRef = useRef(null);

  let DownloadActualPdfFile = () => {
    handlePrint();
  };

  const Show = async () => {
    return;
  };

  let HandlePdfExport = async (showPreview) => {
    setShowOverlay(true);
    if (showPreview) {
      GetPdfPreview(<PdfFile StartDownloadPdf={Show} scheme={scheme} appData={appData} />);
      setShowOverlay(false);
    } else {
      setPdfComponent(<PdfFile StartDownloadPdf={DownloadActualPdfFile} scheme={scheme} appData={appData} />);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setShowOverlay(false);
    },
    onAfterPrint: () => {
      setPdfComponent(null);
    },
  });

  return (
    <>
      {showOverlay && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <Row className="_pdf-download-button justify-content-center">
        <Col
          className={`align-content-center justify-content-end darken-hover ${GetPdfPreview ? " round-left-side-30px" : " round-30px"}`}
          onClick={() => {
            HandlePdfExport(false);
          }}
        >
          <Row>
            <Col>Pdf</Col>
            <Col xs={"auto"}>
              <div>
                <IconSvg icon="download" />
              </div>
            </Col>
          </Row>
        </Col>
        {GetPdfPreview && (
          <Col
            xs={"auto"}
            className={"align-content-center round-right-side-30px darken-hover"}
            onClick={() => {
              HandlePdfExport(true);
            }}
          >
            <div>
              <IconSvg width={24} height={24} icon={"preview"} />
            </div>
          </Col>
        )}
      </Row>
      <ComponentToPrint ref={componentRef}>
        <div className={"shadow-lg onlyPrint"} id={"pdfComp"} ref={componentRef}>
          {pdfComponent}
        </div>
      </ComponentToPrint>
    </>
  );
}
