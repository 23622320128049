import React from "react";
import { ProgressBar, Row, Col } from "react-bootstrap";

export default function ParameterProgressBar({ name, score }) {
  if (score <= 0) score = 0;
  else score = Number(score).toFixed(1);
  return (
    <Row className="mb-3">
      <Col xs="3">
        <h4 className="main-scope-name-pdf">
          {name}
          <span style={{ float: "right", paddingRight: "3px" }}>{score + "%"}</span>
        </h4>
      </Col>
      <Col xs="9">
        <ProgressBar style={{ height: "25px" }} animated variant="warning" now={score} label={score + "%  " + name} />
      </Col>
    </Row>
  );
}
