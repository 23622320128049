import React from "react";
import Modal from "react-bootstrap/Modal";
import AnsweringPageFooter from "./AnswerIngPageFooter";

export default function FinishingModal(props) {
  return (
    <Modal
      show={props.show}
      style={{
        marginTop: window.innerWidth >= 500 ? "10%" : "35%",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          height: "250px",
          display: "inline-flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Plus Jakarta Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "100%",
            letterSpacing: "-0.24px",
          }}
        >
          Tebrikler, Bütün soruları cevapladınız!
        </h1>
      </div>
      <AnsweringPageFooter
        prevButtonFunction={props.DismisFinish}
        prevButtonText="Vazgeç"
        nextButtonFunction={props.Finish}
        nextButtonText="Tamamla"
        isNextDisabled={false}
        isRelative={true}
        isBorderRadius={true}
      />
    </Modal>
  );
}
