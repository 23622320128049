export default class Cache {
  constructor(storageStrategy) {
    this.storageStrategy = storageStrategy;
  }

  set(key, data, timeToLive = 60000) {
    const expiry = Date.now() + timeToLive;
    this.storageStrategy.setData(key, { data, expiry });
  }

  get(key) {
    const data = this.storageStrategy.getData(key);

    if (data && data.expiry > Date.now()) {
      return data.data;
    }

    this.storageStrategy.deleteData(key);
    return null;
  }

  remove(key) {
    this.storageStrategy.deleteData(key);
  }
}
