import React from "react";
import { Col, Row } from "react-bootstrap";

export default function Detail_1({ item, isSub }) {
  return (
    <div>
      <h6 className="_detail-section-scope-name-header-new-wrap-up">
        {item.name}
        <span className="main-scope-score-header-pdf-detail">{item.score}</span>
        <span className="main-scope-score-header-pdf-detail">{item.normScore}</span>
      </h6>
      <div className="scope-detail-scope-container">
        <Row>
          {item.scale.ranges.map((range, i) => {
            let isHit = range.isHit;
            return (
              <Col
                key={i + "-" + range.id + "scopeScale"}
                className={
                  isHit ? (isSub ? "scale-style-pdf hit-sub-scale" : "scale-style-pdf hit-scale") : "scale-style-pdf"
                }
              >
                {item.scale.isHtml ? (
                  <div dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} />
                ) : (
                  range.start + range.text + range.end
                )}
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xs="5">
            <div className="scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
          </Col>
          <Col xs={{ offset: 2, span: 5 }}>
            <div className="scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
          </Col>
        </Row>
        {item.generalInformationText?.length > 2 && (
          <div className="scale-detail-text" dangerouslySetInnerHTML={{ __html: item.generalInformationText }} />
        )}
      </div>
    </div>
  );
}
