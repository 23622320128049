export default class B2CRowDTO {
  constructor(ticket) {
    this.resultId = ticket.id;
    this.email = ticket.email;
    this.name = ticket.name;
    this.birthyear = ticket.birthYear;
    this.gender = ticket.gender;
    this.school = ticket.school;
    this.faculty = ticket.faculty;
    this.department = ticket.department;
    this.experience = ticket.experience;
  }
}
