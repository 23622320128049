import React from "react";
import { Tooltip } from "react-tooltip";

export function PolarAnswer(props) {
  let btnClass = "";
  if (props.prevAnswer && props.prevAnswer === props.value) {
    btnClass = "selected-answer-btn";
  }

  return (
    <>
      <button
        id={props.value + "polar"}
        className={"answer-btn animate__animated animate__faster " + props.btnClassName + " " + btnClass}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={props.tooltipContent}
        onClick={() => props.HandleAnswering(props.value, props.value + "polar")}
        // style={{ float: props.float }}
      >
        {props.label}
      </button>
      {window.innerWidth >= 500 && <Tooltip id="my-tooltip" place="bottom" />}
    </>
  );
}
