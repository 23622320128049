import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

export default function Detail_2({ item, isSub }) {
  let HandleIsHit = (range) => {
    if ((item.score > range.start && item.score <= range.end) || (range.start === 0 && item.score === 0)) return true;
    else return false;
  };

  const GetScaleText = () => {
    const ranges = item.scale.ranges;
    for (let index = 0; index < ranges.length; index++) {
      const range = ranges[index];
      if (HandleIsHit(range)) {
        if (item.scale.isHtml) {
          return <div className="_flex-center" dangerouslySetInnerHTML={{ __html: item.monoSpaceHtml }} />;
        } else {
          return <p className="_detail-2-scale-text _flex-center">{range.text}</p>;
        }
      }
    }
  };

  return (
    <Row>
      <Col xs="4">
        <div className="_scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.lowSideText }} />
      </Col>
      <Col xs="4">
        <h6 className={isSub ? "_detail-2-sub-scope-name-header" : "_detail-2-scope-name-header"}>
          {item.name}
          <span className={isSub ? "_detail-2-sub-scope-score-header" : "_detail-2-scope-score-header"}>
            {item.score}
          </span>
        </h6>
        <br />
        <p> {GetScaleText()}</p>
        <ProgressBar variant={isSub ? "sub-scope" : "main-scope"} now={item.score} label={`${item.score}%`} />
      </Col>
      <Col xs="4">
        <div className="_scale-detail-text-pdf" dangerouslySetInnerHTML={{ __html: item.highSideText }} />
      </Col>
    </Row>
  );
}
