import React from "react";
import { useSelector } from "react-redux";
import WrapUpNew from "../WrapUp/Components/WrapUpNew";
import BreadCrumb from "../../Components/Dashboard/BreadCrumb";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WrapUpPage() {
  const { t } = useTranslation();
  const { state } = useLocation();

  let scheme = useSelector((state) => state.reportScheme.reportItemEntityList);
  let inventory = useSelector((state) => state.reportScheme.inventory);
  if (inventory == null || scheme == null) return;
  scheme = JSON.parse(JSON.stringify(scheme));

  const demoAppData = {
    name: inventory.Name,
    pdfCover: inventory.PdfCoverImageName,
    pdfInfo: inventory.PdfInfoImageName,
  };

  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  scheme.forEach((reportItem) => {
    reportItem.score = randomIntFromInterval(1, 100);
  });

  return (
    <div className={"sadmin-page-container"}>
      <BreadCrumb pageName={t("WrapUpPage")} backPageName={t("Şema")} backUrl={"/scheme"} backState={state} />

      <WrapUpNew demoScheme={scheme} demoAppData={demoAppData} />
    </div>
  );
}

// data={PdfData}
