import { createSlice } from "@reduxjs/toolkit";
import { getAllReportItems, createReportScheme, updateReportScheme, deleteReportScheme } from "./Thunk";
import { GenerateReportItemsArray } from "../Utils/Utils";

export const reportScheme = createSlice({
  name: "reportScheme",
  initialState: {
    inventory: null,
    reportItemEntityList: [],
  },
  reducers: {
    updateReportSchemeState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReportItems.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllReportItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inventory = action.payload;
      state.reportItemEntityList = GenerateReportItemsArray(action.payload);
    });

    builder.addCase(createReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteReportScheme.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteReportScheme.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { updateReportSchemeState } = reportScheme.actions;

export default reportScheme.reducer;
