import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailedDescriptionModal } from "../Store";
import DOMPurify from "dompurify";

export default function ScaleModal() {
  const dispatch = useDispatch();
  const scale = useSelector((state) => state.scale);
  const isOpen = scale.isOpenDetailedDescriptionModal;

  const modalRange = scale.modalRange;
  const isHtml = scale.isHtml;
  const closeModal = () => {
    dispatch(closeDetailedDescriptionModal());
  };

  const HandleText = (text) => {
    if (isHtml) {
      const sanitizedHtml = DOMPurify.sanitize(text);
      return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }
    return text;
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <h3 style={{ textAlign: "center", width: "100%" }}>
          {modalRange?.start} - {modalRange?.end}
        </h3>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>{HandleText(modalRange?.text)}</Col>
          <Col>{HandleText(modalRange?.rightText)}</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
