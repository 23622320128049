export default class ApplicationResultsAPI {
  api = {
    demoResults: "test/GetDemoResults",
    removeTests: "test/deletetestresults",
  };

  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getDemoResults(token) {
    const cachedData = this.cache.get("demoResults");
    if (cachedData) return cachedData;

    const response = await this.httpsClient.get(this.api.demoResults, undefined, token);

    this.cache.set("demoResults", response);

    return response;
  }

  async removeSelectedTests(payload, token) {
    const preparedData = {
      idsJson: JSON.stringify(payload),
    };
    await this.httpsClient.post(this.api.removeTests, preparedData, token);

    this.cache.remove("demoResults");
  }
}
