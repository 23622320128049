import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createMonoTextuary, getMonoTextuaries, updateMonoTextuary, deleteMonoTextuary } from "../Store/Thunk";

import { setIsDual } from "../../../ScoringScale/Store";
import ReportItem from "../../ReportItem/Components/ReportItem";
import { updateMonoTextuaryState } from "../Store";

export default function MonoTextuary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const monoTextuaryState = useSelector((state) => state.monoTextuary);

  useEffect(() => {
    dispatch(setIsDual(false));
    let isSub = state.subScope ? true : false;
    let scopeId = state.subScope ? state.subScope.id : state.scope.id;
    dispatch(updateMonoTextuaryState({ scopeId, isSub }));
    dispatch(getMonoTextuaries({ id: scopeId, isSub }));
  }, []);

  const CreateMonoTextuary = (val) => {
    dispatch(createMonoTextuary(val));
  };

  const UpdateMonoTextuary = (val) => {
    dispatch(updateMonoTextuary(val));
  };

  return (
    <div className="sadmin-page-container">
      <ReportItem
        keyword="Mono"
        state={state}
        pageName={t("createMonoTextuary")}
        CreateFunction={CreateMonoTextuary}
        UpdateFunction={UpdateMonoTextuary}
        DeleteFunction={deleteMonoTextuary}
        entityList={monoTextuaryState.monoTextuaryEntities}
        isLoading={monoTextuaryState.isLoading}
      />
    </div>
  );
}
