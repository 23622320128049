export default class B2BRowDTO {
  constructor(ticket) {
    this.ticketId = ticket.id;
    this.ticketResultId = ticket.ticketResult.id;
    this.adminEmail = ticket.ticketResult.adminEmail;
    this.email = ticket.candidateUser.demography.email;
    this.name = ticket.candidateUser.demography.name;
    this.birthyear = ticket.candidateUser.demography.birthYear;
    this.gender = ticket.candidateUser.demography.gender;
    this.school = ticket.candidateUser.demography.school;
    this.faculty = ticket.candidateUser.demography.faculty;
    this.department = ticket.candidateUser.demography.department;
    this.experience = ticket.candidateUser.demography.experience;
  }
}
