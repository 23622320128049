import { createSlice, current } from "@reduxjs/toolkit";

export const wrapUp = createSlice({
  name: "wrapUp",
  initialState: {
    Summary: true,
    Detail: true,
    Mono: true,
    Dual: true,
  },
  reducers: {
    updateShowButtons(state, action) {
      const { newState } = action.payload;
      for (const [key, value] of Object.entries(newState)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { updateShowButtons } = wrapUp.actions;

export default wrapUp.reducer;
