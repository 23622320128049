import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { HandleUpdateOperation } from "../../../Store";

export default function UpdateInventoryButton({ application }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const HandleMappingAssesments = (assessments) => {
    return assessments.map((x) => ({
      label: x.isB2b ? x.name + "B2b" : x.name + "B2c",
      name: x.name,
      id: x.id,
      value: x.id,
    }));
  };
  const HandleUpdateButton = () => {
    var mappedAssessments = HandleMappingAssesments(application.assessments);

    dispatch(
      HandleUpdateOperation({
        inventoryId: application.id,
        name: application.name,
        selectedAssessments: mappedAssessments,
        isUpdateOperation: true,
      })
    );
  };
  return (
    <Button size="sm" variant="success" onClick={HandleUpdateButton}>
      {t("edit")}
    </Button>
  );
}
