import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NavigateButton({ path, application, children }) {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(path, { state: application });

  return (
    <Button size="sm" variant="primary" onClick={handleNavigate}>
      {children}
    </Button>
  );
}
