export default class StorageModel {
  constructor(storage) {
    this.storage = storage;
  }

  getData(key) {
    return this.storage.getData(key);
  }

  setData(key, value) {
    this.storage.setData(key, value);
  }

  deleteData(key) {
    this.storage.deleteData(key);
  }
}
