import React from "react";
import ConsistencyScore from "./ConsistencyScore";
import LeanNumbers from "./LeanNumbers";

export default function CalculatedParameters({ consistencyScore, leanNumbers }) {
  return (
    <div
      className="mb-5 mt-5"
      style={{
        padding: "50px",
        background: "white",
        borderRadius: "20px",
      }}
    >
      <ConsistencyScore score={consistencyScore} />
      <br></br>
      <LeanNumbers numbers={leanNumbers} />
    </div>
  );
}
