import React from "react";
import IconSvg from "./IconSvg";
import { useTranslation } from "react-i18next";

export default function MenuButton(props) {
  const { t } = useTranslation();
  const buttonClass = "sidemenu-button show-cursor";
  const selectedClass = "sidemenu-button show-cursor sidemenu-selected-button";


  return (
    <div
      className={props.selected === props.page ? selectedClass : buttonClass}
      onClick={() => props.HandleSideMenuButtonClick(props.pageObj)}
    >
      <IconSvg page={props.page} isSelected={props.selected === props.page} />
      <span className="sidemenu-button-text"> {t(props.page)} </span>
    </div>
  );
}
