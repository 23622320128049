import React from "react";
import { Row, Col } from "react-bootstrap";

export default function SubScopeScale(props) {
  let score = props.subScope?.score?.calculatedScore;
  let HandleIsHit = (scale) => {
    if ((score > scale.start && score <= scale.end) || (scale.start === 0 && score === 0)) return true;
    else return false;
  };
  return (
    <>
      <div style={{ marginLeft: "25px", marginBottom: "-13px" }}>
        <h6 className="detail-scope-names">{props.scopeName}</h6>
        <h6 className="detail-sub-scope-names">
          {props.subScope.name}
          <span className="detail-sub-scope-score-pdf">{score}</span>
        </h6>
      </div>
      <div style={{ height: "2px" }}></div>

      <div className="subscope-detail-sub-container-pdf">
        <Row>
          {props.subScope.subScales.map((scale, i) => {
            let isHit = HandleIsHit(scale);
            return (
              <Col key={i + "-" + scale.id} className={isHit ? "hit-sub-scale" : "scale-style"}>
                {scale.text}
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xs="5">
            <h6 className="scale-detail-text-pdf">{props.subScope.leftScaleText}</h6>
          </Col>
          <Col xs={{ offset: 2, span: 5 }}>
            <h6 className="scale-detail-text-pdf">{props.subScope.rightScaleText}</h6>
          </Col>
        </Row>
        {props.subScope.description?.length > 2 && (
          <p style={{ fontSize: "12px" }} className="scale-detail-text">
            Aciklama: {props.subScope.description}
          </p>
        )}
      </div>
    </>
  );
}
