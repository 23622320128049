import { createAsyncThunk } from "@reduxjs/toolkit";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";
import ScopeAPI from "../API/ScopeAPI";
import { ScopeApiUrls } from "../API/ScopeAPI";
const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const scopeAPI = new ScopeAPI(apiHelper, cache);

const HandleGettingScopesAfterApiOperations = async (state) => {
  const currentApp = state.scope.currentApplication;
  const token = state.auth.user.token;
  return await scopeAPI.getScopes({ applicationId: currentApp.id, applicationType: currentApp.applicationType }, token);
};

export const getScopes = createAsyncThunk(ScopeApiUrls.getScopes, async (_, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  const currentApp = state.scope.currentApplication;

  return await scopeAPI.getScopes({ applicationId: currentApp.id, applicationType: currentApp.applicationType }, token);
});

export const createScope = createAsyncThunk(ScopeApiUrls.createScope, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const { scope } = payload;

  await scopeAPI.createScope(scope, token);

  return await HandleGettingScopesAfterApiOperations(state);
});

export const deleteScope = createAsyncThunk(ScopeApiUrls.deleteScope, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;
  const { testId, id } = payload;

  await scopeAPI.deleteScope({ id }, token);

  return await HandleGettingScopesAfterApiOperations(state);
});

export const updateScope = createAsyncThunk("scope/updateScope", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const { testId, scope } = payload;

  await scopeAPI.updateScope(scope, token);

  return await HandleGettingScopesAfterApiOperations(state);
});

export const getSubScope = createAsyncThunk("subscope/getSubScope", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  return await scopeAPI.getSubScope({ scopeId: payload }, token);
});

export const createSubScope = createAsyncThunk("subscope/createSubScope", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const { scopeId, subScope } = payload;

  await scopeAPI.createSubScope(subScope, token);

  return await scopeAPI.getSubScope({ scopeId }, token);
});

export const deleteSubScope = createAsyncThunk("subscope/deleteSubScope", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const { scopeId, id } = payload;

  await scopeAPI.deleteSubScope({ id }, token);

  return await scopeAPI.getSubScope({ scopeId }, token);
});

export const updateSubScope = createAsyncThunk("subscope/updateScope", async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const { scopeId, subScope } = payload;

  await scopeAPI.updateSubScope(subScope, token);

  return await scopeAPI.getSubScope({ scopeId }, token);
});
