import { useMemo } from "react";

export default function useFormattedDate(date) {
  return useMemo(() => {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const startDate = new Date(date);
    startDate.setMonth(date.getMonth() - 1);
    const formattedStartDate = formatDate(startDate);

    const endDate = new Date(date);
    endDate.setDate(date.getDate() + 1);
    const formattedEndDate = formatDate(endDate);

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }, [date]);
}
