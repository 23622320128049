import Button from "react-bootstrap/Button";
import React from "react";
import { useTranslation } from "react-i18next";

export default function OpenInventoryButton({ application }) {
  const { t } = useTranslation();
  const openPage = () => {
    window.open(window.location.origin + "/#/iapp/" + application.id, "_blank");
  };

  return (
    <Button disabled={!application?.questionsImage} size="sm" variant="primary" onClick={openPage}>
      {t("open")}
    </Button>
  );
}
