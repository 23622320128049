import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import React from "react";

export default function RangeParameter({ label, value, onChange }) {
  return (
    <Row>
      <Col>
        <h5>{label}</h5>
      </Col>
      <Col>
        <h5>%{value}</h5>
      </Col>
      <InputGroup className="mb-3">
        <Form.Control
          style={{ background: "#f0f8ff" }}
          type="range"
          max="100"
          min="0"
          step="1"
          placeholder="Enter range"
          value={value}
          onChange={onChange}
        />
      </InputGroup>
    </Row>
  );
}
