const xlsx = require("xlsx");

export default class ExcelGenerator {
  generateExcel(rows, fileName) {
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(rows);
    xlsx.utils.book_append_sheet(wb, ws, fileName);
    xlsx.writeFile(wb, fileName + ".xlsx");
  }
}
