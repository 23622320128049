import React from "react";
import { Card, Row, Col, Accordion, Button } from "react-bootstrap";
import IconSvg from "../../../../../App/AppMenu/IconSvg";
import { useDispatch, useSelector } from "react-redux";
import { updateReportSchemeState } from "../../Store";

export default function MonoSpace({ entity }) {
  const dispatch = useDispatch();
  const entityList = useSelector((state) => state.reportScheme.reportItemEntityList);

  const deleteElement = () => {
    let newList = JSON.parse(JSON.stringify(entityList));

    newList.splice(entity.order - 1, 1);
    newList.forEach((entity, i) => {
      entity.order = i + 1;
    });
    dispatch(updateReportSchemeState({ reportItemEntityList: newList }));
  };
  const CreateHtmlElement = (val) => {
    return (
      <Card>
        <Card.Body>
          <div dangerouslySetInnerHTML={{ __html: val }} />
        </Card.Body>
      </Card>
    );
  };
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Row style={{ width: "100%" }}>
            <Col xs="6" md="6">
              <h5> Tek Bölüm</h5>
            </Col>
            <Col xs="6" md="4">
              <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                  <h5
                    style={{
                      color: entity.orderedBefore && !entity.edited ? "green" : "red",
                    }}
                  >
                    {entity.order}
                  </h5>
                </Col>
              </Row>
            </Col>
            <Col xs="6" md="2">
              <div className="report-item-delete-button" onClick={deleteElement}>
                <IconSvg icon="trash-can" fill="#fff" />
              </div>
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>{CreateHtmlElement(entity?.monoSpaceHtml)}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
