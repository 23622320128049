import React from "react";
import { Col, Row } from "react-bootstrap";

export default function DualText({ item }) {
  return item.scale.ranges.map((range, i) => {
    if (range.isHit)
      return (
        <Row className="mb-3">
          <Col
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "20px",
            }}
            xs="6"
          >
            {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: range.text }} /> : range.text}
          </Col>
          <Col
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "20px",
            }}
            xs="6"
          >
            {item.scale.isHtml ? <div dangerouslySetInnerHTML={{ __html: range.rightText }} /> : range.rightText}
          </Col>
        </Row>
      );
  });
}
