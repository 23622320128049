import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import WysiwygEditor from "../../../../UI/WysiwygEditor";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateReportSchemeState } from "../Store";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../Helpers/ApiHelper";

export default function SchemeElementModal({ schemeElementModalData, onHide, UpdateScheme }) {
  const dispatch = useDispatch();
  const entityList = useSelector((state) => state.reportScheme.reportItemEntityList);
  const [leftEditorState, setLeftEditorState] = useState("");
  const [rightEditorState, setRightEditorState] = useState("");
  const [spaceAmount, setSpaceAmount] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [previevLogoSrc, setPrevievLogoSrc] = useState(null);
  const [imageFile, setImageFile] = useState("");

  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const AddElementToEntityList = async () => {
    const intOrderNumber = parseInt(orderNumber);
    let newList = JSON.parse(JSON.stringify(entityList));
    if (intOrderNumber <= 0 || intOrderNumber > newList.length + 1) return setOrderNumber("");
    const obj = { order: intOrderNumber, htmlEl: true };
    if (schemeElementModalData.type === "image") {
      obj.imageName = await SaveImage(imageFile);
      obj.componentName = "ImageSpace";
    } else if (schemeElementModalData.type === "mono") {
      obj.monoSpaceHtml = leftEditorState;
      obj.componentName = "MonoSpace";
    } else if (schemeElementModalData.type === "dual") {
      obj.leftSpaceHtml = leftEditorState;
      obj.rightSpaceHtml = rightEditorState;
      obj.componentName = "DualSpace";
    } else if (schemeElementModalData.type === "space") {
      obj.emptySpaceHeightPx = spaceAmount;
      obj.componentName = "EmptySpace";
    }

    newList.splice(intOrderNumber - 1, 0, obj);
    newList.forEach((entity, i) => {
      entity.order = i + 1;
    });
    dispatch(updateReportSchemeState({ reportItemEntityList: newList }));
    setOrderNumber("");
    CloseModal();
    UpdateScheme(newList);
  };

  const CloseModal = () => {
    setPrevievLogoSrc("");
    setImageFile("");
    setOrderNumber("");
    onHide();
  };

  const ReadUploadFile = (e) => {
    setImageFile(e.target.files[0]);
    setPrevievLogoSrc(URL.createObjectURL(e.target.files[0]));
  };

  const HandleReturnAjaxCall = (val) => {
    let res = null;
    if (val.response) res = JSON.parse(val.response);
    if (res.statusCode == 1) return res.data;
    else alert("hata olustu sayfa yenile");
  };

  let SaveImage = async () => {
    // setShowOverlay(true);

    const formData = new FormData();

    formData.append("image", imageFile);
    return new Promise(function (resolve, reject) {
      ApiHelper.xmlPostFormData("report/SaveImage", formData, user.token, resolve);
    }).then((val) => {
      return HandleReturnAjaxCall(val);
    });
  };

  return (
    <Modal fullscreen={true} centered show={schemeElementModalData.show} onHide={CloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{schemeElementModalData.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-5">
            <Col xs="3">
              <h4>Sıra : </h4>
            </Col>
            <Col xs="2">
              <Form.Control
                type="number"
                placeholder="Sıra numara giriniz"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </Col>
          </Row>
          {schemeElementModalData.type === "space" && (
            <Row className="mb-5">
              <Col xs="3">
                <h4>Boşluk Miktarı (px) </h4>
              </Col>
              <Col xs="2">
                <Form.Control
                  type="number"
                  value={spaceAmount}
                  onChange={(e) => setSpaceAmount(e.target.value)}
                  placeholder="Boşluk Miktarı giriniz"
                />
              </Col>
            </Row>
          )}
          {schemeElementModalData.type === "image" && (
            <Row className="justify-content-center">
              <Col xs="12" md="6">
                <label className="admin-setting-page-add-image-label">
                  <input
                    id="admin-excel-input"
                    type="file"
                    onChange={ReadUploadFile}
                    accept=".jpeg, .png, .webp, .jpg"
                  />
                  <IconSvg icon="add-image" />

                  <span> Görsel Yükle </span>
                </label>
                <div className="logo-previev-img-container">
                  <img src={previevLogoSrc} />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            {schemeElementModalData.type === "dual" || schemeElementModalData.type === "mono" ? (
              <Col>
                <h4>{schemeElementModalData.type === "dual" ? "Sol Taraf" : "Tek Bölüm"}</h4>
                <WysiwygEditor onChange={(html) => setLeftEditorState(html)} />
              </Col>
            ) : null}
            {schemeElementModalData.type === "dual" && (
              <Col>
                <h4>Sağ Taraf</h4>
                <WysiwygEditor onChange={(html) => setRightEditorState(html)} />
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!orderNumber} onClick={AddElementToEntityList}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
