import React, { useState } from "react";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { PdfFile } from "./PdfFile";
import { Overlay } from "../../../../Helpers/Overlay";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import IconSvg from "../../../../App/AppMenu/IconSvg";

export function PdfDownloadButton(props) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [pdfComponent, setPdfComponent] = useState(null);
  let candidateName = null;

  let DownloadActualPdfFile = (id, scalePageIds, isTest) => {
    setIsOverlayVisible(true);
    printDocument(id, scalePageIds, isTest);
  };

  let HandleCreatingPdfFile = (data, result, isTest) => {
    setPdfComponent(
      <PdfFile
        id={result.id}
        StartDownloadPdf={DownloadActualPdfFile}
        data={data}
        result={result}
        isTest={isTest}
        demography={props.demography}
      />
    );
  };

  let HandlePdfExport = (result) => {
    candidateName = result.name;
    const postObj = CreatePostObj(result);
    ApiHelper.postAsync(
      postObj.isTest ? "user/GetTestPdfImage" : "user/GetInventoryPdfImage",
      postObj,
      function (response) {
        HandleCreatingPdfFile(response.data.data, result, postObj.isTest);
      },
      null
    );
  };

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  let printDocument = async (id, scalePageIds, isTest) => {
    if (window.pdfId === id) return;
    else window.pdfId = id;
    await delay(1000);

    window.htmlEls = [];
    const entry = document.getElementById("entry" + id);
    entry && window.htmlEls.push(entry);
    const info1 = document.getElementById("info" + id + "1");
    info1 && window.htmlEls.push(info1);

    if (isTest) {
      let testPdf = document.getElementById("test" + id);
      window.htmlEls.push(testPdf);
    } else {
      const summary = document.getElementById("summary" + id);
      window.htmlEls.push(summary);
    }

    scalePageIds = [...new Set(scalePageIds)];
    scalePageIds.forEach((pageId) => {
      const scalePage = document.getElementById(pageId);
      window.htmlEls.push(scalePage);
    });
    window.pages = [];

    for (let i = 0; i < window.htmlEls.length; i++) {
      await html2canvas(window.htmlEls[i], {
        scale: 1.5,
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        let imgWidth = 211;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let imgData = canvas.toDataURL("img/png");
        window.pages.push({ imgData, imgHeight });
      });
    }

    SaveAsPdf(window.pages);
    setIsOverlayVisible(false);
  };

  let SaveAsPdf = (pages) => {
    const pdf = new jsPDF("p", "mm", "a4", true);
    pages.forEach((page, i) => {
      pdf.addImage(page.imgData, "PNG", 0, 0, 211, page.imgHeight);
      if (i + 1 < pages.length) {
        pdf.addPage();
      }
    });

    pdf.save(candidateName);

    window.htmlEls = [];
    window.pages = [];
    window.pdfId = null;
    setPdfComponent(null);
  };

  return (
    <>
      {isOverlayVisible && <Overlay text="Pdf Dosyası Hazırlanıyor" />}
      <Button
        className={props.resultPage && "pdf-download-button"}
        variant={props.resultPage ? "warning" : props.isActive ? "outline-success" : "outline-danger"}
        size={!props.resultPage && "sm"}
        disabled={!props.isActive}
        onClick={() => HandlePdfExport(props.result)}
      >
        {props.resultPage ? "Pdf Raporu" : "Pdf"}
        <span style={{ paddingLeft: "5px" }}>
          <IconSvg icon="download" />
        </span>
      </Button>
      <div style={{ position: "absolute", left: "-9999px" }}>{pdfComponent}</div>
    </>
  );
}

let HandleGroupingScopes = (polarLikertAns, adjAns) => {
  let scope = {};
  let subScope = {};
  let concatedArr = null;
  if (polarLikertAns && adjAns) {
    concatedArr = [...polarLikertAns, ...adjAns];
  } else if (polarLikertAns) {
    concatedArr = polarLikertAns;
  } else if (adjAns) {
    concatedArr = adjAns;
  }
  concatedArr.forEach((item) => {
    if (item.scopeId) {
      if (scope[item.scopeId]) {
        scope[item.scopeId].push(item);
      } else {
        scope[item.scopeId] = [];
        scope[item.scopeId].push(item);
      }
    } else if (item.subScopeId) {
      if (subScope[item.subScopeId]) {
        subScope[item.subScopeId].push(item);
      } else {
        subScope[item.subScopeId] = [];
        subScope[item.subScopeId].push(item);
      }
    }
  });
  let isTest = false;
  if (polarLikertAns && polarLikertAns[0].options) {
    isTest = true;
  }

  return {
    scope,
    subScope,
    isTest,
  };
};

let HandleGroupingQuestions = (data, isSub) => {
  let orderedScopes = [];
  for (const key of Object.keys(data)) {
    let sortedScope = {
      [isSub ? "subScopeId" : "scopeId"]: key,
      likertQuestions: [],
      polaredQuestions: [],
      adjQuestions: [],
    };
    data[key].forEach((question) => {
      if (isSub ? question.subLikertId : question.likertId) {
        sortedScope.likertQuestions.push({
          answer: question.answer,
          questionId: question.questionId,
          likertId: isSub ? question.subLikertId : question.likertId,
        });
      } else if (isSub ? question.subPolaredId : question.polaredId) {
        sortedScope.polaredQuestions.push({
          answer: question.answer,
          questionId: question.questionId,
          polaredId: isSub ? question.subPolaredId : question.polaredId,
        });
      } else if (isSub ? question.subAdjectivesId : question.adjectivesId) {
        sortedScope.adjQuestions.push({
          isChecked: question.checked,
          questionId: isSub ? question.subAdjectivesId : question.adjectivesId, // burda karisiklik olmus
          adjId: question.id,
        });
      }
    });
    orderedScopes.push(sortedScope);
  }
  return orderedScopes;
};

export const CreatePostObj = (result) => {
  let groupedScopes = HandleGroupingScopes(
    JSON.parse(result.questionAnswersJson),
    JSON.parse(result.checkboxAnswersJson)
  );

  return {
    isTest: groupedScopes.isTest,
    scopes: HandleGroupingQuestions(groupedScopes.scope),
    subScopes: HandleGroupingQuestions(groupedScopes.subScope, true),
    result: result,
  };
};
