import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../Services/ApiHelper";
import DualTextuaryAPI from "../API/DualTextuaryAPI";
import { DualTextuaryApiUrls } from "../API/DualTextuaryAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const dualTextuaryAPI = new DualTextuaryAPI(apiHelper);

export const createDualTextuary = createAsyncThunk(
  DualTextuaryApiUrls.createDualTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await dualTextuaryAPI.createDualTextuary(payload, token);
    dispatch(
      getDualTextuaries(
        {
          id: state.dualTextuary.scopeId,
          isSub: state.dualTextuary.isSub,
        },
        token
      )
    );
  }
);

export const getDualTextuaries = createAsyncThunk(
  DualTextuaryApiUrls.getDualTextuaries,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    let data = await dualTextuaryAPI.getDualTextuaries(payload, token);
    return data;
  }
);

export const deleteDualTextuary = createAsyncThunk(
  DualTextuaryApiUrls.deleteDualTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await dualTextuaryAPI.deleteDualTextuary(payload, token);
    dispatch(
      getDualTextuaries(
        {
          id: state.dualTextuary.scopeId,
          isSub: state.dualTextuary.isSub,
        },
        token
      )
    );
  }
);

export const updateDualTextuary = createAsyncThunk(
  dualTextuaryAPI.updateDualTextuary,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await dualTextuaryAPI.updateDualTextuary(payload, token);
    dispatch(
      getDualTextuaries(
        {
          id: state.dualTextuary.scopeId,
          isSub: state.dualTextuary.isSub,
        },
        token
      )
    );
  }
);
