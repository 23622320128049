import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { UpdateLeanModalState } from "../../../Store";

export default function LeanModalButton({ inventory }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openModal = () => dispatch(UpdateLeanModalState({ isVisible: true, inventory }));

  return (
    <Button
      //todo
      // disabled={!application.testImage}
      size="sm"
      variant="primary"
      onClick={openModal}
    >
      {t("orientation")}
    </Button>
  );
}
