import React from "react";

export default function EmptySpace({ item }) {
  return (
    <div
      style={{
        height: item.emptySpaceHeightPx + "mm",
        width: "100%",
        clear: "both",
      }}
    ></div>
  );
}
