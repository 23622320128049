import React, { useState, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import Rows from "./Rows";
import { useSelector } from "react-redux";

export function CreditTable(props) {
  const itemsPerPage = 12;
  const creditsData = useSelector((state) => state.credit);
  const { filteredCredits, admins, tests } = creditsData;
  const [itemOffset, setItemOffset] = useState(0);
  const preparedItems = useMemo(
    () =>
      filteredCredits.map((credit) => ({
        id: credit.id,
        labelAdmin: admins.find((admin) => admin.id === credit.adminUserId)?.email,
        labelTest: tests.find((test) => test.id === credit.testId)?.name,
        creditAmount: credit.creditAmount,
      })),
    [filteredCredits, admins, tests]
  );

  const currentItems = useMemo(() => {
    const endOffset = itemOffset + itemsPerPage;
    return preparedItems.slice(itemOffset, endOffset);
  }, [itemOffset, preparedItems]);

  const pageCount = Math.ceil(preparedItems.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % preparedItems.length;
    setItemOffset(newOffset);
  };
  return (
    <Card body style={{ background: "#2c3034", color: "white" }} className="paginated-credits">
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>
              Admin
              <input
                type="text"
                placeholder="Ara"
                style={{ marginLeft: "10px" }}
                onChange={(e) => props.HandleMailSearch(e.target.value)}
              />
            </th>
            <th>Envanter</th>
            <th>Kalan Kredi</th>
          </tr>
        </thead>
        <tbody>
          <Rows items={currentItems} />
        </tbody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="sonraki >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< önceki"
        renderOnZeroPageCount={null}
      />
    </Card>
  );
}
