import React from "react";
import Image from "react-bootstrap/Image";
import ApiHelper from "../../../../../Helpers/ApiHelper";

export default function InfoSection(props) {
  let style = {
    top: "1mm",
    margin: 0,
    padding: 0,
  };

  let background1 = {
    position: "relative",
    height: "296mm",
    left: 0,
    top: 0,
    width: "210mm",
    zIndex: "-100",
  };

  return (
    <div style={style}>
      <Image
        id={"info" + props.id + "1"}
        style={background1}
        className="pdf-background"
        crossOrigin="anonymous"
        // src={require("./../../../../../Images/info.jpeg")}
        src={ApiHelper.baseURL + "wwwroot/info/" + props.infoImage}
      />
    </div>
  );
}
