import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import DeleteApplication from "../ActionsButton/DeleteApplication";
import Navigate from "../ActionsButton/Navigate";
import OpenNewPage from "../ActionsButton/OpenNewPage";
import OpenEntityModal from "../ActionsButton/OpenEntityModal";
import OpenInfoModal from "../ActionsButton/OpenInfoModal";
import DownloadBackUp from "../ActionsButton/DownloadBackUp";
import { useTranslation } from "react-i18next";

const ApplicationActions = React.memo(({ application }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col xs="6" md="6">
        <Row xs="auto" className="justify-content-center">
          <Col>
            <Navigate application={application} path="/scope">
              {t("dimensions")}
            </Navigate>
          </Col>
          <Col>
            <Navigate application={application} path="/reorder">
              {t("sortBy")}
            </Navigate>
          </Col>
          <Col>
            <OpenInfoModal application={application} />
          </Col>
          <Col>
            <Navigate application={application} path="/scheme">
              {t("scheme")}
            </Navigate>
          </Col>
          <Col>
            <OpenNewPage application={application} />
          </Col>
        </Row>
      </Col>
      <Col xs="6" md="2">
        <Row xs="auto" className="justify-content-md-end">
          {/* <DownloadBackUp application={application} /> */}
          <Col>
            <OpenEntityModal application={application} />
          </Col>
          <Col>
            <DeleteApplication application={application} />
          </Col>
        </Row>
      </Col>
    </>
  );
});

export default ApplicationActions;
