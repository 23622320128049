import { createSlice } from "@reduxjs/toolkit";
import { createDualTextuary, getDualTextuaries, deleteDualTextuary, updateDualTextuary } from "./Thunk";

export const dualTextuary = createSlice({
  name: "dualTextuary",
  initialState: {
    scopeId: 0,
    id: 0,
    isSub: false,
    isLoading: false,
    dualTextuaryEntities: [],
  },
  reducers: {
    updateDualTextuaryState(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDualTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createDualTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dualTextuaryEntities = action.payload;
    });
    builder.addCase(getDualTextuaries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDualTextuaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dualTextuaryEntities = action.payload;
    });
    builder.addCase(deleteDualTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDualTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dualTextuaryEntities = action.payload;
    });
    builder.addCase(updateDualTextuary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateDualTextuary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.id = 0;
    });
  },
});

export const { updateDualTextuaryState } = dualTextuary.actions;

export default dualTextuary.reducer;
