import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import validator from "validator";
import ApiHelper from "./../../Helpers/ApiHelper";
import IconSvg from "../../App/AppMenu/IconSvg";
import { Overlay } from "../../Helpers/Overlay";
import { useTranslation } from "react-i18next";

const PWD_Regex = /^(?=.*[a-z])(?=.*[^a-zA-Z\d\s:])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;

export function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validation, setValidation] = useState({
    mail: null,
    password: null,
    name: null,
    confirmPassword: null,
  });

  const [modal, setModalShow] = useState({
    show: false,
  });

  let errorStrings = {
    mail: t("invalidEmail"),
    password: t("passwordCheckRules"),
    name: t("requiredField"),
    confirmPassword: t("passwordsDoNotMatch"),
  };

  let checkValidationAndSet = (value, inputType) => {
    if (inputType === "mail") {
      setState((state) => ({ ...state, email: value }));
      if (!validator.isEmail(value)) {
        setValidation((validation) => ({ ...validation, mail: true }));
      } else {
        setValidation((validation) => ({ ...validation, mail: false }));
      }
    } else if (inputType === "password") {
      setState((state) => ({ ...state, password: value }));

      if (value === "" || !PWD_Regex.test(value)) {
        setValidation((validation) => ({ ...validation, password: true }));
      } else {
        setValidation((validation) => ({ ...validation, password: false }));
      }
    } else if (inputType === "confirmPassword") {
      setState((state) => ({ ...state, confirmPassword: value }));
      if (value !== state.password) {
        setValidation((validation) => ({
          ...validation,
          confirmPassword: true,
        }));
      } else {
        setValidation((validation) => ({
          ...validation,
          confirmPassword: false,
        }));
      }
    } else if (inputType === "name") {
      setState((state) => ({ ...state, username: value }));

      if (value === "") {
        setValidation((validation) => ({ ...validation, name: true }));
      } else {
        setValidation((validation) => ({ ...validation, name: false }));
      }
    }
  };

  let onSubmit = (event) => {
    event.preventDefault();
    setShowOverlay(true);
    ApiHelper.postAsync("user/register", state, function (result) {
      setShowOverlay(false);
      if (result.status !== 200) {
        //todo : apihelperdan donen istekleri statuslerine gore handle et
        setModalShow(() => ({
          show: true,
          text: result.status,
          heading: "Hata",
          success: false,
        }));
      } else if (result && result.data.statusCode) {
        setModalShow(() => ({
          show: true,
          text: t("registerSuccessMessage"),
          heading: t("registerSuccess"),
          success: true,
        }));
      } else if (result && !result.data.statusCode) {
        setModalShow(() => ({
          show: true,
          text: result.data.data,
          heading: "Hata",
          success: false,
        }));
      }
    });
  };

  let modalClose = () => {
    if (modal.success) navigate("/login");
    else setModalShow(false);
  };

  let logoHeightWidth = window.innerWidth <= 500 ? "100px" : "200px";
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Container className={"login-page-container"}>
      {showOverlay && <Overlay text={t("registering")} />}
      <Modal centered show={modal.show} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modal.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.text}</Modal.Body>
        <Modal.Footer className={"justify-content-center"}>
          <Button style={{ width: "90%" }} variant="primary" onClick={modalClose}>
            {modal.success === true ? t("login") : t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="justify-content-center">
        <Col xs="12" md="5">
          <div>
            <div className="login-page-logo-container">
              <IconSvg icon="cp-logo" height={logoHeightWidth} width={logoHeightWidth} />
            </div>
            <h1 className="login-page-welcome-header">{t("register")}</h1>
            <h5 className="mt-3 login-page-info-h5">{t("pleaseFillTheFormBelowToRegister")}</h5>
          </div>

          <div className="mb-3">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>{t("emailLabel")}</h6>
                <Form.Control
                  type="email"
                  placeholder={t("emailInputPlaceholder")}
                  onChange={(e) => checkValidationAndSet(e.target.value, "mail")}
                  isInvalid={validation.mail}
                />
                <Form.Control.Feedback type="invalid">{errorStrings.mail}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicName">
                <h6>İsim</h6>
                <Form.Control
                  type="text"
                  placeholder="İsminizi giriniz"
                  onChange={(e) => checkValidationAndSet(e.target.value, "name")}
                  isInvalid={validation.name}
                />
                <Form.Control.Feedback type="invalid">{errorStrings.name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>{t("Password")}</h6>
                <Form.Control
                  type="password"
                  placeholder={t("passwordPlaceholderMessage")}
                  onChange={(e) => checkValidationAndSet(e.target.value, "password")}
                  isInvalid={validation.password}
                />
                <Form.Control.Feedback type="invalid">{errorStrings.password}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                <h6>Şifre tekrarı</h6>
                <Form.Control
                  type="password"
                  placeholder="Şifrenizi tekrar giriniz"
                  onChange={(e) => checkValidationAndSet(e.target.value, "confirmPassword")}
                  isInvalid={validation.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">{errorStrings.password}</Form.Control.Feedback>
              </Form.Group>

              <Row className="justify-content-center mt-3">
                <Col xs="12" md="6">
                  <Button
                    style={{ width: "100%", height: "50px" }}
                    className={"mt-4 mb-4"}
                    variant="primary"
                    type="submit"
                    disabled={
                      validation.mail === false &&
                      validation.name === false &&
                      validation.password === false &&
                      validation.confirmPassword === false
                        ? false
                        : true
                    }
                  >
                    {t("Register")}
                  </Button>
                  <div className="login-page-logo-container">
                    <p className="forgot-password-button" onClick={() => navigate("/login")}>
                      {t("Login")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
