import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Badge from "react-bootstrap/Badge";

export default function RowInfo({ inventory }) {
  const { t } = useTranslation();
  const calculateSubScopeNumber = (scope) => scope.reduce((acc, current) => acc + current.subScopes.length, 0);

  const calculateQuestionNumber = (scope) =>
    scope.reduce((acc, s) => {
      const subScopeQuestions = s.subScopes.reduce((accSub, ss) => accSub + ss.questions.length, 0);
      return acc + s.questions.length + subScopeQuestions;
    }, 0);

  return (
    <Col xs="4" md="4">
      <Row xs="auto">
        <Col>{inventory.id}</Col>
        <Col>{inventory.name}</Col>
        <Col>
          {inventory.scopes.length} {t("scope")}
        </Col>
        <Col>-{t("subScope")}</Col>
        <Col>-{t("question")}</Col>
      </Row>
      <Row>
        {inventory.assessments &&
          inventory.assessments.map((assessment, m) => (
            <Col key={"te" + m} xs="auto">
              <Badge bg={assessment.isB2b ? "success" : "secondary"}>{assessment.name}</Badge>
            </Col>
          ))}
      </Row>
    </Col>
  );
}
