import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import validator from "validator";
import { FormCheck, Modal } from "react-bootstrap";
import IconSvg from "../../../../../../App/AppMenu/IconSvg";
import { useTranslation } from "react-i18next";
const xlsx = require("xlsx");

export default function TargetMailManagement(props) {
  const { t } = useTranslation();
  const [mailinput, setMailInput] = useState({
    mail: "",
    isValid: false,
    cssClass: null,
  });

  const [excelFileInput, setExcelFileInput] = useState("");

  const [invalidMailModal, setInvalidMailModal] = useState({
    show: false,
    data: [],
  });

  const HandleSettingMail = (mail) => {
    let isValid = validator.isEmail(mail);
    let cssClass = HandleEmailInputCssClass(mail, isValid);
    setMailInput({ mail, isValid, cssClass });
  };

  const HandleKeyDown = (event) => {
    if (event.keyCode === 13 && mailinput.isValid) {
      props.setPostData((state) => ({
        ...state,
        mailList: [...new Set([...props.postData.mailList, mailinput.mail])],
      }));
      setMailInput({
        mail: "",
        isValid: false,
        cssClass: null,
      });
    }
  };

  const RemoveFromMailList = (mail) => {
    const newArr = props.postData.mailList.filter((m) => m !== mail);
    props.setPostData((state) => ({
      ...state,
      mailList: newArr,
    }));
  };

  const HandleEmailInputCssClass = (mail, isValid) => {
    if (mail.length === 0) return null;
    if (mail?.length > 0 && isValid) return "valid-mail-input ";
    return "invalid-mail-input ";
  };

  const ReadUploadFile = (e) => {
    setExcelFileInput(e.target.value);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        HandleValidateExcelAndAddMailList(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const HandleValidateExcelAndAddMailList = (data) => {
    let invalid = [];
    let valid = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      if (validator.isEmail(row.mail)) valid.push(row.mail);
      else invalid.push(row);
    }

    if (invalid.length > 0) {
      setInvalidMailModal({ show: true, data: invalid });
    }

    if (valid.length > 0)
      props.setPostData((state) => ({
        ...state,
        mailList: [...new Set([...props.postData.mailList, ...valid])],
      }));
  };

  const HandleCleanMailList = () => {
    excelFileInput !== "" && setExcelFileInput("");
    props.setPostData((state) => ({
      ...state,
      mailList: [],
    }));
  };

  const HandleChangeAllowPdfCheck = () => {
    props.setPostData((state) => ({
      ...state,
      allowPdf: !props.postData.allowPdf,
    }));
  };

  const HandleChangeOpenCamCheck = () => {
    props.setPostData((state) => ({
      ...state,
      openCam: !props.postData.openCam,
    }));
  };

  return (
    <>
      <Row>
        <Col xs={6}>
          <h6 className="project-page-input-label ">{t("candidateEmailAddress")}</h6>
        </Col>
        <Col xs={6}>
          {excelFileInput !== 0 ? (
            <h6 onClick={HandleCleanMailList} className="clean-mail-list-button">
              <span>{t("deleteAll")}</span> <IconSvg icon="trash-can" />
            </h6>
          ) : (
            <label className="project-page-excel-button-label">
              <input
                value={excelFileInput}
                id="admin-excel-input"
                type="file"
                onChange={ReadUploadFile}
                accept=".xlsx, .xls, .ods"
              />
              <span> {t("chooseAnExcelFile")} </span>
              <IconSvg icon="excel" />
            </label>
          )}
        </Col>
      </Row>
      <Form.Control
        type="email"
        value={mailinput.mail}
        className={mailinput.cssClass}
        onChange={(e) => HandleSettingMail(e.target.value)}
        onKeyDown={HandleKeyDown}
      />

      <div className="send-ticket-mail-list-container">
        {props.postData.mailList.map((mail, i) => (
          <div className="candidate-mail" key={i + "mail"}>
            <span style={{ paddingRight: "3px" }}> {i + 1 + " ) "} </span>
            {mail}
            <span style={{ paddingLeft: "10px", marginTop: "-2px" }} onClick={() => RemoveFromMailList(mail)}>
              <IconSvg icon="delete-x" />
            </span>
          </div>
        ))}
      </div>

      <div className="send-ticket-controllers-container ">
        <Row>
          <Col xs="6" className="mb-3" style={{ display: "inline-flex" }}>
            <FormCheck value={props.postData.allowPdf} onChange={HandleChangeAllowPdfCheck} />
            <span className="send-ticket-mail-checkbox-label">{t("accessToResultsPdf")}</span>
          </Col>
          <Col xs="6" className="mb-3" style={{ display: "inline-flex" }}>
            <FormCheck value={props.postData.openCam} onChange={HandleChangeOpenCamCheck} />
            <span className="send-ticket-mail-checkbox-label">{t("webcamRequirement")}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              style={{ width: "100%" }}
              disabled={props.postData.mailList.length > 0 && props.postData.applications.length > 0 ? false : true}
              onClick={() => props.setShowSendingConfirmModal(true)}
            >
              {t("send")}
            </Button>
          </Col>
        </Row>
      </div>

      <Modal show={invalidMailModal.show} onHide={() => setInvalidMailModal({ show: false, data: [] })}>
        <Modal.Header closeButton>
          <Modal.Title>{t("invalidEmails")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "30vh", overflow: "auto" }}>
          {invalidMailModal.data.map((mailRow) => (
            <h6> {"Satır" + mailRow.__rowNum__ + ")" + mailRow.mail} </h6>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setInvalidMailModal({ show: false, data: [] })}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
