import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

export const designTypes = {
  Detail_1: 0,
  DualText: 1,
  MonoText: 2,
  Summary_1: 3,
  Summary_LoadingBar: 4,
  Detail_LoadingBar: 5,
  Detail_2: 6,
  MonoText_2: 7,
};

export default function DesignTypeDropdown(props) {
  const { t } = useTranslation();
  var dropdownItems = [];

  Object.entries(designTypes).forEach(([key, value]) => {
    if (key.includes(props.keyword))
      dropdownItems.push(
        <Dropdown.Item key={value} eventKey={value}>
          {key}
        </Dropdown.Item>
      );
  });

  const GetKeyByValue = (value) => {
    return Object.keys(designTypes).find((key) => designTypes[key] === value);
  };

  const HandleDropDownSelect = (val) => {
    props.designTypeDropDownOnChange(val);
  };
  const buttonString = GetKeyByValue(parseInt(props.selectedDesignType));

  return (
    <Dropdown onSelect={HandleDropDownSelect}>
      <Dropdown.Toggle variant="success" style={{ width: "100%" }}>
        {buttonString ? buttonString : t("DesignTypeButton")}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "100%" }}>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
}
