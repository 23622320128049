import { createSlice } from "@reduxjs/toolkit";
import { login } from "./AuthThunk";

export const Auth = createSlice({
  name: "Auth",
  initialState: {
    credentials: {
      email: "",
      password: "",
    },
    user: {},
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const user = (state) => state.auth.user;
export const token = (state) => state.state.auth.user.token;

export const { setUser } = Auth.actions;

export default Auth.reducer;
