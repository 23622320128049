import React from "react";
import { Alert, Card, Col, Image, Row } from "react-bootstrap";
import ApiHelper from "../../../../../../Helpers/ApiHelper";

export default function TestQuestionsList(props) {
  const HandleQuestionImageFile = (fileName) => {
    if (fileName.endsWith(".mp3"))
      return (
        <audio controls name="media">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="audio/mpeg" />
        </audio>
      );
    else if (fileName.endsWith(".webm"))
      return (
        <video controls name="media" width="130" height="130">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/webm" />
        </video>
      );
    else if (fileName.endsWith(".mp4"))
      return (
        <video controls name="media" width="130" height="130">
          <source src={ApiHelper.baseURL + "wwwroot/question/" + fileName} type="video/mp4" />
        </video>
      );
    return <Image src={ApiHelper.baseURL + "wwwroot/question/" + fileName} width="130" height="130" />;
  };

  return (
    <>
      {props.ScopeQuestions?.data?.length > 0 &&
        props.ScopeQuestions.data.map((sq, i) => {
          return (
            <Card key={i} className="mb-3">
              <Card.Body>
                <span
                  style={{
                    position: "absolute",
                    top: "2px",
                    left: "12px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  K = {sq.likert.factor}
                </span>
                <Row style={{ paddingTop: "5px" }}>
                  <Col>
                    {sq.likert.imagePath ? HandleQuestionImageFile(sq.likert.imagePath) : sq.likert.questionText}
                  </Col>
                  {sq.likert.answers.options.map((opt, i) => {
                    return (
                      <Col
                        key={"i" + i}
                        style={
                          opt.isCorrect
                            ? {
                                border: "2px solid green",
                                borderRadius: "5px",
                                padding: "5px",
                                background: "aquamarine",
                                margin: "10px",
                              }
                            : {
                                background: "gainsboro",
                                padding: "5px",
                                margin: "10px",
                                borderRadius: "5px",
                              }
                        }
                      >
                        {opt.option ? (
                          opt.option
                        ) : (
                          <Image src={ApiHelper.baseURL + "wwwroot/question/" + opt.path} width="100" height="100" />
                        )}
                      </Col>
                    );
                  })}
                </Row>
                <Alert.Link
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "10px",
                    color: "red",
                  }}
                  variant="danger"
                  onClick={() => props.DeleteScopeQuestion(sq)}
                >
                  Sil
                </Alert.Link>
              </Card.Body>
            </Card>
          );
        })}
    </>
  );
}
