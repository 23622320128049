import B2BRowDTO from "../DTO/B2BRowDTO";
import B2CRowDTO from "../DTO/B2CRowDTO";

function parseAnswers(ticket, isB2b, answerType) {
  const answerPath = isB2b ? ticket.ticketResult[answerType] : ticket[answerType];
  return answerPath && JSON.parse(answerPath);
}

function combineAnswers(questionAnswersJson, checkboxAnswersJson) {
  if (questionAnswersJson && checkboxAnswersJson) {
    return checkboxAnswersJson.concat(questionAnswersJson);
  }
  return questionAnswersJson || checkboxAnswersJson || [];
}

function addAnswerToObject(obj, question) {
  let questionStr = question.leftPolar
    ? `${question.leftPolar}/${question.rightPolar}(P)`
    : question.text
      ? `${question.text}(L)`
      : `${question.questionText}(C)`;

  if (question.answer !== undefined) {
    obj[questionStr] = question.answer;
  } else if (question.checked !== undefined) {
    obj[question.questionText] = question.checked ? 1 : 0;
  }
}

export default function refactorInventoryRow(ticket, isB2b) {
  const questionAnswersJson = parseAnswers(ticket, isB2b, "questionAnswersJson");
  const checkboxAnswersJson = parseAnswers(ticket, isB2b, "checkboxAnswersJson");
  const processedAnswers = combineAnswers(questionAnswersJson, checkboxAnswersJson);
  const rowObject = isB2b ? new B2BRowDTO(ticket) : new B2CRowDTO(ticket);

  processedAnswers.forEach((question) => addAnswerToObject(rowObject, question));

  return rowObject;
}
