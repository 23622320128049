import React, { useEffect } from "react";

import { getTests } from "../Store/Thunk";
import { updateIsTest } from "../Store";
import LeanModal from "./Modal/LeanModal";
import EntityModal from "./Modal/EntityModal";
import ApplicationItem from "./ApplicationItem/ApplicationItem";

import ApplicationInfoModal from "./Modal/ApplicationInfoModal";
import { useDispatch, useSelector } from "react-redux";
import "../Styles/index.css";
import { Overlay } from "../../../../Helpers/Overlay";

export default function EntityList({ isTest }) {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.entityList.tests);
  const isShowInfoModal = useSelector((state) => state.entityList.infoModal.isShow);

  const isLoading = useSelector((state) => state.entityList.isLoading);

  useEffect(() => {
    dispatch(updateIsTest(isTest));
    dispatch(getTests());
  }, []);

  return (
    <>
      {isLoading && <Overlay />}
      {tests?.data?.length > 0 &&
        tests.data.map((application, i) => {
          return <ApplicationItem application={application} key={i} />;
        })}

      <EntityModal isTest={isTest} />
      <LeanModal />

      {isShowInfoModal && <ApplicationInfoModal />}
    </>
  );
}
