import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PolarAnswer } from "./PolarAnswer";
import "animate.css";
import TextSection from "../../SharedComponents/TextSection";

export function PolarImage(props) {
  const [active, setActive] = useState(null);
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  let HandleAnswering = async (a, id) => {
    setActive(a);
    document.getElementById(id)?.classList?.add("animate__flash");
    await timeout(400);
    document.getElementById(id)?.classList?.remove("animate__flash");
    props.SetAnswer(props.q.order, a, props.n + 1, "polar");
  };
  return (
    <>
      <TextSection
        text="Hangi taraftaki ifade sizi daha iyi tanımlıyorsa o taraftaki seçeneklerden birini seçiniz (1- Biraz tanımlıyor, 2- Oldukça tanımlıyor, 3- Tamamen tanımlıyor) "
        class="polar-image-info-text"
      />
      <div
        style={{ marginTop: window.innerWidth <= 500 ? "180px" : "250px" }}
        className="animate__animated  animate__fadeInDown animate__faster"
      >
        <Row style={{ margin: 0, padding: 0 }}>
          <Col className="polar-question-text-col" xs={{ span: 6, order: 1 }} md={{ span: 4, order: 1 }}>
            {window.innerWidth <= 500 && <br></br>}
            <h4
              className="polar-question-text"
              style={{
                fontSize: window.innerWidth >= 500 ? "20px" : "14px",
                marginLeft: window.innerWidth >= 500 ? "40px" : "20px",
              }}
            >
              {props.q.leftPolar}
            </h4>
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 4, order: 2 }}
            style={{
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "inline-flex",
              marginTop: window.innerWidth < 500 ? "40px" : "0px",
            }}
          >
            <PolarAnswer
              label={3}
              value={1}
              tooltipContent={"Tamamen tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              btnClassName="polar-btn-big"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path
                d="M6 1L1.89319 5.10681C1.39989 5.60011 1.39989 6.39989 1.89319 6.89319L6 11"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <PolarAnswer
              label={2}
              value={2}
              tooltipContent={"Oldukça tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              btnClassName="polar-btn-mid"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path
                d="M6 1L1.89319 5.10681C1.39989 5.60011 1.39989 6.39989 1.89319 6.89319L6 11"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <PolarAnswer
              label={1}
              value={3}
              tooltipContent={"Biraz tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              btnClassName="polar-btn-small"
            />
            <span
              style={{
                height: window.innerWidth < 500 ? "400%" : "100%",
                marginTop: window.innerWidth < 500 ? "-150px" : "0px",
                width: "4px",
                borderRadius: "2px",
                backgroundColor: "#E8E8E8",
              }}
            ></span>
            <PolarAnswer
              label={1}
              value={4}
              tooltipContent={"Biraz tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              float="right"
              btnClassName="polar-btn-small"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path
                d="M1 11L5.10681 6.89319C5.6001 6.39989 5.60011 5.60011 5.10681 5.10681L1 1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <PolarAnswer
              label={2}
              value={5}
              tooltipContent={"Oldukça tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              float="right"
              btnClassName="polar-btn-mid"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path
                d="M1 11L5.10681 6.89319C5.6001 6.39989 5.60011 5.60011 5.10681 5.10681L1 1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <PolarAnswer
              label={3}
              value={6}
              tooltipContent={"Tamamen tanımlıyor"}
              active={active}
              prevAnswer={props.q.answer}
              HandleAnswering={HandleAnswering}
              float="right"
              btnClassName="polar-btn-big"
            />
          </Col>

          <Col className="polar-question-text-col" xs={{ span: 6, order: 2 }} md={{ span: 4, order: 3 }}>
            {window.innerWidth <= 500 && <br></br>}
            <h4
              className="polar-question-text"
              style={{
                fontSize: window.innerWidth >= 500 ? "20px" : "14px",
                marginRight: window.innerWidth >= 500 ? "40px" : "20px",
              }}
            >
              {props.q.rightPolar}
            </h4>
          </Col>
        </Row>
      </div>
    </>
  );
}
