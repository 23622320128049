import { Badge, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ApplicationInfo({ application }) {
  const { t } = useTranslation();

  const calculateQuestionNumber = (scope) =>
    scope.reduce((acc, s) => {
      const subScopeQuestions = s.subScopes.reduce((accSub, ss) => accSub + ss.questions.length, 0);
      return acc + s.questions.length + subScopeQuestions;
    }, 0);

  return (
    <Col xs="12" md="4">
      <Row xs="auto">
        <Col>{application.id}</Col>
        <Col>{application.name}</Col>
        <Col>
          {application.scopes.length} {t("scope")}
        </Col>
        <Col>
          {calculateQuestionNumber(application.scopes)} {t("question")}
        </Col>
        <Col>
          {application.duration} {t("minute")}
        </Col>
      </Row>
      <Row>
        {application.assessments &&
          application.assessments.map((assessment, m) => (
            <Col key={"te" + m} xs="auto">
              <Badge bg={assessment.isB2b ? "success" : "secondary"}>{assessment.name}</Badge>
            </Col>
          ))}
      </Row>
    </Col>
  );
}
