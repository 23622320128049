import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiHelper from "../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../Helpers/Auth/UseAuthHelper";
import { Overlay } from "../../../Helpers/Overlay";
import IconSvg from "../../../App/AppMenu/IconSvg";
export function UserLogin() {
  const { hash } = useParams();
  const { setAuth } = UseAuthHelper();
  const navigate = useNavigate();
  if (hash) {
    let loginData = atob(hash);
    loginData = JSON.parse(JSON.parse(loginData));
    ApiHelper.getAsync("user/HashLogin", loginData, function (result) {
      if (result.status !== 200) {
        navigate("/login");
        //todo : apihelperdan donen istekleri statuslerine gore handle et
      } else if (result && result.data.statusCode) {
        let data = result.data.data;
        setAuth({ data });
        localStorage.setItem("cpdata", JSON.stringify(data));
        if (data.demography) navigate("/userdash");
        else {
          navigate("/demography");
        }
      } else if (result && !result.data.statusCode) {
        navigate("/login");
      }
    });
  }

  return (
    <div style={{ marginTop: "100px", display: "flex", justifyContent: "center" }}>
      <Overlay text="Yönlendirme yapılıyor" />
      <IconSvg icon="cp-logo" height="100" width="100" />
    </div>
  );
}
