import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { filterTestsByTestId, filterTestsById, filterTestsByEmail, cleanFilter } from "../../Store";

export default function TableFilter() {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.exportExcelAllFilter.testsEntity);

  let options = [];

  if (tests) {
    options = tests.map((test) => ({
      value: test.id,
      label: test.id + "-" + test.name,
    }));
  }

  const filterById = (event) => {
    dispatch(filterTestsById(event.target.value));
  };

  const filterByEmail = (event) => {
    dispatch(filterTestsByEmail(event.target.value));
  };

  const filterByTestId = (event) => {
    dispatch(filterTestsByTestId(event.value));
  };

  const clean = () => {
    dispatch(cleanFilter());
  };

  return (
    <Card body className="mb-3" style={{ background: "#2c3034" }}>
      <Row>
        <Col xs={12} md={3}>
          <Select style={{ color: "black" }} options={options} placeholder="Envanter Seç" onChange={filterByTestId} />
        </Col>
        <Col xs={12} md={3} style={{ margin: "auto" }}>
          <InputGroup size="sm">
            <InputGroup.Text id="inputGroup-sizing-sm">Id</InputGroup.Text>
            <Form.Control aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={filterById} />
          </InputGroup>
        </Col>
        <Col xs={12} md={3} style={{ margin: "auto" }}>
          <InputGroup size="sm">
            <InputGroup.Text id="inputGroup-sizing-sm">Mail</InputGroup.Text>
            <Form.Control aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={filterByEmail} />
          </InputGroup>
        </Col>
        <Col xs={12} md={3}>
          <Button style={{ width: "100%" }} variant="primary" onClick={clean}>
            Filtreyi Temizle
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
