import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import WrapUpNew from "../../../WrapUp/Components/WrapUpNew";

export default function DemoWrapUpModal({ demoWrapUpModalData, onHide, isTest }) {
  const dispatch = useDispatch();
  let scheme = useSelector((state) => state.reportScheme.reportItemEntityList);
  let inventory = useSelector((state) => state.reportScheme.inventory);
  if (inventory == null || scheme == null) return;
  scheme = JSON.parse(JSON.stringify(scheme));
  const demoAppData = {
    name: inventory.Name,
    pdfCover: inventory.PdfCoverImageName,
    pdfInfo: inventory.PdfInfoImageName,
  };

  const { auth } = UseAuthHelper();
  const user = auth?.data;

  const CloseModal = () => {
    onHide();
  };

  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  scheme.forEach((reportItem) => {
    reportItem.score = randomIntFromInterval(1, 100);
  });

  return (
    <Modal fullscreen={true} centered show={demoWrapUpModalData.show} onHide={CloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Demo Wrap Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WrapUpNew demoScheme={scheme} demoAppData={demoAppData} />
      </Modal.Body>
    </Modal>
  );
}

// data={PdfData}
