import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../../Services/HttpsClient";
import ApiHelper from "../../../../../Services/ApiHelper";
import BasicGraphDetailAPI from "../API/BasicGraphDetailAPI";
import { BasicGraphDetailApiUrls } from "../API/BasicGraphDetailAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const basicGraphDetailAPI = new BasicGraphDetailAPI(apiHelper);

export const createBasicGraphDetail = createAsyncThunk(
  BasicGraphDetailApiUrls.createBasicGraphDetail,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphDetailAPI.createBasicGraphDetail(payload, token);
    dispatch(
      getBasicGraphDetails(
        {
          id: state.basicGraphDetail.scopeId,
          isSub: state.basicGraphDetail.isSub,
        },
        token
      )
    );
  }
);

export const getBasicGraphDetails = createAsyncThunk(
  BasicGraphDetailApiUrls.getBasicGraphDetails,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    let data = await basicGraphDetailAPI.getBasicGraphDetails(payload, token);
    return data;
  }
);

export const deleteBasicGraphDetail = createAsyncThunk(
  BasicGraphDetailApiUrls.deleteBasicGraphDetail,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphDetailAPI.deleteBasicGraphDetail(payload, token);
    dispatch(
      getBasicGraphDetails(
        {
          id: state.basicGraphDetail.scopeId,
          isSub: state.basicGraphDetail.isSub,
        },
        token
      )
    );
  }
);

export const updateBasicGraphDetail = createAsyncThunk(
  basicGraphDetailAPI.updateBasicGraphDetail,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    await basicGraphDetailAPI.updateBasicGraphDetail(payload, token);
    dispatch(
      getBasicGraphDetails(
        {
          id: state.basicGraphDetail.scopeId,
          isSub: state.basicGraphDetail.isSub,
        },
        token
      )
    );
  }
);
