export default class ApplicationDTO {
  constructor(application) {
    this.InventoryId = application.id;
    this.InventoryLeanParametersId = application.inventoryLeanParameters.id;
    this.HighRange = application.inventoryLeanParameters.highRange;
    this.MidRange = application.inventoryLeanParameters.midRange;
    this.LowRange = application.inventoryLeanParameters.lowRange;
    this.EdgeRange = application.inventoryLeanParameters.edgeRange;
    this.HighRate = application.inventoryLeanParameters.highRate;
    this.MidRate = application.inventoryLeanParameters.midRate;
    this.LowRate = application.inventoryLeanParameters.lowRate;
    this.EdgeRate = application.inventoryLeanParameters.edgeRate;
  }
}
