import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";

export default function CreateScope({ scopeId, data, application, uiString, UpdateScope, CreateScope }) {
  const [scope, setScope] = useState({
    id: data?.id,
    name: data?.name || "",
    scopeId,
    isManipulation: data?.isManipulation || false,
    isConsistency: data?.isConsistency || false,
  });

  const [cleanScoringComp, setCleanScoringComp] = useState(false);

  useEffect(() => {
    if (cleanScoringComp) {
      setCleanScoringComp(false);
    }
  }, []);

  const handleCreateOrUpdate = () => {
    const action = data ? UpdateScope : CreateScope;

    action(scope);

    if (!data) {
      setScope((prev) => ({
        ...prev,
        name: "",
        isManipulation: false,
        isConsistency: false,
      }));
      setCleanScoringComp(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const isCheckbox = type === "checkbox";
    setScope((prev) => ({
      ...prev,
      [name]: isCheckbox ? checked : value,
    }));
  };

  const HandleCosistencyAndManipulation = (type, checked) => {
    if (type === "consistency") {
      setScope((scope) => ({
        ...scope,
        isManipulation: checked && scope.isManipulation && !checked,
        isConsistency: checked,
      }));
    } else if (type === "manipulation") {
      setScope((scope) => ({
        ...scope,
        isManipulation: checked,
        isConsistency: checked && scope.isConsistency && !checked,
      }));
    }
  };

  return (
    <Card body className="mb-3">
      <Form>
        <Row>
          <Col xs={12} md={10} className="d-flex">
            <InputGroup className="me-3">
              <InputGroup.Text>{uiString} adı</InputGroup.Text>
              <Form.Control
                name="name"
                value={scope.name}
                placeholder={uiString + " adı giriniz"}
                onChange={handleChange}
              />
            </InputGroup>

            {!application?.isTest && (
              <Row className="flex-nowrap">
                <Col className="flex-center">
                  <Form.Check
                    type="switch"
                    id="manipulation-switch"
                    name="isManipulation"
                    label="Manipülasyon"
                    checked={scope.isManipulation}
                    onChange={(e) => HandleCosistencyAndManipulation("manipulation", e.target.checked)}
                  />
                </Col>
                <Col className="flex-center">
                  <Form.Check
                    type="switch"
                    id="consistency-switch"
                    name="isConsistency"
                    label="Tutarlılık"
                    checked={scope.isConsistency}
                    onChange={(e) => HandleCosistencyAndManipulation("consistency", e.target.checked)}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} md={2}>
            <Button style={{ width: "100%" }} variant="primary" onClick={handleCreateOrUpdate} disabled={!scope.name}>
              {data ? "Güncelle" : "Oluştur"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
