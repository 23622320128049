import React, { useEffect } from "react";
import ImageSpace from "./ReportItems/HtmlElements/ImageSpace";
import MonoSpace from "./ReportItems/HtmlElements/MonoSpace";
import DualSpace from "./ReportItems/HtmlElements/DualSpace";
import EmptySpace from "./ReportItems/HtmlElements/EmptySpace";
import CoverSection from "./CoverSection";
import InfoSection from "./InfoSection";
import Detail_1 from "./ReportItems/Designs/Detail_1";
import Detail_2 from "./ReportItems/Designs/Detail_2";
import Detail_LoadingBar from "./ReportItems/Designs/Detail_LoadingBar";
import DualText from "./ReportItems/Designs/DualText";
import MonoText from "./ReportItems/Designs/MonoText";
import MonoText_2 from "./ReportItems/Designs/MonoText_2";
import Summary_1 from "./ReportItems/Designs/Summary_1";
import Summary_LoadingBar from "./ReportItems/Designs/Summary_LoadingBar";
import { designTypes } from "../../../CreateApplication/ReportItems/ReportItemBase/Components/DesignTypeDropdown";
import "../../Styles/PdfFile.css";

export default function PdfFile({ StartDownloadPdf, scheme, appData }) {
  useEffect(() => {
    StartDownloadPdf();
  }, []);

  const Key = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  const HandleMappingReportItemComponents = (item, i) => {
    if (item.componentName === "EmptySpace") return <EmptySpace key={Key(i)} item={item} />;
    else if (item.componentName === "MonoSpace") return <MonoSpace key={Key(i)} item={item} />;
    else if (item.componentName === "DualSpace") return <DualSpace key={Key(i)} item={item} />;
    else if (item.componentName === "ImageSpace") return <ImageSpace key={Key(i)} item={item} />;
    else return CreateDynamicComponent(item);
  };

  const designs = {
    Summary_1,
    Summary_LoadingBar,
    Detail_1,
    Detail_2,
    Detail_LoadingBar,
    MonoText,
    DualText,
    MonoText_2,
  };

  const GetNameProperty = (item) => {
    for (var name in designTypes) {
      var value = designTypes[name];
      if (value === item.designType) return name;
    }
  };

  const CreateDynamicComponent = (item) => {
    const isSub = item.subScopeId ? true : false;
    const SpecificComponent = designs[GetNameProperty(item)];
    return <SpecificComponent item={item} isSub={isSub}></SpecificComponent>;
  };

  return (
    <div id="pdf-file" style={{ width: "794px", padding: "2mm 10mm" }} className="jakarta-sans ">
      {appData.pdfCover && <CoverSection imageName={appData.pdfCover} inventoryName={appData.name} />}
      {appData.pdfInfo && <InfoSection imageName={appData.pdfInfo} />}
      {scheme.map((x, i) => HandleMappingReportItemComponents(x, i))}
    </div>
  );
}
