import React from "react";

import DemoResultsTable from "./DemoResultsTable/DemoResultsTable";
import BreadCrumb from "../../../Components/Dashboard/BreadCrumb";
import ExportExcelAllFilter from "../../ExportExcelAllFilter";
import TableFilter from "./Filter/TableFilter";

import "../Styles/index.css";

export default function ApplicationResults() {
  return (
    <div className="sadmin-page-container">
      <BreadCrumb pageName="Sonuçlar" backPageName="Dashboard" backUrl="/sdash" />
      <ExportExcelAllFilter />
      <TableFilter />
      <DemoResultsTable />
    </div>
  );
}
