export const ReportSchemeApiUrls = {
  createScheme: "report/CreateScheme",
  updateScheme: "report/UpdateScheme",
  deleteScheme: "report/DeleteScheme",
  getAllReportItems: "reportitem/GetAllReportItems",
};

export default class ReportSchemeAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async createReportScheme(data, token) {
    return this.post(ReportSchemeApiUrls.createScheme, data, token);
  }

  async updateReportScheme(data, token) {
    return this.post(ReportSchemeApiUrls.updateScheme, data, token);
  }

  async deleteReportScheme(data, token) {
    return await this.get(ReportSchemeApiUrls.deleteScheme, data, token);
  }

  async getAllReportItems(data, token) {
    return await this.get(ReportSchemeApiUrls.getAllReportItems, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
