import { createSlice, current } from "@reduxjs/toolkit";
import { getCreditsData, upsertCredit } from "./CreditThunk";

export const credit = createSlice({
  name: "credit",
  initialState: {
    admins: [],
    tests: [],
    credits: [],
    filteredCredits: [],
  },
  reducers: {
    filteredCredits(state, action) {
      const credits = current(state.credits);
      const admins = current(state.admins);

      const filteredData = credits.filter((credit) => {
        return admins.find((admin) => admin.id === credit.adminUserId).email.includes(action.payload);
      });

      state.filteredCredits = filteredData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditsData.fulfilled, (state, action) => {
      state.admins = action.payload.admins;
      state.tests = action.payload.tests;
      state.credits = action.payload.credits;
      state.filteredCredits = action.payload.credits;
    });
    builder.addCase(upsertCredit.fulfilled, (state, action) => {
      state.admins = action.payload.admins;
      state.tests = action.payload.tests;
      state.credits = action.payload.credits;
      state.filteredCredits = action.payload.credits;
    });
  },
});

export const { filteredCredits } = credit.actions;

export default credit.reducer;
