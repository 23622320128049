import React, { useState } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { designTypes } from "../../../ReportItems/ReportItemBase/Components/DesignTypeDropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateReportSchemeState } from "../../Store";

export default function ReportItemEntityBreadCrumb({ entity, itemName }) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(entity.order);
  const [heightInputVal, setHeightInputVal] = useState(Number(entity.height));
  const reportItemEntityList = useSelector((state) => state.reportScheme.reportItemEntityList);

  const GetKeyByValue = (value) => {
    return Object.keys(designTypes).find((key) => designTypes[key] === value);
  };

  const ChangeOrder = () => {
    let val = parseInt(inputValue);
    if (val > reportItemEntityList.length || val === entity.order || val <= 0) return setInputValue(entity.order);
    let newList = JSON.parse(JSON.stringify(reportItemEntityList));
    newList = newList.filter((x) => x.order != entity.order);
    newList.forEach((x, i) => (x.order = i + 1));

    let firstHalf = [];
    let secondHalf = [];
    newList = JSON.parse(JSON.stringify(newList));
    newList.forEach((x, i) => {
      if (i + 1 < val) firstHalf.push(x);
      else {
        x.edited = true;
        secondHalf.push(x);
      }
    });
    entity = JSON.parse(JSON.stringify(entity));
    entity.edited = true;
    firstHalf.push(entity);

    firstHalf = firstHalf.concat(secondHalf);
    let resultList = JSON.parse(JSON.stringify(firstHalf));
    resultList.forEach((x, i) => {
      x.order = i + 1;
    });

    dispatch(updateReportSchemeState({ reportItemEntityList: resultList }));
  };

  const ChangeEntityHeight = () => {
    let coppiedList = JSON.parse(JSON.stringify(reportItemEntityList));

    coppiedList.forEach((x, i) => {
      if (x.order == entity.order) x.height = heightInputVal;
    });

    dispatch(updateReportSchemeState({ reportItemEntityList: coppiedList }));
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col xs="6" md="2">
        <h6> {itemName} </h6>
      </Col>
      <Col xs="6" md="2">
        <h6>{GetKeyByValue(entity.designType)} </h6>
      </Col>
      <Col xs="6" md="2">
        <h6> {entity.name} </h6>
      </Col>
      <Col xs="6" md="1">
        <div className="report-item-change-order-button" onClick={() => ChangeEntityHeight()}>
          Kaydet (mm)
        </div>
        <Form.Control
          size="sm"
          type="number"
          min="1"
          value={heightInputVal}
          onChange={(e) => setHeightInputVal(e.target.value)}
        />
      </Col>
      <Col xs="6" md="3">
        <Row>
          <Col>{entity.scopeName}</Col>
          <Col>{entity.subScopeName}</Col>
          <Col>
            <h5
              style={{
                color: entity.orderedBefore && !entity.edited ? "green" : "red",
              }}
            >
              {entity.order}
            </h5>
          </Col>
        </Row>
      </Col>
      <Col xs="6" md="1">
        <div className="report-item-change-order-button" onClick={() => ChangeOrder()}>
          Değiştir
        </div>
        <Form.Control
          type="number"
          min="1"
          size="sm"
          value={inputValue}
          step="1"
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Col>
    </Row>
  );
}
