import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { openEntityModal } from "../../Store";

export default function OpenEntityModal({ application }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openModal = () => dispatch(openEntityModal(application));

  return (
    <Button size="sm" variant="success" onClick={openModal}>
      {t("edit")}
    </Button>
  );
}
